import React, { Component } from 'react'
import {
    Accordion,
    Button,
    Card,
    Col,
    Form,
    Modal,
    Nav,
    Row,
    Tab,
} from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import LocaleName from '../Elements/Translations/LocaleName'

class AddDisplayByDataFieldModal extends Component {
    constructor(props) {
        super(props)
        let locales = this.props.kedo.env().getEnvironmentLocales()
        let translations = []
        locales.map((localeItem) => {
            translations.push({
                culture: localeItem.code,
                name: this.props.item.name,
            })
        })

        this.state = {
            defDossier: this.props.item.defDossier,
            environment: this.props.item.environment,
            dataFieldUuid: this.props.item.uuid,
            views: [],
            errors: false,
            submitting: false,
            displayPositions: [],
            translations: translations,
            dataField: this.props.dataField,
            displayItems: [],
        }

        this.submit = this.submit.bind(this)
        this.fetchDisplayPositions = this.fetchDisplayPositions.bind(this)
        this.fetchDisplayItems = this.fetchDisplayItems.bind(this)
        this.onChangeAddDisplay = this.onChangeAddDisplay.bind(this)
    }

    fetchDisplayPositions() {
        let url =
            this.props.kedo.api().getDisplayPositionEndpoint() +
            '?limit=500&defDossier=' +
            this.state.defDossier
        this.setState({ fetchingPositions: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState({
                    displayPositions: response.data.results,
                    fetchingPositions: false,
                })
            )
    }

    fetchDisplayItems(callBack) {
        let url =
            this.props.kedo.api().getDisplayItemEndpoint() +
            '?limit=500&defDossier=' +
            this.state.defDossier
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState(
                    {
                        displayItems: response.data.results,
                    },
                    callBack
                )
            )
    }

    onChangeAddDisplay(e) {
        let views = this.state.views.filter((item) => item !== e.target.value)
        if (e.target.checked) {
            views.push(e.target.value)
        }
        this.setState({ views: views })
    }

    compareItem(item, view) {
        if (
            view === 'edit' &&
            (item.view === 'edit' || item.view === 'show/edit')
        ) {
            return true
        }

        if (
            view === 'show/edit' &&
            (item.view === 'edit' ||
                item.view === 'show' ||
                item.view === 'show/edit')
        ) {
            return true
        }

        if (
            view === 'show' &&
            (item.view === 'show' || item.view === 'show/edit')
        ) {
            return true
        }

        return item.view === view
    }

    submit() {
        let api = this.props.kedo.api()
        let totLength = this.state.views.length
        if (totLength > 0) {
            this.setState({ submitting: true })
        }

        this.fetchDisplayItems()

        this.state.views.map((viewName, vIndex) => {
            let data = {
                defDossier: this.state.defDossier,
                environment: this.state.environment,
                defDossierDefField: this.state.dataFieldUuid,
                rank: 1,
                col: 1,
                rowspan: 12,
                colspan: 4,
                translations: this.state.translations,
                view: viewName,
            }

            let latestRow = 1
            this.state.displayItems
                .filter((item) => this.compareItem(item, viewName))
                .map((item) =>
                    item.rank > latestRow ? (latestRow = item.rank) : null
                )

            data.rank = latestRow + 1

            if (viewName == 'show/edit') {
                data.displayPosition = this.state.displayPosition
            }

            api.post(api.getDisplayItemEndpoint(), data).then((response) => {
                if (vIndex + 1 >= totLength) {
                    this.props.onClose()
                }
            })
        })
    }

    componentDidMount() {
        this.fetchDisplayPositions()
    }

    onChangeTranslation(locale, ddiName) {
        let trans = this.state.translations.find(
            (item) => item.culture === locale
        )
        let translations = this.state.translations.filter(
            (item) => item.culture !== locale
        )

        if (!trans) {
            trans = { culture: locale, name: ddiName }
        } else {
            trans.name = ddiName
        }
        translations.push(trans)

        this.setState({ translations: translations })
    }

    renderTranslations(translations) {
        const kedo = this.props.kedo
        if (!translations || translations.length <= 0) {
            translations = []
        }
        let locales = this.props.kedo.env().getEnvironmentLocales()

        if (!locales) {
            return this.props.kedo.t('No languages set')
        }

        if (locales.length === 1) {
            let trans = translations.find(
                (item) => locales[0].code === item.culture
            )
            if (!trans) {
                trans = {}
            }

            return (
                <div>
                    <Form.Group>
                        <Form.Label>{kedo.t('Name')}</Form.Label>
                        <Form.Control
                            value={trans.name}
                            isInvalid={!!this.state.errors[locales[0]]}
                            type={'text'}
                            onChange={(event) =>
                                this.onChangeTranslation(
                                    locales[0].code,
                                    event.target.value
                                )
                            }
                        />
                    </Form.Group>
                </div>
            )
        }

        return (
            <Accordion
                defaultActiveKey={locales[0].id}
                className={'language_accordion'}
            >
                {locales.map((locale) => {
                    let trans = translations.find(
                        (item) => locale.code === item.culture
                    )
                    if (!trans) {
                        trans = {}
                    }
                    return (
                        <Card key={locale.id}>
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey={locale.id}
                            >
                                <LocaleName
                                    kedo={kedo}
                                    localeCode={locale.code}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={locale.id}>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>
                                            {this.props.kedo.t('Name')}
                                        </Form.Label>
                                        <Form.Control
                                            value={trans.name}
                                            isInvalid={
                                                !!this.state.errors[locale]
                                            }
                                            type={'text'}
                                            onChange={(event) =>
                                                this.onChangeTranslation(
                                                    locale.code,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            {this.props.kedo.t(
                                                'Information text'
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            value={
                                                trans.settings &&
                                                trans.settings.informationtext
                                                    ? trans.settings
                                                          .informationtext
                                                    : null
                                            }
                                            as="textarea"
                                            rows="3"
                                            onChange={(event) =>
                                                this.onChangeTranslationSettings(
                                                    locale.code,
                                                    event.target.value,
                                                    'informationtext'
                                                )
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            {this.props.kedo.t('Pre text')}
                                        </Form.Label>
                                        <Form.Control
                                            value={
                                                trans.settings &&
                                                trans.settings.pretext
                                                    ? trans.settings.pretext
                                                    : null
                                            }
                                            as="textarea"
                                            rows="3"
                                            onChange={(event) =>
                                                this.onChangeTranslationSettings(
                                                    locale.code,
                                                    event.target.value,
                                                    'pretext'
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })}
            </Accordion>
        )
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal size={'lg'} show={true}>
                <Modal.Header>
                    <Modal.Title>{kedo.t('Add display items?')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col sm={3} className={'pillsSidebar'}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey={'first'}>
                                            {kedo.t('General')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="labels">
                                            {kedo.t('Labels')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <p>
                                            {kedo.t(
                                                'What display items do you want to auto create?'
                                            )}
                                        </p>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>
                                                    {kedo.t('Type')}
                                                </Form.Label>
                                                <Form.Check
                                                    onChange={
                                                        this.onChangeAddDisplay
                                                    }
                                                    type={'checkbox'}
                                                    label={kedo.t('List')}
                                                    value={'list'}
                                                />
                                                <Form.Check
                                                    onChange={
                                                        this.onChangeAddDisplay
                                                    }
                                                    type={'checkbox'}
                                                    label={kedo.t('Summary')}
                                                    value={'summary'}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>
                                                    {kedo.t('Location')}{' '}
                                                    {this.state
                                                        .fetchingPositions ? (
                                                        <LoadingDefault />
                                                    ) : null}
                                                </Form.Label>
                                                <Form.Check
                                                    onChange={
                                                        this.onChangeAddDisplay
                                                    }
                                                    type={'checkbox'}
                                                    label={kedo.t('Show/edit')}
                                                    value={'show/edit'}
                                                />
                                                <Form.Control
                                                    name={'displayPosition'}
                                                    onChange={(e) =>
                                                        this.setState({
                                                            [e.target.name]:
                                                                e.target.value,
                                                        })
                                                    }
                                                    as="select"
                                                >
                                                    <option>
                                                        {this.props.kedo.t(
                                                            'Choose an option'
                                                        )}
                                                    </option>
                                                    {this.state.displayPositions.map(
                                                        (item) => (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {
                                                                    item.name_plural
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Check
                                                    onChange={
                                                        this.onChangeAddDisplay
                                                    }
                                                    type={'checkbox'}
                                                    label={kedo.t('Search')}
                                                    value={'search'}
                                                />
                                                <Form.Check
                                                    onChange={
                                                        this.onChangeAddDisplay
                                                    }
                                                    type={'checkbox'}
                                                    label={kedo.t(
                                                        'Advanced search'
                                                    )}
                                                    value={'advanced-search'}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="labels">
                                        {this.renderTranslations(
                                            this.state.translations
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.state.submitting}
                        onClick={this.submit}
                        variant="primary"
                    >
                        {this.state.submitting ? <LoadingDefault /> : null}{' '}
                        {kedo.t('Yes')}
                    </Button>
                    <Button
                        disabled={this.state.submitting}
                        onClick={this.props.onClose}
                        variant="secondary"
                    >
                        {kedo.t('No')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddDisplayByDataFieldModal
