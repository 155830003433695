import React, {Component} from 'react'
import {Form} from 'react-bootstrap'
import Select from "react-select";

class DisplayItemFileSettings extends Component {
    constructor(props) {
        super(props);

        const kedo = props.kedo;
        const selected = [];

        if (props.item.settings && props.item.settings.file_types) {
            props.item.settings.file_types.map(item => {
                let label = item.charAt(0).toUpperCase() + item.slice(1);
                if (item === 'images') {
                    label = kedo.t('Images')
                } if (item === 'documents') {
                    label = kedo.t('Text documents')
                } if (item === 'spreadsheet') {
                    label = kedo.t('Spreadsheet documents')
                } if (item === 'presentation') {
                    kedo.t('Presentation documents')
                }

                selected.push({
                    label: label,
                    value: item,
                })
            });
        }

        const options = [
            // {value: '', label: 'Everything'},
            {value: 'images', label: kedo.t('Images')},
            {value: 'audio', label: 'Audio'},
            {value: 'video', label: 'Videos'},
            {value: 'documents', label: kedo.t('Text documents')},
            {value: 'spreadsheet', label: kedo.t('Spreadsheet documents')},
            {value: 'presentation', label: kedo.t('Presentation documents')},
        ];

        this.state = {
            multiple: this.props.item && this.props.item.settings && this.props.item.settings.multiple ? true : false,
            file_preview: this.props.item && this.props.item.settings && this.props.item.settings.file_preview ? true : false,
            preview_direct: this.props.item && this.props.item.settings && this.props.item.settings.preview_direct ? true : false,
            preview_width: this.props.item && this.props.item.settings && this.props.item.settings.preview_width ? this.props.item.settings.preview_width : null,
            preview_height: this.props.item && this.props.item.settings && this.props.item.settings.preview_height ? this.props.item.settings.preview_height : null,
            options: options,
            selected: this.props.item && this.props.item.settings && this.props.item.settings.file_types ? selected : [],
        };

        this.onChange = this.onChange.bind(this);
        this.onNormalChange = this.onNormalChange.bind(this);
        this.onChangeFileTypeOptions = this.onChangeFileTypeOptions.bind(this);
    }

    onChangeFileTypeOptions(options) {
        if(!options || !options.map) {
            this.setState({selected: []});
            return;
        }

        this.setState({selected: options});

        let values = options.map(item => item.value);
        this.props.onChange('file_types', values);
    }

    onChange(event) {
        if (event.target.checked === true) {
            this.setState({[event.target.name]: true});
            this.props.onChange(event.target.name, true);
        } else {
            this.setState({[event.target.name]: false});
        }
    }

    onNormalChange(event) {
        this.setState({[event.target.name]: event.target.value});
        this.props.onChange(event.target.name, event.target.value);
    }

    render() {
        return  <div>
                    <Form.Group>
                        <Form.Label>{this.props.kedo.t('Restrict file uploads')}</Form.Label>
                        <Select
                            className={"basic-multi-select"}
                            id={`file-settings`}
                            isMulti
                            onChange={(event) => this.onChangeFileTypeOptions(event)}
                            options={this.state.options}
                            placeholder={this.props.kedo.t('Select an option') + '...'}
                            noOptionsMessage={() => this.props.kedo.t('No items found')}
                            value={this.state.selected}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            checked={this.state.multiple}
                            id={'radio-multiple-files'}
                            name={'multiple'}
                            label={this.props.kedo.t('Allow multiple files')}
                            onChange={(event) => this.onChange(event)}
                            type="checkbox"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            checked={this.state.file_preview}
                            id={'radio-file-preview'}
                            name={'file_preview'}
                            label={this.props.kedo.t('Show file preview')}
                            onChange={(event) => this.onChange(event)}
                            type="checkbox"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            checked={this.state.preview_direct}
                            id={'radio-preview-direct'}
                            name={'preview_direct'}
                            label={this.props.kedo.t('Direct preview')}
                            onChange={(event) => this.onChange(event)}
                            type="checkbox"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.props.kedo.t('Width')}</Form.Label>
                        <Form.Control
                            value={this.state.preview_width}
                            id={'radio-preview-width'}
                            name={'preview_width'}
                            type={'number'}
                            onChange={(event) => this.onNormalChange(event)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.props.kedo.t('Height')}</Form.Label>
                        <Form.Control
                            value={this.state.preview_height}
                            id={'radio-preview-width'}
                            name={'preview_height'}
                            type={'number'}
                            onChange={(event) => this.onNormalChange(event)}
                        />
                    </Form.Group>
                </div>
    }
}

export default DisplayItemFileSettings