import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Editor } from '@tinymce/tinymce-react'
import AsyncSelect from 'react-select/async'
import ConfirmDeleteChangesDialog from '../../Form/ConfirmDeleteChangesDialog'

class ComposeMessage extends React.Component {
    constructor(props) {
        super(props)

        const sender = []
        if (props.to) {
            props.to.map((item) =>
                sender.push({
                    id: item.id,
                    value: item.id,
                    label: item.sender_name,
                })
            )
        }

        this.state = {
            limit: 50,
            errors: false,
            sending: false,
            messageText: null,
            // messageText: this.props.defaultMessage ? this.props.defaultMessage : '',
            messageTitle: this.props.title ? 'RE: ' + this.props.title : null,
            messageType: 'normal',
            showCancelAlert: false,
            showSuccessAlert: false,
            to: this.props.to ? sender : [],
            users: this.props.to ? sender : [],
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.renderUsers = this.renderUsers.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.onChangeMessageText = this.onChangeMessageText.bind(this)
    }

    sendMessage() {
        const kedo = this.props.kedo
        let userIds = this.state.to.map((val) => val.id)

        if (
            !this.state.messageText ||
            !this.state.messageTitle ||
            !this.state.to
        ) {
            let errors = {}
            if (!this.state.to || this.state.to.length <= 0) {
                errors.to = kedo.t('This is a required field')
            }
            if (
                !this.state.messageTitle ||
                this.state.messageTitle.length <= 0
            ) {
                errors.title = kedo.t('This is a required field')
            }
            if (!this.state.messageText || this.state.messageText.length <= 0) {
                errors.message = kedo.t('This is a required field')
            }

            this.setState({ errors: errors })
            return
        }

        this.setState({ errors: false, sending: true })

        let data = {
            messageText: this.state.messageText,
            title: this.state.messageTitle,
            users: userIds,
            dossier: this.props.dossier ? this.props.dossier.id : null,
        }

        if (this.state.messageType) {
            data.type = this.state.messageType
        }

        if (this.props.dossier) {
            data.contentDossier = this.props.dossier.id
            data.dossier = this.props.dossier.id
            data.environment = this.props.kedo.env().getEnvironment().id
        } else if (this.props.dossierId) {
            data.contentDossier = this.props.dossierId
            data.dossier = this.props.dossierId
            data.environment = this.props.kedo.env().getEnvironment().id
        }

        kedo.api()
            .post(kedo.api().getMessageEndpoint(), data)
            .then(() => {
                this.setState({
                    errors: false,
                    messageText: '',
                    messageTitle: '',
                    messageType: null,
                    showSuccessAlert: true,
                    sending: false,
                    to: [],
                })

                this.props.onCloseModal()
            })
            .catch((error) => {
                this.setState({
                    sending: false,
                    errors:
                        error.response &&
                        error.response.data &&
                        error.response.data.errors
                            ? error.response.data.errors
                            : true,
                })
            })
    }

    renderUsers() {
        const kedo = this.props.kedo

        const getOptions = (input) => {
            let params = {
                params: {
                    limit: this.state.limit,
                },
            }

            if (input.length > 0) {
                params.params.name = input
            }
            return kedo
                .api()
                .get(kedo.api().getUserEndpoint(), params)
                .then(function (response) {
                    if (!response.data.results) {
                        return []
                    }

                    return response.data.results.map((user) => ({
                        id: user.id,
                        value: user.id,
                        label: user.profile
                            ? user.profile.last_name +
                              ', ' +
                              user.profile.first_name
                            : user.username,
                    }))
                })
                .catch(() => {
                    this.setState({ userLoading: false })
                })
        }

        return (
            <div
                className={
                    this.state.errors && this.state.errors.to
                        ? 'is-invalid'
                        : ''
                }
            >
                <AsyncSelect
                    className={'basic-multi-select'}
                    isMulti
                    cacheOptions
                    defaultOptions
                    multi={true}
                    loadOptions={getOptions}
                    value={this.state.to}
                    placeholder={this.props.kedo.t('Search') + '...'}
                    onChange={(event) => this.handleReceiversChange(event)}
                    noOptionsMessage={() => this.props.kedo.t('No items found')}
                />
                {this.state.errors && this.state.errors.to ? (
                    <span className={'fake-feedback'}>
                        {this.state.errors.to}
                    </span>
                ) : null}
            </div>
        )
    }

    handleReceiversChange(selectedItems) {
        if (!selectedItems || !selectedItems.map) {
            this.setState({ to: [] })
            return
        }

        this.setState({ to: selectedItems })
    }

    renderCancelAlert() {
        if (!this.state.showCancelAlert || this.state.messageText === null) {
            return null
        }

        return (
            <ConfirmDeleteChangesDialog
                kedo={this.props.kedo}
                variant={'modal'}
                accept={this.props.onCloseModal}
                cancel={() => this.setState({ showCancelAlert: false })}
            />
        )
    }

    checkDefaultMessage() {
        if (this.props.dossier) {
            // TODO CHECK CURRENT ENV FOR DOSSIER
            // IF TRUE > REDIRECT
            // ELSE > MODAL - SWITCH ENV?
            //return `<p>` + this.props.kedo.t('Dossier') + `: <a href="/contentdossier/` + this.props.dossier.id + `" target="_parent">` + this.props.dossier.summary + `</a></p>`
        }

        return false
    }

    onChangeMessageText(content, editor) {
        this.setState({ messageText: content })
    }

    handleCancel() {
        this.setState({ showSuccessAlert: false })

        if (this.state.messageText === null) {
            this.props.onCloseModal()
        } else {
            this.setState({ showCancelAlert: true })
        }
    }

    isRichEdit() {
        return true
    }

    render() {
        const kedo = this.props.kedo

        return (
            <div>
                {this.state.showCancelAlert ? this.renderCancelAlert() : null}
                <Modal
                    show={true}
                    onHide={
                        this.state.messageText === null ||
                        this.state.showCancelAlert
                            ? this.props.onCloseModal
                            : this.handleCancel
                    }
                    size={'lg'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.kedo.t('Compose message')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="receivers.message">
                                <Form.Label
                                    style={{
                                        width: '100%',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {kedo.t('To')}
                                </Form.Label>
                                {this.renderUsers()}
                            </Form.Group>
                            <Form.Group controlId="title.message">
                                <Form.Label style={{ fontWeight: 'bold' }}>
                                    {kedo.t('Title')}
                                </Form.Label>
                                <Form.Control
                                    isInvalid={
                                        this.state.errors &&
                                        this.state.errors.title
                                    }
                                    onChange={(event) =>
                                        this.setState({
                                            messageTitle: event.target.value,
                                        })
                                    }
                                    value={this.state.messageTitle}
                                />
                                {this.state.errors &&
                                this.state.errors.title ? (
                                    <Form.Control.Feedback type="invalid">
                                        {this.state.errors.title}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                            {kedo.user().isSuperAdmin() ||
                            kedo.user().isContractAdminUser() ||
                            kedo.env().isEnvironmentAdmin() ? (
                                <Form.Group controlId="type.message">
                                    <Form.Label style={{ fontWeight: 'bold' }}>
                                        {kedo.t('Type')}
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(event) =>
                                            this.setState({
                                                messageType: event.target.value,
                                            })
                                        }
                                        as="select"
                                    >
                                        <option
                                            selected={
                                                this.state.messageType ===
                                                'normal'
                                            }
                                            value={'normal'}
                                        >
                                            {kedo.t('Default message')}
                                        </option>
                                        <option
                                            selected={
                                                this.state.messageType ===
                                                'popup'
                                            }
                                            value={'popup'}
                                        >
                                            {kedo.t('Pop-up message')}
                                        </option>
                                    </Form.Control>
                                </Form.Group>
                            ) : null}
                            <Form.Group controlId="text.message">
                                <Form.Label style={{ fontWeight: 'bold' }}>
                                    {kedo.t('Message')}
                                </Form.Label>
                                <div
                                    className={
                                        this.state.errors &&
                                        this.state.errors.message
                                            ? 'is-invalid'
                                            : ''
                                    }
                                >
                                    <Editor
                                        initialValue={this.checkDefaultMessage()}
                                        onEditorChange={
                                            this.onChangeMessageText
                                        }
                                        value={
                                            this.checkDefaultMessage()
                                                ? this.checkDefaultMessage()
                                                : this.state.messageText
                                        }
                                        init={{
                                            branding: false,
                                            default_link_target: '_blank',
                                            font_formats:
                                                'Arial=arial,helvetica,sans-serif; Arial Black=arial black,gadget,sans-serif; Comic Sans=comic sans ms,cursive,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,serif; Impact=impact,charcoal,sans-serif; Lucida Sans Unicode=lucida sans unicode,lucida grande,sans-serif; Palatino Linotype=alatino linotype,book antiqua,palatino,serif; Tahoma=tahoma,geneva,sans-serif; Trebuchet=trebuchet,helvetica,sans-serif; Times New Roman =times new roman,times,serif; Verdana=verdana,geneva,sans-serif;',
                                            fontsize_formats:
                                                '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                            height: 250,
                                            body_class: 'tinyMCE-editor',
                                            plugins: [
                                                'table',
                                                'lists',
                                                'link',
                                                'autolink',
                                                'emoticons',
                                                'help',
                                            ],
                                            menubar: false,
                                            toolbar:
                                                'undo redo | styleselect | fontselect fontsizeselect bold italic underline strikethrough forecolor backcolor | hr link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | table | emoticons | removeformat  | help',
                                            toolbar_mode: 'sliding',
                                        }}
                                    />
                                </div>
                                {this.state.errors &&
                                this.state.errors.message ? (
                                    <Form.Control.Feedback type="invalid">
                                        {this.state.errors.message}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.sendMessage} variant="primary">
                            {this.state.sending ? (
                                <LoadingDefault size={'sm'} as={'span'} />
                            ) : (
                                <FontAwesomeIcon icon={faPaperPlane} />
                            )}
                            &nbsp; {kedo.t('Send message')}
                        </Button>
                        <Button
                            onClick={this.handleCancel}
                            variant="secondary"
                            title={'Cancel'}
                        >
                            {this.props.kedo.t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ComposeMessage
