import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'

class ConfirmDeleteChangesDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submitting: false,
        }
    }

    getForm() {
        return (
            <Modal
                centered
                show={true}
                variant={'danger'}
                onHide={() => this.props.cancel()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t('Confirm delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>{this.props.kedo.t('confirm_delete_changes')}</Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.accept} variant="danger">
                        {this.state.submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faTrashAlt} />
                        )}
                        &nbsp; {this.props.kedo.t('Delete')}
                    </Button>{' '}
                    &nbsp;
                    <Button
                        onClick={() => this.props.cancel()}
                        variant="secondary"
                    >
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        // if (this.props.variant === 'modal') {
        return this.getForm()
        // }

        // return  <Prompt
        //             when={this.props.when}
        //             message={() => this.getForm()}
        //         />
    }
}

export default ConfirmDeleteChangesDialog
