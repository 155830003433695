import React from 'react'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import DateTimeField from './DateTimeField'

class DisplayItemDossierData extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        this.state = {
            users: [],
            usersSelected: [],
            loading: false,
            timeout: null,
            value: null,
        }

        this.setSelectedUsers = this.setSelectedUsers.bind(this)
        this.handleUserInputChange = this.handleUserInputChange.bind(this)
        this.renderUserSearch = this.renderUserSearch.bind(this)
    }

    getValue() {
        let dataType = this.props.item.settings.dossier_data

        if (dataType === 'created_by' || dataType === 'modified_by') {
            return this.state.usersSelected.map((item) => item.id)
        } else if (dataType === 'summary') {
            return this.state.value
        } else if (dataType === 'modified_at' || dataType === 'created_at') {
            return this.state.value
        }
    }

    getFormattedDate(value) {
        if (value.length <= 0) {
            return ''
        }

        let date = new Date(value)
        let dateTimeFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
            timeStyle: 'medium',
        })

        return dateTimeFormat.format(date)
    }

    renderUser(userData) {
        if (userData && userData.username) {
            return <a href={`/user/${userData.id}`}>{userData.username}</a>
        }

        return ''
    }

    fetchUserData(search) {
        const api = this.props.kedo.api()
        let params = {
            params: {},
        }

        params.params.environment = this.props.kedo.env().getEnvironmentId()

        if (search.length > 0) {
            params.params.name = search
        }
        this.setState({ loading: true })
        api.get(api.getUserEndpoint(), params).then((response) => {
            let items = []
            for (let i = 0; i < response.data.results.length; i++) {
                items.push({
                    label: response.data.results[i].username,
                    id: response.data.results[i].id,
                    value: response.data.results[i].id,
                })
            }
            this.setState({
                loading: false,
                users: items,
            })
        })
    }

    setSelectedUsers(items, dossierDataType) {
        if (items === null) {
            items = []
        }

        this.setState({ usersSelected: items })
        this.props.changeValue(dossierDataType, items)
    }

    handleUserInputChange(search) {
        if (this.state.timeout) clearTimeout(this.state.timeout)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.timeout = setTimeout(() => {
            this.fetchUserData(search)
        }, 400)
    }

    renderUserSearch(dossierDataType) {
        if (this.isSearch() === true && dossierDataType === 'summary') {
            return (
                <Select
                    isMulti
                    isClearable
                    value={this.state.usersSelected}
                    noOptionsMessage={() => this.props.kedo.t('No items found')}
                    onChange={(items) =>
                        this.setSelectedUsers(items, dossierDataType)
                    }
                    isLoading={this.state.loading}
                    options={this.state.users}
                    onInputChange={this.handleUserInputChange}
                />
            )
        }
    }

    isSearch() {
        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return true
        }

        return false
    }

    render() {
        const dossierDataType = this.props.item.settings.dossier_data

        if (
            this.isSearch() === true &&
            (dossierDataType === 'created_by' ||
                dossierDataType === 'modified_by')
        ) {
            return this.renderUserSearch(dossierDataType)
        } else if (this.isSearch() && dossierDataType === 'summary') {
            return (
                <Form.Control
                    onChange={(event) =>
                        this.props.changeValue('summary', event.target.value)
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                    type="text"
                    value={this.props.value}
                />
            )
        } else if (this.isSearch() && dossierDataType === 'dossier_id') {
            return (
                <Form.Control
                    onChange={(event) =>
                        this.props.changeValue('dossierId', event.target.value)
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                    type="text"
                    value={this.props.value}
                />
            )
        } else if (this.isSearch() && dossierDataType === 'created_at') {
            return (
                <DateTimeField
                    {...this.props}
                    onChange={(event) =>
                        this.props.changeValue('created_at', event.target.value)
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                />
            )
        } else if (this.isSearch() && dossierDataType === 'modified_at') {
            return (
                <DateTimeField
                    {...this.props}
                    onChange={(event) =>
                        this.props.changeValue(
                            'modified_at',
                            event.target.value
                        )
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                />
            )
        }

        if (!this.props.dossier || !this.props.dossier.id) {
            return ''
        }

        switch (dossierDataType) {
            case 'summary':
                return this.props.dossier.summary ?? null
            case 'dossier_id':
                return this.props.dossier.id
            case 'created_at':
                return this.getFormattedDate(this.props.dossier.created_at)
            case 'modified_at':
                return this.getFormattedDate(this.props.dossier.modified_at)
            case 'created_by':
                return this.renderUser(this.props.dossier.created_by)
            case 'modified_by':
                return this.renderUser(this.props.dossier.modified_by)
        }

        return ''
    }
}

export default DisplayItemDossierData
