import React, { Component } from 'react'
import { Alert, Button, Col, Form, Modal, Nav, Row, Tab } from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import DefFieldSettings from './DefFieldSettings/DefFieldSettings'
import {
    faExclamationCircle,
    faSave,
    faTrashAlt,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DefFieldForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            baseFields: [],
            dataFields: [],
            defDossier: this.props.defDossier,
            defFieldLists: [],
            errors: null,
            item: this.props.item
                ? {
                      id: this.props.item.id,
                      name: this.props.item.name,
                      type: this.props.item.type,
                      settings: this.props.item.settings
                          ? this.props.item.settings
                          : {},
                      ranks: [],
                  }
                : {
                      settings: {},
                  },
            submitting: false,
        }

        this.handleErrors = this.handleErrors.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onSettingsChange = this.onSettingsChange.bind(this)
        this.onRankChange = this.onRankChange.bind(this)
        this.onChangeSettings = this.onChangeSettings.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.submitBaseField = this.submitBaseField.bind(this)
    }

    /**
     * @param response
     * @returns {boolean}
     */
    hasFormErrors(response) {
        if (
            response.response.status === 400 &&
            response.response.data &&
            response.response.data.errors
        ) {
            return true
        }

        return false
    }

    handleSuccess() {
        this.setState({
            submitting: false,
            item: {},
            errors: false,
        })

        this.props.onClose(true)
    }

    handleErrors(response) {
        if (!this.hasFormErrors(response)) {
            this.setState({
                submitting: false,
                errors: {
                    generic: this.props.kedo.t('Something went wrong'),
                },
            })
        }
        this.setState({
            submitting: false,
            errors: response.response.data.errors,
        })
    }

    renderErrorFeedback(errorMsg) {
        if (!errorMsg) {
            return
        }

        return (
            <Form.Control.Feedback type="invalid">
                {errorMsg}
            </Form.Control.Feedback>
        )
    }

    handleCheck(event) {
        let item = this.state.item
        item[event.target.name] = event.target.checked
        this.setState({ item: item })
    }

    onSettingsChange(name, value) {
        let item = this.state.item
        if (!item.settings) {
            item.settings = { [name]: value }
        }
        item.settings[name] = value
        this.setState({ item: item })
    }
    onRankChange(items) {
        let ranks = items.map((item) => {
            return {
                uuid: item.uuid,
                id: item.id,
                rank: item.rank,
            }
        })
        this.setState({ items_ranks: ranks })
    }
    onChangeSettings(event) {
        let item = this.state.item
        item.settings[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    handleChange(event) {
        let item = this.state.item
        item[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    submitBaseField() {
        const kedo = this.props.kedo

        if (!this.state.item.name || !this.state.item.type) {
            let errors = {}
            if (!this.state.item.name || this.state.item.name.length <= 0) {
                errors.name = kedo.t('This is a required field')
            }
            if (!this.state.item.type || this.state.item.type.length <= 0) {
                errors.type = kedo.t('This is a required field')
            }

            this.setState({ errors: errors })
            return
        }

        this.setState({
            submitting: true,
            errors: false,
        })

        let item = {
            name: this.state.item.name,
            settings: this.state.item.settings,
        }

        if (this.props.item.id) {
            kedo.api()
                .patch(
                    kedo.api().getDefFieldEndpoint() + '/' + this.props.item.id,
                    item
                )
                .then((successResponse) => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
            if (this.state.items_ranks) {
                this.state.items_ranks.forEach((item) => {
                    kedo.api().patch(
                        kedo.api().getDefFieldListEndpoint() + '/' + item.id,
                        {
                            rank: item.rank,
                        }
                    )
                })
            }
        } else {
            item.environment = kedo.env().getEnvironment().id
            item.type = this.state.item.type
            item.uuid = kedo.utils().uuidv4()

            kedo.api()
                .post(kedo.api().getDefFieldEndpoint(), item)
                .then((successResponse) => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
        }
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal size={'xl'} show={true} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {kedo.t(this.props.item.id ? 'Edit' : 'Add')}{' '}
                        {kedo.t('Base field').toLowerCase()}:{' '}
                        {this.props.item.id
                            ? this.state.item.name
                            : this.props.kedo.t('Unknown')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    <Tab.Container defaultActiveKey="general">
                        <Row>
                            <Col sm={3} className={'pillsSidebar'}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="general">
                                            {kedo.t('Generic')}
                                            {this.state.errors != null &&
                                            this.state.errors &&
                                            (this.state.errors.name ||
                                                this.state.errors.type) ? (
                                                <FontAwesomeIcon
                                                    className={`validationIcon validationFailed`}
                                                    icon={faExclamationCircle}
                                                />
                                            ) : null}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="misc">
                                            {kedo.t('Misc')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        {this.props.checkUsed &&
                                        this.props.checkUsed() ? (
                                            <Alert variant={'warning'}>
                                                <FontAwesomeIcon
                                                    icon={faExclamationCircle}
                                                />
                                                &nbsp;{' '}
                                                {this.props.kedo.t(
                                                    'used_field_alert'
                                                )}
                                            </Alert>
                                        ) : null}
                                        <h3>{kedo.t('Generic')}</h3>
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t('Name')}
                                            </Form.Label>
                                            <Form.Control
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.name
                                                }
                                                value={this.state.item.name}
                                                name={'name'}
                                                onChange={this.handleChange}
                                            />
                                            {this.state.errors !== null &&
                                            this.state.errors.name ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.name}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t('Type')}
                                            </Form.Label>
                                            <Form.Control
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.type
                                                }
                                                disabled={
                                                    this.props.checkUsed &&
                                                    this.props.checkUsed()
                                                        ? this.props.checkUsed()
                                                        : false
                                                }
                                                value={this.state.item.type}
                                                as="select"
                                                name={'type'}
                                                onChange={this.handleChange}
                                            >
                                                <option>
                                                    {kedo.t('Choose an option')}
                                                </option>
                                                <optgroup
                                                    label={kedo.t('Text')}
                                                >
                                                    <option value={'text'}>
                                                        {kedo.t('Text')}
                                                    </option>
                                                    <option value={'textarea'}>
                                                        {kedo.t('Text area')}
                                                    </option>
                                                    <option value={'amount'}>
                                                        {kedo.t('Amount')}
                                                    </option>
                                                    <option value={'url'}>
                                                        URL
                                                    </option>
                                                    <option value={'email'}>
                                                        {kedo.t('E-mail')}
                                                    </option>
                                                    <option
                                                        value={'autoIncrement'}
                                                    >
                                                        {kedo.t(
                                                            'Auto increment'
                                                        )}
                                                    </option>
                                                    <option value={'calc'}>
                                                        {kedo.t('Formula')}
                                                    </option>
                                                </optgroup>
                                                <optgroup
                                                    label={kedo.t('Other')}
                                                >
                                                    <option value={'imageCode'}>
                                                        {kedo.t('Image code')}
                                                    </option>
                                                    <option value={'date'}>
                                                        {kedo.t('Date')}
                                                    </option>
                                                    <option value={'time'}>
                                                        {kedo.t('Time')}
                                                    </option>
                                                    <option value={'file'}>
                                                        {kedo.t('Files')}
                                                    </option>
                                                    <option
                                                        value={'geolocation'}
                                                    >
                                                        {kedo.t('Geolocation')}
                                                    </option>
                                                    <option value={'list'}>
                                                        {kedo.t('List')}
                                                    </option>
                                                    <option value={'user'}>
                                                        {kedo.t('Users')}
                                                    </option>
                                                    <option
                                                        value={
                                                            'customActionButton'
                                                        }
                                                    >
                                                        {kedo.t(
                                                            'Action button'
                                                        )}
                                                    </option>
                                                </optgroup>
                                            </Form.Control>
                                            {this.state.errors !== null &&
                                            this.state.errors.type ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.type}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="misc">
                                        <Form.Group>
                                            <DefFieldSettings
                                                onSettingsChange={
                                                    this.onSettingsChange
                                                }
                                                onRankChange={this.onRankChange}
                                                kedo={this.props.kedo}
                                                item={this.state.item}
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.item.id ? (
                        <Button
                            className={'mr-auto'}
                            disabled={
                                this.props.checkUsed && this.props.checkUsed()
                                    ? this.props.checkUsed()
                                    : false
                            }
                            onClick={() => this.props.onDelete(this.props.item)}
                            variant="danger"
                            title={
                                this.props.checkUsed && this.props.checkUsed()
                                    ? this.props.kedo.t(
                                          'You cannot delete a placed field'
                                      )
                                    : this.props.kedo.t('Delete')
                            }
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                            &nbsp; {this.props.kedo.t('Delete')}
                        </Button>
                    ) : null}
                    <Button onClick={this.submitBaseField} variant="primary">
                        {this.state.submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {kedo.t('Save')}
                    </Button>
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                    >
                        {kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DefFieldForm
