import React, { Component } from 'react'
import { Button, Form, Modal, Alert, Tab, Row, Col, Nav } from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'
import ObjectCreatedInfo from '../Elements/ObjectCreatedInfo'

class DefDossierLinkForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defDossier: this.props.defDossier,
            submitting: false,
            errors: false,
            defDossiers: [],
            dossierQueries: [],
            back_link: {
                name: '',
                id: '',
                uuid: '',
            },
            item: this.props.item
                ? {
                      id: this.props.item.id,
                      name: this.props.item.name,
                      typeOfLink: this.props.item.type_of_link
                          ? this.props.item.type_of_link
                          : 'select',
                      defDossier: this.props.item.def_dossier_id,
                      cascadeRule: this.props.item.cascade_rule,
                      childDefDossier: this.props.item.child_def_dossier_id,
                      obligatory: this.props.item.obligatory,
                      settings:
                          this.props.item.settings &&
                          typeof this.props.item.settings == 'object' &&
                          !Array.isArray(this.props.item.settings)
                              ? this.props.item.settings
                              : {},
                  }
                : {
                      defDossier: this.props.defDossier.id,
                      typeOfLink: 'select',
                      obligatory: false,
                      settings: {},
                  },
        }

        this.handleErrors = this.handleErrors.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.changeDossierQuery = this.changeDossierQuery.bind(this)
        this.handleSettingsCheck = this.handleSettingsCheck.bind(this)
        this.submitLinkField = this.submitLinkField.bind(this)
        this.getDossierQueriesFormControl =
            this.getDossierQueriesFormControl.bind(this)
    }

    /**
     * @param response
     * @returns {boolean}
     */
    hasFormErrors(response) {
        if (
            response &&
            response.response &&
            response.response.status === 400 &&
            response.response.data &&
            response.response.data.errors
        ) {
            return true
        }

        return false
    }

    handleSuccess() {
        this.setState({
            submitting: false,
            item: {},
            errors: false,
        })

        this.props.onSuccess(true)
    }

    handleErrors(response) {
        if (!this.hasFormErrors(response)) {
            this.setState({
                submitting: false,
                errors: {
                    generic: this.props.kedo.t('Something went wrong'),
                },
            })
        }

        this.setState({
            submitting: false,
            errors: response.response.data.errors,
        })
    }

    handleSettingsCheck(event) {
        let item = this.state.item

        if (event.target.name === 'cascade_rule') {
            item.settings[event.target.name] = event.target.value
        } else {
            item.settings[event.target.name] = event.target.checked
        }

        this.setState({ item: item })
    }

    changeDossierQuery(event) {
        let item = this.state.item
        item.settings['limit_output_to_mis_item'] = event.target.value

        this.setState({ item: item })
    }

    handleCheck(event) {
        let item = this.state.item
        item[event.target.name] = event.target.checked
        this.setState({ item: item })
    }

    handleChange(event) {
        let item = this.state.item
        item[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    submitLinkField() {
        let errors = {}

        if (
            !this.state.item.name ||
            !this.state.item.typeOfLink ||
            !this.state.item.childDefDossier
        ) {
            if (
                this.state.item &&
                (!this.state.item.name || this.state.item.name.length <= 0)
            ) {
                errors['name'] = this.props.kedo.t('This is a required field')
            }
            if (
                this.state.item &&
                (!this.state.item.typeOfLink ||
                    this.state.item.typeOfLink.length <= 0)
            ) {
                errors['typeOfLink'] = this.props.kedo.t(
                    'This is a required field'
                )
            }
            if (
                this.state.item &&
                (!this.state.item.childDefDossier ||
                    this.state.item.childDefDossier.length <= 0)
            ) {
                errors['childDefDossier'] = this.props.kedo.t(
                    'This is a required field'
                )
            }

            this.setState({ errors: errors })
            return
        }

        this.setState({
            submitting: true,
            errors: false,
        })

        let item = {
            obligatory: this.state.item.obligatory === true,
            name: this.state.item.name,
            typeOfLink: this.state.item.typeOfLink,
            cascadeRule: this.state.item.cascadeRule,
            defDossier: this.state.defDossier,
            settings: this.state.item.settings,
            childDefDossier: this.state.item.childDefDossier,
        }
        if (this.props.item && this.props.item.id) {
            item.id = this.props.item.id
            item.uuid = this.props.item.uuid
        }

        if (item.id) {
            this.props.kedo
                .api()
                .patch(
                    this.props.kedo.api().getDefDossierLinkEndpoint() +
                        '/' +
                        item.id,
                    {
                        obligatory: item.obligatory,
                        cascadeRule: item.cascadeRule,
                        settings: item.settings,
                        name: item.name,
                        typeOfLink: item.typeOfLink,
                    }
                )
                .then((successResponse) => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
        } else {
            item.environment = this.props.kedo.env().getEnvironment().id
            item.defDossier = parseInt(this.state.defDossier)
            item.uuid = this.props.kedo.utils().uuidv4()

            this.props.kedo
                .api()
                .post(this.props.kedo.api().getDefDossierLinkEndpoint(), item)
                .then((successResponse) => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
        }
    }

    fetchDefDossiers() {
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 500,
            },
        }
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getDefDossierEndpoint(), params)
            .then((response) => {
                this.setState({
                    defDossiers: response.data.results,
                    loading: false,
                })
            })
        if (this.props.item && this.props.item.back_link_id) {
            this.props.kedo
                .api()
                .get(
                    this.props.kedo
                        .api()
                        .getDefDossierLinkEndpoint(this.props.item.back_link_id)
                )
                .then((response) => {
                    const { data } = response
                    this.setState({ back_link: data })
                })
        }
    }

    getDefDossierName(defDossierId) {
        let defDossier = this.state.defDossiers.find(
            (item) => item.id === parseInt(defDossierId)
        )

        if (!defDossier) {
            return ''
        }

        return defDossier.name_plural
    }

    getDossierQueriesFormControl(defDossier) {
        if (!defDossier) {
            return (
                <Alert variant={'info'}>
                    {this.props.kedo.t('No settings available')}
                </Alert>
            )
        }

        let ddObject = this.props.kedo
            .env()
            .getCurrentEnvironmentDefDossiers()
            .find((item) => item.id === defDossier)

        if (
            !ddObject ||
            !ddObject.dossierqueries ||
            ddObject.dossierqueries.length <= 0
        ) {
            return (
                <Alert variant={'info'}>
                    {this.props.kedo.t('No settings available')}
                </Alert>
            )
        }

        return (
            <Form.Control
                value={
                    this.state.item.settings &&
                    this.state.item.settings.limit_output_to_mis_item
                        ? this.state.item.settings.limit_output_to_mis_item
                        : null
                }
                onChange={this.changeDossierQuery}
                as={'select'}
            >
                <option value={''}>
                    {this.props.kedo.t('Choose an option')}
                </option>
                {ddObject.dossierqueries.map((dossierQuery) => (
                    <option key={dossierQuery.uuid} value={dossierQuery.uuid}>
                        {this.props.kedo
                            .env()
                            .translateItem(dossierQuery, 'dossierquery')}
                    </option>
                ))}
            </Form.Control>
        )
    }

    componentDidMount() {
        this.fetchDefDossiers()
    }

    render() {
        const defDossier = this.getDefDossierName(this.state.item.defDossier)
            ? this.getDefDossierName(this.state.item.defDossier)
            : this.props.kedo.t('Entity')
        const dossier =
            this.state.item &&
            this.state.item.childDefDossier &&
            this.state.item.childDefDossier.length > 0
                ? this.getDefDossierName(this.state.item.childDefDossier)
                : this.props.kedo.t('Dossier').toLowerCase()
        const kedo = this.props.kedo

        return (
            <Modal size={'lg'} show={true} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.item && this.props.item.id
                            ? kedo.t('Edit') + ': ' + this.props.item.name
                            : kedo.t('Add link')}{' '}
                        {this.props.item ? (
                            <small className={'text-muted'}>
                                {' '}
                                ({this.props.item.id})
                            </small>
                        ) : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    <Tab.Container defaultActiveKey="main">
                        <Row>
                            <Col sm={3} className={'pillsSidebar'}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="main">
                                            {kedo.t('Default')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="cascade">
                                            {kedo.t('Misc')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    {this.props.item && this.props.item.id ? (
                                        <Nav.Item>
                                            <Nav.Link eventKey="info">
                                                {kedo.t('info')}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ) : null}
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="main">
                                        {this.state.errors['generic'] ? (
                                            <Alert variant={'danger'}>
                                                {kedo.t('Something went wrong')}
                                            </Alert>
                                        ) : null}
                                        <h3>{kedo.t('Generic')}</h3>
                                        <Form.Group>
                                            <Form.Label className={'required'}>
                                                {kedo.t('Name')}
                                            </Form.Label>
                                            <Form.Control
                                                readOnly={this.state.loading}
                                                value={this.state.item.name}
                                                name={'name'}
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.name
                                                }
                                                onChange={this.handleChange}
                                            />
                                            {this.state.errors !== null &&
                                            this.state.errors.name ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.name}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className={'required'}>
                                                {kedo.t('Type')}
                                            </Form.Label>
                                            <Form.Control
                                                readOnly={this.state.loading}
                                                value={
                                                    this.state.item.typeOfLink
                                                }
                                                name={'typeOfLink'}
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.typeOfLink
                                                }
                                                onChange={this.handleChange}
                                                as={'select'}
                                            >
                                                <option value={'select'}>
                                                    {this.props.kedo.t(
                                                        'select'
                                                    )}
                                                </option>
                                                <option value={'embeddedList'}>
                                                    {this.props.kedo.t(
                                                        'embeddedList'
                                                    )}
                                                </option>
                                            </Form.Control>
                                            {this.state.errors !== null &&
                                            this.state.errors.typeOfLink ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.errors
                                                            .typeOfLink
                                                    }
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className={'required'}>
                                                {kedo.t('Entity')}
                                            </Form.Label>
                                            <Form.Control
                                                disabled={
                                                    this.state.loading ||
                                                    (this.props.item &&
                                                        this.props.item.id)
                                                }
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors
                                                        .childDefDossier
                                                }
                                                value={
                                                    this.state.item
                                                        .childDefDossier
                                                }
                                                as="select"
                                                name={'childDefDossier'}
                                                onChange={this.handleChange}
                                            >
                                                <option>
                                                    {kedo.t('Choose an option')}
                                                </option>
                                                {this.state.defDossiers.map(
                                                    (item) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {kedo
                                                                .env()
                                                                .translateItem(
                                                                    item,
                                                                    'defdossier'
                                                                )}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Control>
                                            {this.state.errors !== null &&
                                            this.state.errors
                                                .childDefDossier ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.errors
                                                            .childDefDossier
                                                    }
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                readOnly={this.state.loading}
                                                id={'obligatory'}
                                                name={'obligatory'}
                                                checked={
                                                    this.state.item.obligatory
                                                }
                                                onChange={this.handleCheck}
                                                type="checkbox"
                                                label={kedo.t('Required')}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                readOnly={this.state.loading}
                                                id={'multiple'}
                                                name={'multiple'}
                                                checked={
                                                    this.state.item.settings &&
                                                    this.state.item.settings
                                                        .multiple
                                                }
                                                onChange={
                                                    this.handleSettingsCheck
                                                }
                                                type="checkbox"
                                                label={kedo.t('Multiselect')}
                                            />
                                        </Form.Group>
                                        <hr />
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t(
                                                    'Limit options on dossier query'
                                                )}
                                            </Form.Label>
                                            {this.getDossierQueriesFormControl(
                                                this.state.item.childDefDossier
                                            )}
                                        </Form.Group>
                                        <hr />
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t('Back link')}
                                            </Form.Label>
                                            <ObjectCreatedInfo
                                                item={this.state.back_link}
                                                kedo={kedo}
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="cascade">
                                        <h3>{kedo.t('Dossier options')}</h3>
                                        <Form.Check
                                            name={'cascadeRule'}
                                            id={'maintain'}
                                            value={'maintain'}
                                            readOnly={this.state.loading}
                                            onChange={this.handleChange}
                                            checked={
                                                !this.state.item.cascadeRule ||
                                                this.state.item.cascadeRule
                                                    .length <= 0 ||
                                                this.state.item.cascadeRule ===
                                                    'maintain'
                                            }
                                            type={'radio'}
                                            label={
                                                <Trans
                                                    i18nKey="keep_objects"
                                                    dossier={dossier}
                                                >
                                                    Keep objects from{' '}
                                                    {{ dossier }}
                                                </Trans>
                                            }
                                        />
                                        <Form.Check
                                            name={'cascadeRule'}
                                            onChange={this.handleChange}
                                            id={'delete'}
                                            value={'delete'}
                                            type={'radio'}
                                            checked={
                                                this.state.item.cascadeRule ===
                                                'delete'
                                            }
                                            readOnly={this.state.loading}
                                            label={
                                                <Trans
                                                    i18nKey="delete_objects"
                                                    dossier={dossier}
                                                >
                                                    Delete objects from{' '}
                                                    {{ dossier }}
                                                </Trans>
                                            }
                                        />
                                        <Form.Check
                                            name={'cascadeRule'}
                                            onChange={this.handleChange}
                                            id={'constraint'}
                                            value={'constraint'}
                                            type={'radio'}
                                            checked={
                                                this.state.item.cascadeRule ===
                                                'constraint'
                                            }
                                            readOnly={this.state.loading}
                                            label={
                                                <Trans
                                                    i18nKey="delete_objects_no_associations"
                                                    defDossier={defDossier}
                                                    dossier={dossier}
                                                >
                                                    {{ defDossier }} only delete
                                                    objects if there are no
                                                    associations from{' '}
                                                    {{ dossier }}
                                                </Trans>
                                            }
                                        />
                                    </Tab.Pane>
                                    {this.props.item && this.props.item.id ? (
                                        <Tab.Pane eventKey="info">
                                            <h3>{kedo.t('Info')}</h3>
                                            <ObjectCreatedInfo
                                                item={this.props.item}
                                                kedo={kedo}
                                            />
                                        </Tab.Pane>
                                    ) : null}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.state.loading}
                        onClick={this.submitLinkField}
                        variant="primary"
                    >
                        {this.state.submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {kedo.t('Save')}
                    </Button>
                    <Button
                        disabled={this.state.loading}
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                    >
                        {kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DefDossierLinkForm
