import React from 'react'
import { Row, Col, FormControl, InputGroup } from 'react-bootstrap'

class DefaultSearchbar extends React.Component {
    /*** How to use:
        <DefaultSearchbar kedo={this.props.kedo} handleChange={this.handleChange} handleSearch={this.handleSearch} searchName={this.state.searchName} resetButton={this.resetButton}/>
     ***/

    render() {
        return (
            <Row>
                <Col xs={12}>
                    <div className="input-group searchField">
                        <InputGroup className="mb-3">
                            <FormControl
                                className="form-control"
                                name={'searchName'}
                                autocomplete={'off'}
                                onChange={this.props.handleChange}
                                onKeyPress={this.props.handleSearch}
                                placeholder={
                                    this.props.kedo.t('Search') + '...'
                                }
                                type="text"
                                value={this.props.searchName}
                            />
                            <InputGroup.Append>
                                {this.props.searchName &&
                                this.props.searchName.length > 0
                                    ? this.props.resetButton()
                                    : null}
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default DefaultSearchbar
