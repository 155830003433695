import React, { Component } from 'react'
import VerzoekToewijzingen from '../../Modules/IjwIwmo/VerzoekToewijzingen'
import HourOverviewIndex from '../../Modules/HourOverview/HourOverviewIndex'
import VerlofOverviewIndex from '../../Modules/VerlofOverview/VerlofOverviewIndex'
const ToegewezenProducten = React.lazy(() =>
    import('../../Modules/IjwIwmo/ToegewezenProducten')
)
const StartProducten = React.lazy(() =>
    import('../../Modules/IjwIwmo/StartProducten')
)
const StopProducten = React.lazy(() =>
    import('../../Modules/IjwIwmo/StopProducten')
)

class LoadModuleContent extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        if (!this.props.module || this.props.module.length <= 0) {
            return null
        }
        if (this.props.module === 'verlofoverview') {
            return (
                <VerlofOverviewIndex
                    module={this.props.module}
                    dossierId={this.props.dossierId}
                    kedo={this.props.kedo}
                />
            )
        } else if (this.props.module === 'houroverview') {
            return (
                <HourOverviewIndex
                    module={this.props.module}
                    dossierId={this.props.dossierId}
                    kedo={this.props.kedo}
                />
            )
        } else if (this.props.module === 'ijw_toegewezen_producten') {
            return (
                <ToegewezenProducten
                    module={this.props.module}
                    dossierId={this.props.dossierId}
                    kedo={this.props.kedo}
                />
            )
        } else if (this.props.module === 'ijw_start_producten') {
            return (
                <StartProducten
                    module={this.props.module}
                    dossierId={this.props.dossierId}
                    kedo={this.props.kedo}
                />
            )
        } else if (this.props.module === 'ijw_stop_producten') {
            return (
                <StopProducten
                    module={this.props.module}
                    dossierId={this.props.dossierId}
                    kedo={this.props.kedo}
                />
            )
        } else if (this.props.module === 'ijw_vot_index') {
            return (
                <VerzoekToewijzingen
                    module={this.props.module}
                    dossierId={this.props.dossierId}
                    kedo={this.props.kedo}
                />
            )
        }

        return null
    }
}

export default LoadModuleContent
