import React  from 'react'
import BlockEditButton from "./BlockEditButton";
import {Alert} from "react-bootstrap";
const BlockCumulioWrapper = React.lazy(() => import('./BlockCumulioWrapper')) ;

export default class BlockCumulio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            block: this.props.block,
            loading: false,
            blockSettings: this.props.block ? this.props.block.settings : null,
        };
    }

    renderCumulDashboard() {
        if (!this.state.blockSettings || !this.state.blockSettings.authKey) {
            return <Alert variant={'warning'}>{this.props.kedo.t('Missing setting', {settingName: 'authKey'})}</Alert>
        }

        if (!this.state.blockSettings.authToken) {
            return <Alert variant={'warning'}>{this.props.kedo.t('Missing setting', {settingName: 'authToken'})}</Alert>
        }

        if (!this.state.blockSettings.dashboardId) {
            return <Alert variant={'warning'}>{this.props.kedo.t('Missing setting', {settingName: 'dashboardId'})}</Alert>
        }

        return <BlockCumulioWrapper
            authKey={this.state.blockSettings.authKey}
            authToken={this.state.blockSettings.authToken}
            dashboardId={this.state.blockSettings.dashboardId}
        />
    }

    render() {
        const kedo = this.props.kedo;

        return   <div className={!this.props.dashboard ? 'kedo-block' : null} style={{height: "300px", width: '100%', marginBottom: '30px', minHeight: "300px", minWidth: "300px"}}>
            {this.props.kedo.isAdminOrEnvironmentAdmin() ? <BlockEditButton
                item={this.state.block}
                kedo={this.props.kedo}
                onSuccess={() => {this.fetchData();}}
            /> : null}
            {this.props.misc && this.props.misc.hideTitle == true ? null : <h3>{kedo.env().translateItem(this.props.block, 'mypageblock')}</h3>}
            <div>{this.renderCumulDashboard()}</div>
        </div>
    }
}