import React, { Component } from 'react'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button } from 'react-bootstrap'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify'
import InnerHTML from 'dangerously-set-html-content'

class HourOverviewIndex extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            dossierId: null,
            htmlContent: null,
            items: [],
            pager: {},
        }
    }

    async fetchData() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        const response = await api.get(
            '/module/houroverview/' + this.props.dossierId
        )
        this.setState({
            htmlContent: response.data,
            loading: false,
        })
    }

    componentDidMount() {
        this.fetchData()
    }

    render() {
        const kedo = this.props.kedo

        let settings = {
            ALLOWED_TAGS: [
                'a',
                'b',
                'br',
                'button',
                'center',
                'span',
                'table',
                'ol',
                'tbody',
                'li',
                'javascript',
                'i',
                'strong',
                'tr',
                'td',
                'th',
                'thead',
                'div',
                'p',
                'h1',
                'h2',
            ],
            ALLOWED_ATTR: ['style', 'border', 'class', 'id', 'href'],
        }

        if (this.state.error === true) {
            return (
                <Alert variant={'warning'}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    <span className={'ml-2'}>
                        {this.props.kedo.t('Something went wrong')}
                    </span>
                </Alert>
            )
        }

        if (this.state.loading) {
            return <LoadingDefault />
        }

        if (!this.state.htmlContent) {
            return <div />
        }

        return <InnerHTML html={this.state.htmlContent} />
    }
}

export default HourOverviewIndex
