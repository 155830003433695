import React, { Component } from 'react'
import {
    Button,
    Col,
    Form,
    Nav,
    Row,
    Tab,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { CirclePicker } from 'react-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircle,
    faInfo,
    faSave,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
const Flag = React.lazy(() => import('react-world-flags'))

class DefFieldListForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: [],
            availableRoles: [],
            color: null,
            rank: 0,
            errors: false,
            default: false,
            settings: null,
        }
        this.saveOption = this.saveOption.bind(this)
        this.fetchItem = this.fetchItem.bind(this)
        this.fetchRoles = this.fetchRoles.bind(this)
        this.onChangeRole = this.onChangeRole.bind(this)
        this.onChangeTranslationSettings =
            this.onChangeTranslationSettings.bind(this)
    }

    renderTranslations(translations) {
        const kedo = this.props.kedo
        if (!translations || translations.length <= 0) {
            translations = []
        }
        let locales = kedo.env().getEnvironmentLocales()

        if (!locales) {
            return (
                <div>
                    <p>{kedo.t('Geen talen in de omgeving ingesteld.')}</p>
                </div>
            )
        }

        return (
            <div>
                {' '}
                {locales.map((locale, index) => {
                    let trans = translations.find(
                        (item) => locale.code === item.culture
                    )
                    if (!trans) {
                        trans = {}
                    }
                    return (
                        <div key={index}>
                            <Form.Group>
                                <Form.Label>
                                    {kedo.t('Name')}{' '}
                                    <Flag
                                        code={locale.code.substr(
                                            locale.code.length - 2
                                        )}
                                        height="12"
                                        className={'mr-2'}
                                    />
                                </Form.Label>
                                <Form.Control
                                    value={trans.text}
                                    isInvalid={!!this.state.errors[locale]}
                                    type={'text'}
                                    onChange={(event) =>
                                        this.onChangeTranslation(
                                            locale.code,
                                            event.target.value
                                        )
                                    }
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {kedo.t('Information text')}
                                </Form.Label>
                                <Form.Control
                                    value={
                                        trans.settings &&
                                        trans.settings.informationtext
                                            ? trans.settings.informationtext
                                            : null
                                    }
                                    as="textarea"
                                    rows="3"
                                    onChange={(event) =>
                                        this.onChangeTranslationSettings(
                                            locale.code,
                                            event.target.value,
                                            'informationtext'
                                        )
                                    }
                                />
                            </Form.Group>
                        </div>
                    )
                })}
            </div>
        )
    }

    fetchItem() {
        const kedo = this.props.kedo
        this.setState({ loading: true })
        kedo.api()
            .get(kedo.api().getDefFieldListEndpoint() + '/' + this.props.id)
            .then((response) => {
                this.fetchRoles()
                this.setState({
                    translations: response.data.translations,
                    color: response.data.color,
                    rank: response.data.rank,
                    default: response.data.default,
                    settings: response.data.settings,
                    loading: false,
                })
            })
    }

    onChangeTranslationSettings(locale, text, textKey) {
        let transes = this.state.translations

        if (!transes || transes.length <= 0) {
            transes = []
        }

        let trans = transes.find((item) => item.culture === locale)
        let transl = transes.filter((item) => item.culture !== locale)
        transes = transl
        if (!trans) {
            trans = { culture: locale, text: '', settings: {} }
        } else {
            if (!trans.settings) {
                trans.settings = {}
            } else if (Array.isArray(trans.settings)) {
                trans.settings = {}
            }
        }

        if (textKey === 'informationtext') {
            trans.settings.informationtext = text
        } else if (textKey === 'pretext') {
            trans.settings.pretext = text
        }

        transes.push(trans)

        this.setState({ translations: transes })
    }

    onChangeTranslation(locale, text) {
        let transes = this.state.translations

        if (!transes || transes.length <= 0) {
            transes = []
        }

        let trans = transes.find((item) => item.culture === locale)
        let transl = transes.filter((item) => item.culture !== locale)

        transes = transl
        if (!trans) {
            trans = { culture: locale, text: text }
        } else {
            trans.text = text
        }
        transes.push(trans)

        this.setState({ translations: transes })
    }

    saveOption() {
        const kedo = this.props.kedo
        let item = {
            color: this.state.color,
            default: this.state.default,
            defField: this.props.defFieldId,
            translations: this.state.translations,
            rank: this.state.rank,
            settings: this.state.settings,
            environment: kedo.env().getEnvironment().id,
        }

        this.setState({ addingOption: true })
        if (this.props.id) {
            kedo.api()
                .patch(
                    kedo.api().getDefFieldListEndpoint() + '/' + this.props.id,
                    item
                )
                .then((response) => {
                    if (this.props.onSuccess) {
                        this.props.onSuccess(item)
                    }
                })
        } else {
            kedo.api()
                .post(kedo.api().getDefFieldListEndpoint(), item)
                .then((response) => {
                    item.id = response.data.id
                    item.uuid = response.data.uuid
                    if (this.props.onSuccess) {
                        this.props.onSuccess(item)
                    }
                })
        }
    }

    componentDidMount() {
        if (this.props.id) {
            this.fetchItem()
        }
    }

    fetchRoles() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.get(api.getRoleEndpoint(), {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 200,
            },
        }).then((response) =>
            this.setState({
                loading: false,
                availableRoles: response.data.results,
            })
        )
    }

    onChangeRole(e) {
        let settings = this.state.settings

        if (!settings) {
            settings = {}
        }

        if (!Array.isArray(settings.roles)) {
            settings.roles = []
        }

        settings.roles = settings.roles.filter(
            (roleId) => e.target.value != roleId
        )

        if (e.target.checked) {
            settings.roles.push(parseInt(e.target.value))
        }

        this.setState({ settings: settings })
    }

    render() {
        if (!this.props.defFieldId) {
            return ''
        }
        const kedo = this.props.kedo

        if (this.state.loading) {
            return <LoadingDefault />
        }

        return (
            <Tab.Container defaultActiveKey="general">
                <Row>
                    <Col sm={3} className={'pillsSidebar'}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="general">
                                    {kedo.t('Generic')}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="layout">
                                    {kedo.t('Layout')}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="visibility">
                                    {kedo.t('Visibility')}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9} className={'pillsContent'}>
                        <Tab.Content>
                            <Tab.Pane eventKey="general">
                                {this.renderTranslations(
                                    this.state.translations
                                )}
                                <Form.Group>
                                    <Form.Check
                                        checked={this.state.default}
                                        key={`list-option-default`}
                                        label={kedo.t('Default')}
                                        name={'default'}
                                        onChange={(e) =>
                                            this.setState({
                                                default: !this.state.default,
                                            })
                                        }
                                        type="checkbox"
                                    />
                                </Form.Group>
                            </Tab.Pane>
                            <Tab.Pane eventKey={'layout'}>
                                <h3>{kedo.t('Layout')}</h3>
                                <Form.Group>
                                    <Form.Label>{kedo.t('Color')}</Form.Label>
                                    {this.state.color ? (
                                        <span>
                                            &nbsp;{' '}
                                            <FontAwesomeIcon
                                                style={{
                                                    color: this.state.color,
                                                }}
                                                icon={faCircle}
                                            />
                                            &nbsp;
                                        </span>
                                    ) : null}
                                    {this.state.color ? (
                                        <Button
                                            size={'sm'}
                                            onClick={() => {
                                                this.setState({ color: null })
                                            }}
                                            title={'Clear'}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    ) : null}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        {kedo.t('Choose an option')}
                                    </Form.Label>
                                    <CirclePicker
                                        value={this.state.color}
                                        onChangeComplete={(color, event) => {
                                            this.setState({ color: color.hex })
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{kedo.t('Rank')}</Form.Label>
                                    <Form.Control
                                        value={this.state.rank}
                                        min={'0'}
                                        isInvalid={!!this.state.errors.rank}
                                        type={'number'}
                                        onChange={(e) =>
                                            this.setState({
                                                rank: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Tab.Pane>
                            <Tab.Pane eventKey="visibility">
                                <h3>{kedo.t('Visibility')}</h3>
                                <Form.Group>
                                    <Form.Label>
                                        {kedo.t('Roles')}
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={(props) => (
                                                <Tooltip
                                                    id="button-tooltip"
                                                    {...props}
                                                >
                                                    {kedo.t(
                                                        'Select role(s) to hide this specific option'
                                                    )}
                                                </Tooltip>
                                            )}
                                        >
                                            <Button size={'sm'} variant="info">
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    {this.state.availableRoles.map((item) => (
                                        <Form.Check
                                            key={item.id}
                                            value={item.id}
                                            checked={
                                                this.state.settings &&
                                                this.state.settings.roles &&
                                                this.state.settings.roles.find(
                                                    (roleId) =>
                                                        roleId === item.id
                                                )
                                            }
                                            onChange={this.onChangeRole}
                                            type={'checkbox'}
                                            label={item.name}
                                            id={`role-${item.id}`}
                                        />
                                    ))}
                                </Form.Group>
                            </Tab.Pane>
                        </Tab.Content>
                        <Form.Group className="text-right">
                            <Button
                                variant={'primary'}
                                onClick={this.saveOption}
                            >
                                {this.state.addingOption ? (
                                    <LoadingDefault as={'span'} />
                                ) : (
                                    <FontAwesomeIcon icon={faSave} />
                                )}
                                &nbsp; {this.props.kedo.t('Save')}
                            </Button>
                            &nbsp;
                            <Button
                                variant={'secondary'}
                                onClick={this.props.onClose}
                            >
                                {kedo.t('Cancel')}
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }
}

export default DefFieldListForm
