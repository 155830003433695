import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'

class MultiContentDossierDelete extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DeleteDefDossierForm: false,
            submitting: false,
        }

        this.deleteSelectedItems = this.deleteSelectedItems.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({ DeleteDefDossierForm: false })
    }

    handleErrors(errorData) {
        //TODO
    }

    async deleteSelectedItems() {
        if (!this.props.items) {
            return
        }

        this.setState({ submitting: true })
        let selectedItems = this.props.items
        let x

        let allSelectedItems = selectedItems.length
        let counter = 0

        const forLoop = async (_) => {
            for (x of selectedItems) {
                this.setState({ delete: true })

                try {
                    const response = await this.props.kedo
                        .api()
                        .delete(
                            this.props.kedo.api().getContentDossierEndpoint() +
                                '/' +
                                x
                        )
                    counter = counter + 1
                    if (counter === allSelectedItems) {
                        this.setState({
                            submitting: false,
                            DeleteDefDossierForm: false,
                        })
                        this.props.onClose()
                    }
                } catch (e) {
                    this.handleErrors(e.response)
                    this.setState({
                        delete: false,
                        errors: true,
                    })
                }
            }
        }
        await forLoop()
    }

    formDelete() {
        return (
            <Modal show={true} variant={'danger'} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t('Confirmation')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {this.props.items.length > 1
                            ? this.props.kedo.t('confirm_delete_text_plural')
                            : this.props.kedo.t('confirm_delete_text_singular')}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.deleteSelectedItems} variant="danger">
                        {this.state.submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faTrashAlt} />
                        )}
                        &nbsp; {this.props.kedo.t('Delete')}
                    </Button>{' '}
                    &nbsp;
                    <Button onClick={this.handleClose} variant="secondary">
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    displayButton(onClick, button, tooltip = null) {
        if (!tooltip)
            return (
                <Button variant={'outline-danger'} onClick={onClick}>
                    {button}
                </Button>
            )

        return (
            <>
                <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip>{tooltip}</Tooltip>}
                >
                    <Button variant={'outline-danger'} onClick={onClick}>
                        {button}
                    </Button>
                </OverlayTrigger>
            </>
        )
    }

    render() {
        if (this.state.DeleteDefDossierForm) {
            return this.formDelete()
        }

        const mode = this.props.mode

        return (
            <>
                {this.displayButton(
                    () =>
                        this.setState({
                            DeleteDefDossierForm: true,
                            loading: true,
                        }),
                    <>
                        <FontAwesomeIcon icon={faTrashAlt} />{' '}
                        {mode &&
                        (mode === 'embeddedList' || mode === 'block') ? null : (
                            <>&nbsp; {this.props.kedo.t('Delete')}</>
                        )}
                    </>,
                    mode && (mode === 'embeddedList' || mode === 'block')
                        ? this.props.kedo.t('Delete')
                        : null
                )}
            </>
        )
    }
}

export default MultiContentDossierDelete
