import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import SelectList from '../../Elements/SelectList'
import DisplayItemLinkSortSettings from './DisplayItemLinkSortSettings'

class DisplayItemLinkSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blocks: [],
            customDisplayPos: [],
            displayPos: [],
            views:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.views
                    ? this.props.item.settings.views
                    : [],
            block:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.block
                    ? this.props.item.settings.block
                    : null,
            sort:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.sort
                    ? this.props.item.settings.sort
                    : null,
            hide_add:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.hide_add === true
                    ? true
                    : false,
            show_multi_add:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.show_multi_add === true
                    ? true
                    : false,
            only_allow_add:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.only_allow_add === true
                    ? true
                    : false,
            clickable:
                this.props.item &&
                this.props.item.settings &&
                typeof this.props.item.settings.clickable === 'boolean'
                    ? this.props.item.settings.clickable
                    : true,
            show_email:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.show_email === true
                    ? true
                    : false,
            enable_small_view:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.enable_small_view === true
                    ? true
                    : false,
            show_brp:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.show_brp === true
                    ? true
                    : false,
            show_embedded:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.show_embedded === true
                    ? true
                    : false,
            custom_view:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.custom_view
                    ? this.props.item.settings.custom_view
                    : null,
            limit_embedded_link:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.limit_embedded_link
                    ? this.props.item.settings.limit_embedded_link
                    : false,
            default_val_dos_query:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.default_val_dos_query
                    ? this.props.item.settings.default_val_dos_query
                    : false,
            passing_sidebar:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.passing_sidebar
                    ? this.props.item.settings.passing_sidebar
                    : false,
        }
        this.onChangeView = this.onChangeView.bind(this)
        this.extraSettings = this.extraSettings.bind(this)
        this.fetchDisplayPos = this.fetchDisplayPos.bind(this)
    }

    fetchDisplayPos() {
        const kedo = this.props.kedo
        let params = {
            params: { defDossier: this.props.item.def_dossier_id, limit: 500 },
        }
        kedo.api()
            .get(kedo.api().getDisplayPositionEndpoint(), params)
            .then((displayPos) =>
                kedo
                    .api()
                    .get(kedo.api().getDisplayCustomViewEndpoint(), params)
                    .then((displayPosCustom) =>
                        this.setState({
                            displayPos: [
                                ...displayPos.data.results,
                                ...displayPosCustom.data.results,
                            ],
                        })
                    )
            )

        //Check for child defDossier id, to get custom views
        if (
            this.props.item.def_dossier_link &&
            this.props.item.def_dossier_link.child_def_dossier_id
        ) {
            let linkParams = {
                params: {
                    defDossier:
                        this.props.item.def_dossier_link.child_def_dossier_id,
                    limit: 500,
                },
            }

            kedo.api()
                .get(kedo.api().getDisplayCustomViewEndpoint(), linkParams)
                .then((linkPos) => {
                    this.setState({
                        customDisplayPos: linkPos.data.results,
                    })
                })
        }
    }

    extraSettings() {
        const kedo = this.props.kedo

        return (
            <>
                <Form.Group>
                    <Form.Label className={'required'}>
                        {this.props.kedo.t('Select view for sidebar')}
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={this.state.passing_sidebar}
                        name={'passing_sidebar'}
                        onChange={(e) => {
                            this.setState(
                                { passing_sidebar: e.target.value },
                                this.props.onChange(
                                    'passing_sidebar',
                                    e.target.value !== ''
                                        ? e.target.value
                                        : null
                                )
                            )
                        }}
                    >
                        <option value="">{kedo.t('Switched off')}</option>
                        <optgroup label={kedo.t('Available views')}>
                            {this.state.displayPos &&
                                this.state.displayPos.map((pos) => (
                                    <option
                                        key={pos.id}
                                        value={
                                            pos && pos.view_name
                                                ? pos.view_name
                                                : pos.id
                                        }
                                    >
                                        {pos && pos.view_name
                                            ? pos.view_name
                                            : kedo
                                                  .env()
                                                  .translateItem(
                                                      pos,
                                                      'displayposition',
                                                      true
                                                  )}
                                    </option>
                                ))}
                        </optgroup>
                    </Form.Control>
                </Form.Group>
            </>
        )
    }

    fetchBlock(id) {
        if (!id) {
            return
        }

        const api = this.props.kedo.api()
        api.get(api.getMyPageBlockEndpoint(id)).then((response) =>
            this.setState({ block_data: response.data })
        )
    }

    onChangeView(e) {
        let views = this.state.views
        views = views.filter((item) => item !== e.target.value)
        if (e.target.checked) {
            views.push(e.target.value)
        }
        this.setState({ views: views }, () =>
            this.props.onChange('views', views)
        )
    }

    fetchData() {
        const api = this.props.kedo.api()

        api.get(api.getMyPageBlockEndpoint(), {
            params: {
                defDossier:
                    this.props.item.def_dossier_link.child_def_dossier_id,
                type: 'dashboard',
                environment: this.props.kedo.env().getEnvironment().id,
            },
        }).then((response) => this.setState({ blocks: response.data.results }))
    }

    componentDidMount() {
        this.fetchData()
        this.fetchDisplayPos()
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    render() {
        const kedo = this.props.kedo
        const hasBrp = kedo
            .env()
            .getCurrentEnvironmentModules()
            .find((item) => item.module === 'brp')
            ? true
            : false

        return (
            <div>
                <h3>{kedo.t('Settings')}</h3>
                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Show as hyperlink')}
                        onChange={(e) => {
                            this.setState({ clickable: e.target.checked })
                            this.props.onChange('clickable', e.target.checked)
                        }}
                        name={'clickable'}
                        checked={this.state.clickable === true}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Hide add button')}
                        onChange={(e) => {
                            this.setState({ hide_add: e.target.checked })
                            this.props.onChange('hide_add', e.target.checked)
                        }}
                        name={'hide_add'}
                        checked={this.state.hide_add === true}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Show multi add button')}
                        onChange={(e) => {
                            this.setState({ show_multi_add: e.target.checked })
                            this.props.onChange(
                                'show_multi_add',
                                e.target.checked
                            )
                        }}
                        name={'show_multi_add'}
                        checked={this.state.show_multi_add === true}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Only allow add')}
                        onChange={(e) => {
                            this.setState({ only_allow_add: e.target.checked })
                            this.props.onChange(
                                'only_allow_add',
                                e.target.checked
                            )
                        }}
                        name={'only_allow_add'}
                        checked={this.state.only_allow_add === true}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Show email button')}
                        onChange={(e) => {
                            this.setState({ show_email: e.target.checked })
                            this.props.onChange('show_email', e.target.checked)
                        }}
                        name={'show_email'}
                        checked={this.state.show_email === true}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Enable small view')}
                        onChange={(e) => {
                            this.setState({
                                enable_small_view: e.target.checked,
                            })
                            this.props.onChange(
                                'enable_small_view',
                                e.target.checked
                            )
                        }}
                        name={'enable_small_view'}
                        checked={this.state.enable_small_view === true}
                    />
                </Form.Group>
                {hasBrp ? (
                    <Form.Group>
                        <Form.Check
                            type={'checkbox'}
                            label={kedo.t('Show brp')}
                            onChange={(e) => {
                                this.setState(
                                    { show_brp: e.target.checked },
                                    () =>
                                        this.props.onChange(
                                            'show_brp',
                                            e.target.checked
                                        )
                                )
                            }}
                            name={'show_brp'}
                            checked={this.state.show_brp === true}
                        />
                    </Form.Group>
                ) : null}
                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Show embedded')}
                        onChange={(e) => {
                            this.setState(
                                { show_embedded: e.target.checked },
                                () =>
                                    this.props.onChange(
                                        'show_embedded',
                                        e.target.checked
                                    )
                            )
                        }}
                        name={'show_embedded'}
                        checked={this.state.show_embedded === true}
                    />
                </Form.Group>
                {this.extraSettings()}
                <Form.Group>
                    <Form.Label>
                        {kedo.t('Limit by embedded dossier link field')}
                    </Form.Label>
                    <SelectList
                        currentValue={this.state.limit_embedded_link}
                        onClear={() => {
                            this.setState({ limit_embedded_link: null })
                            this.props.onChange('limit_embedded_link', null)
                        }}
                        disableInit={true}
                        searchName={'search'}
                        kedo={kedo}
                        urlParams={{
                            environment: kedo.env().getEnvironment().id,
                        }}
                        onDisplayName={(item) => {
                            return item.id + ': ' + item.name
                        }}
                        url={kedo.api().getDefDossierLinkEndpoint()}
                        onSelect={(item) => {
                            this.setState({ limit_embedded_link: item.id })
                            this.props.onChange('limit_embedded_link', item.id)
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        {kedo.t('Default values dossier query')}
                    </Form.Label>
                    <SelectList
                        currentValue={this.state.default_val_dos_query}
                        onClear={() => {
                            this.setState({ default_val_dos_query: null })
                            this.props.onChange('default_val_dos_query', null)
                        }}
                        disableInit={true}
                        searchName={'search'}
                        kedo={kedo}
                        urlParams={{
                            environment: kedo.env().getEnvironment().id,
                            defDossier:
                                this.props.item.def_dossier_link
                                    .child_def_dossier_id,
                        }}
                        onDisplayName={(item) => {
                            return (
                                item.id +
                                ': ' +
                                this.props.kedo
                                    .env()
                                    .translateItem(item, 'dossierquery')
                            )
                        }}
                        url={kedo.api().getDossierQueryEndpoint()}
                        onSelect={(item) => {
                            this.setState({ default_val_dos_query: item.id })
                            this.props.onChange(
                                'default_val_dos_query',
                                item.id
                            )
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{kedo.t('Enable views')}</Form.Label>
                    {[
                        'calendar',
                        'gantt',
                        'kanban',
                        'list',
                        'timeline',
                        'hourregistration',
                    ].map((viewItem) => (
                        <Form.Check
                            id={viewItem}
                            key={viewItem}
                            checked={this.state.views.includes(viewItem)}
                            label={kedo.t(this.capitalizeFirstLetter(viewItem))}
                            name={'views'}
                            onChange={this.onChangeView}
                            type="checkbox"
                            value={viewItem}
                        />
                    ))}
                </Form.Group>
                {this.props.item.def_dossier_link.type_of_link ===
                    'embeddedList' && (
                    <Form.Group>
                        <Form.Label>{kedo.t('Custom views')}</Form.Label>
                        <Form.Control
                            as="select"
                            id={'custom_link_view'}
                            value={this.state.custom_view}
                            onChange={(e) => {
                                this.setState({ custom_view: e.target.value })
                                this.props.onChange(
                                    'custom_view',
                                    e.target.value
                                )
                            }}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            {this.state.customDisplayPos &&
                                this.state.customDisplayPos.map((pos) => (
                                    <option
                                        key={pos.id}
                                        value={
                                            pos && pos.view_name
                                                ? pos.view_name
                                                : pos.id
                                        }
                                    >
                                        {pos && pos.view_name
                                            ? pos.view_name
                                            : kedo
                                                  .env()
                                                  .translateItem(
                                                      pos,
                                                      'displayposition',
                                                      true
                                                  )}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Label>{kedo.t('Block')}</Form.Label>
                    <Form.Control
                        name={'block'}
                        onChange={(e) => {
                            this.setState({ block: e.target.value })
                            this.fetchBlock(e.target.value)
                            this.props.onChange('block', e.target.value)
                        }}
                        value={this.state.block}
                        as={'select'}
                    >
                        <option value={''}>{kedo.t('Choose an option')}</option>
                        {this.state.blocks.map((block) => (
                            <option key={block.id} value={block.id}>
                                {kedo.env().translateItem(block, 'mypageblock')}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <DisplayItemLinkSortSettings
                        defDossierId={
                            this.props.item.def_dossier_link
                                .child_def_dossier_id
                        }
                        sort={this.state.sort}
                        onChangeSort={(sortSettings) => {
                            this.setState({ sort: sortSettings })
                            this.props.onChange('sort', sortSettings)
                        }}
                        kedo={kedo}
                    />
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemLinkSettings
