import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from 'react-bootstrap'
import NewContentDossier from '../Pages/ContentDossier/NewContentDossier'

class DossierModalNewButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            add: this.props.add === true,
            size: this.props.size ? this.props.size : 'sm',
        }
    }

    renderAdd() {
        return (
            <Modal
                key={`new-inline-modal-${this.props.defDossierId}`}
                centered
                backdrop="static"
                show={true}
                onHide={() => {
                    this.props.onClose ? this.props.onClose() : null
                    this.setState({ add: false })
                }}
                size={'xl'}
            >
                <Modal.Header
                    closeButton
                    closeLabel={this.props.kedo.t('Close')}
                />
                <Modal.Body>
                    <NewContentDossier
                        location={this.props.location}
                        content={this.props.content}
                        onSuccess={(dossierId) => {
                            this.setState({ add: false })
                            this.props.onSuccess(dossierId)
                        }}
                        onClose={() => {
                            this.setState({ add: false })
                            this.props.onClose()
                        }}
                        defDossierId={this.props.defDossierId}
                        disableBreadcrumb={true}
                        kedo={this.props.kedo}
                    />
                </Modal.Body>
            </Modal>
        )
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.add !== this.state.add) {
            return true
        }

        return false
    }

    render() {
        if (!this.props.defDossierId) {
            return ''
        }

        if (
            this.props.kedo
                .env()
                .isAllowedDefDossier(
                    'create',
                    this.props.defDossierId,
                    this.props.kedo.user()
                ) !== true
        ) {
            return ''
        }

        return (
            <>
                {this.state.add === true ? this.renderAdd() : null}
                <Button
                    className={'new-dossier-inline'}
                    onClick={() => this.setState({ add: true })}
                    size={this.state.size}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </>
        )
    }
}

export default DossierModalNewButton
