import React, { Component } from 'react'
import { Alert, Form } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectList from '../../Elements/SelectList'

class DisplayItemCustomActionButtonSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            action:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.action
                    ? this.props.item.settings.action
                    : null,
            kedocx:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.kedocx
                    ? this.props.item.settings.kedocx
                    : null,
            kedocx_format:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.kedocx_format
                    ? this.props.item.settings.kedocx_format
                    : null,
            event_id:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.event_id
                    ? this.props.item.settings.event_id
                    : null,
            popup_view:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.popup_view
                    ? this.props.item.settings.popup_view
                    : null,
            popup_custom_view:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.popup_custom_view
                    ? this.props.item.settings.popup_custom_view
                    : null,
            kedocxs: [],
            kedocxs_loaded: false,
        }
        this.onChange = this.onChange.bind(this)
        this.fetchKedocxs = this.fetchKedocxs.bind(this)
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })

        this.props.onChange(event.target.name, event.target.value)
    }

    fetchKedocxs() {
        const api = this.props.kedo.api()
        let filter = {
            params: {
                defDossier: this.props.item.def_dossier_id,
            },
        }

        api.get(api.getKedocxEndpoint(), filter).then((response) =>
            this.setState({
                kedocxs: response.data.results,
                kedocxs_loaded: true,
            })
        )
    }

    renderKedocxSettings() {
        if (this.state.kedocxs_loaded === false) {
            this.fetchKedocxs()
        }

        return (
            <>
                <Form.Label>
                    <strong>
                        {this.props.kedo.t('Kedocx')}{' '}
                        {this.props.kedo.t('Template').toLowerCase()}
                    </strong>
                </Form.Label>
                {this.state.kedocxs_loaded && this.state.kedocxs.length <= 0 ? (
                    <Alert variant={'warning'}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        &nbsp; {this.props.kedo.t('No files found')}
                    </Alert>
                ) : this.state.kedocxs_loaded === true ? (
                    <>
                        <Form.Group>
                            <Form.Control
                                value={this.state.kedocx}
                                onChange={this.onChange}
                                name={'kedocx'}
                                as={'select'}
                            >
                                <option>
                                    {this.props.kedo.t('Choose an option')}
                                </option>
                                {this.state.kedocxs.map((item) => (
                                    <option key={item.uuid} value={item.uuid}>
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                {this.props.kedo.t('Format')}
                            </Form.Label>
                            <Form.Control
                                value={this.state.kedocx_format}
                                onChange={this.onChange}
                                name={'kedocx_format'}
                                as={'select'}
                            >
                                <option>
                                    {this.props.kedo.t('Choose an option')}
                                </option>
                                <option value={'pdf'}>PDF</option>
                                <option value={'docx'}>Word (.docx)</option>
                                <option value={'html'}>HTML</option>
                            </Form.Control>
                        </Form.Group>
                    </>
                ) : (
                    <LoadingDefault as={'span'} />
                )}
            </>
        )
    }

    renderFireEventView() {
        return (
            <div className="col-md-12">
                <strong>
                    {this.props.kedo.t(
                        'Select event to be triggered upon click'
                    )}
                </strong>
                <SelectList
                    currentValue={this.state.event_id}
                    disableInit={true}
                    onClear={() =>
                        this.setState({ event_id: null }, () =>
                            this.props.onChange('event_id', null)
                        )
                    }
                    searchName={'search'}
                    kedo={this.props.kedo}
                    urlParams={{ defDossier: this.props.item.def_dossier_id }}
                    onDisplayName={(item) => {
                        return item.id + ': ' + item.name
                    }}
                    url={this.props.kedo.api().getEventEndpoint()}
                    onSelect={(item) => {
                        this.setState({ event_id: item.id }, () =>
                            this.props.onChange('event_id', item.id)
                        )
                    }}
                />
            </div>
        )
    }

    renderPopupTabOrView() {
        return (
            <div className="col-md-12">
                <strong>
                    {this.props.kedo.t('Select modal position to be shown')}
                </strong>
                <SelectList
                    currentValue={this.state.popup_view}
                    disableInit={true}
                    onClear={() =>
                        this.setState({ popup_view: null }, () =>
                            this.props.onChange('popup_view', null)
                        )
                    }
                    searchName={'search'}
                    kedo={this.props.kedo}
                    urlParams={{ defDossier: this.props.item.def_dossier_id }}
                    onDisplayName={(item) => {
                        return (
                            item.id +
                            ': ' +
                            this.props.kedo
                                .env()
                                .translateItem(item, 'displayposition')
                        )
                    }}
                    url={this.props.kedo.api().getDisplayPositionEndpoint()}
                    onSelect={(item) => {
                        this.setState({ popup_view: item.id }, () =>
                            this.props.onChange('popup_view', item.id)
                        )
                    }}
                />
                <strong>
                    {this.props.kedo.t('Select custom position to be shown')}
                </strong>
                <SelectList
                    currentValue={this.state.popup_custom_view}
                    onClear={() =>
                        this.setState({ popup_view: null }, () =>
                            this.props.onChange('popup_custom_view', null)
                        )
                    }
                    disableInit={true}
                    searchName={'search'}
                    kedo={this.props.kedo}
                    urlParams={{ defDossier: this.props.item.def_dossier_id }}
                    onDisplayName={(item) => {
                        return (
                            item.id +
                            ': ' +
                            this.props.kedo
                                .env()
                                .translateItem(item, 'displayposition')
                        )
                    }}
                    url={this.props.kedo.api().getDisplayCustomViewEndpoint()}
                    onSelect={(item) => {
                        this.setState({ popup_custom_view: item.id }, () =>
                            this.props.onChange('popup_custom_view', item.id)
                        )
                    }}
                />
            </div>
        )
    }

    render() {
        const kedo = this.props.kedo

        return (
            <div>
                <Form.Group>
                    <Form.Label>{kedo.t('Action')}</Form.Label>
                    <Form.Control
                        value={this.state.action}
                        name={'action'}
                        onChange={this.onChange}
                        as="select"
                    >
                        <option value={''}>{kedo.t('Choose an option')}</option>
                        <option value={'kedocx'}>{kedo.t('Kedocx')}</option>
                        <option value={'popup_tab_or_view'}>
                            {kedo.t('popup_tab_or_view')}
                        </option>
                        <option value={'fire_event'}>
                            {kedo.t('fire_event')}
                        </option>
                    </Form.Control>
                </Form.Group>
                {this.state.action === 'kedocx' ? <hr /> : null}
                {this.state.action === 'kedocx'
                    ? this.renderKedocxSettings()
                    : null}
                {this.state.action === 'popup_tab_or_view'
                    ? this.renderPopupTabOrView()
                    : null}
                {this.state.action === 'fire_event'
                    ? this.renderFireEventView()
                    : null}
            </div>
        )
    }
}

export default DisplayItemCustomActionButtonSettings
