import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Alert,
    Accordion,
    Card,
    Button,
    Form,
    Modal,
    Tab,
    Nav,
    Col,
    Row,
} from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { faExclamationCircle, faSave } from '@fortawesome/free-solid-svg-icons'
import DisplayItemSettings from './DisplayItemSettings/DisplayItemSettings'
import LocaleName from '../Elements/Translations/LocaleName'
import ObjectCreatedInfo from '../Elements/ObjectCreatedInfo'
import SelectList from '../Elements/SelectList'

class DisplayItemForm extends Component {
    constructor(props) {
        super(props)

        //TODO: Change this
        if (this.props.item && this.props.item.def_dossier_def_field) {
            this.props.item.defDossierDefField =
                this.props.item.def_dossier_def_field
        }
        //TODO: change this
        if (this.props.item && this.props.item.def_dossier_link) {
            this.props.item.defDossierLink = this.props.item.def_dossier_link
        }

        this.state = {
            loading: false,
            fetchingPositions: false,
            fetchingLinks: false,
            fetchingFields: false,
            defDossier: this.props.defDossier,
            submitting: false,
            errors: false,
            dataFields: [],
            linkFields: [],
            displayPositions: [],
            baseFields: [],
            availableRoles: [],
            item: this.props.item
                ? this.props.item
                : {
                      col: 1,
                      rank: 1,
                      roles: [],
                      translations: [],
                  },
        }

        this.handleErrors = this.handleErrors.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onChangeTranslation = this.onChangeTranslation.bind(this)
        this.onChangeRole = this.onChangeRole.bind(this)
        this.onChangeTranslationSettings =
            this.onChangeTranslationSettings.bind(this)
        this.onChangeObjectField = this.onChangeObjectField.bind(this)
        this.submitDisplayItem = this.submitDisplayItem.bind(this)
        this.onChangeSettings = this.onChangeSettings.bind(this)
    }

    /**
     * @param response
     * @returns {boolean}
     */
    hasFormErrors(response) {
        if (
            response.response.status === 400 &&
            response.response.data &&
            response.response.data.errors
        ) {
            return true
        }

        return false
    }

    handleSuccess(newItem) {
        setTimeout(
            () =>
                this.setState({
                    submitting: false,
                    item: {},
                    errors: false,
                }),
            300
        )

        this.props.onClose(true, newItem)
        if (this.props.onSuccess) {
            this.props.onSuccess()
        }
    }

    handleErrors(response) {
        if (!this.hasFormErrors(response)) {
            this.setState({
                submitting: false,
                errors: { generic: this.props.kedo.t('Something went wrong') },
            })
            return
        }
        this.setState({
            submitting: false,
            errors: response.response.data.errors,
        })
    }

    handleChange(event) {
        let item = this.state.item
        item[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    submitDisplayItem() {
        this.setState({
            submitting: true,
            errors: false,
        })

        let errors = {}
        let hasErrors = false
        let item = {
            col: this.state.item.col,
            rank: this.state.item.rank,
            rowspan: 0,
            colspan: this.state.item.colspan,
            view: this.state.item.view,
            defaultPermissionType: '',
            roles:
                this.state.item && this.state.item.roles
                    ? this.state.item.roles
                    : [],
            translations: this.state.item.translations,
        }

        item.permissions_type = this.state.item.permissions_type

        if (this.state.item.settings) {
            item.settings = this.state.item.settings
        }

        if (this.props.item && this.props.item.id) {
            item.id = this.props.item.id
        } else if (this.props.id) {
            item.id = this.props.id
        }

        if (this.state.item.display_position) {
            item.displayPosition = this.state.item.display_position.id
        }

        if (this.state.item.defDossierDefField) {
            item.defDossierDefField = this.state.item.defDossierDefField.id
        } else if (this.state.item.defDossierLink) {
            item.defDossierLink = this.state.item.defDossierLink.id
        } else if (
            !this.state.item.defDossierLink &&
            !this.state.item.defDossierDefField &&
            this.state.item.settings.block
        ) {
            //Chart settings
        } else if (
            !this.state.item.defDossierLink &&
            !this.state.item.defDossierDefField &&
            this.state.item.settings &&
            this.state.item.settings.dossier_data
        ) {
            //Dossier data field
        } else {
            hasErrors = true
            errors.defDossierDefField = this.props.kedo.t(
                'Choose a data field or a link field'
            )
            errors.defDossierLink = this.props.kedo.t(
                'Choose a data field or a link field'
            )
        }

        if (!item.view || item.view.length <= 0) {
            errors.view = this.props.kedo.t('This is a required field')
            hasErrors = true
        }

        if (hasErrors) {
            this.setState({
                submitting: false,
                errors: errors,
            })
            return
        }

        if (item.id) {
            this.props.kedo
                .api()
                .patch(
                    this.props.kedo.api().getDisplayItemEndpoint() +
                        '/' +
                        item.id,
                    item
                )
                .then((successResponse) => this.handleSuccess(item))
                .catch((errResponse) => this.handleErrors(errResponse))
        } else {
            item.environment = this.props.kedo.env().getEnvironment().id
            item.defDossier = parseInt(this.state.defDossier)
            item.uuid = this.props.kedo.utils().uuidv4()

            this.props.kedo
                .api()
                .post(this.props.kedo.api().getDisplayItemEndpoint(), item)
                .then((successResponse) => {
                    item.id = successResponse.data.id
                    this.handleSuccess(item)
                })
                .catch((errResponse) => this.handleErrors(errResponse))
        }
    }

    renderErrorFeedback(errorMsg) {
        if (!errorMsg) {
            return
        }

        return (
            <Form.Control.Feedback type="invalid">
                {errorMsg}
            </Form.Control.Feedback>
        )
    }

    onChangeTranslationSettings(locale, text, textKey) {
        let ddiItem = this.state.item

        if (!ddiItem.translations || ddiItem.translations.length <= 0) {
            ddiItem.translations = []
        }

        let trans = ddiItem.translations.find((item) => item.culture === locale)
        let translations = ddiItem.translations.filter(
            (item) => item.culture !== locale
        )

        ddiItem.translations = translations
        if (!trans) {
            trans = { culture: locale, name: '', settings: {} }
        } else {
            if (!trans.settings) {
                trans.settings = {}
            } else if (Array.isArray(trans.settings)) {
                trans.settings = {}
            }
        }

        if (textKey === 'informationtext') {
            trans.settings.informationtext = text
        } else if (textKey === 'pretext') {
            trans.settings.pretext = text
        }

        translations.push(trans)
        ddiItem.translations = translations
        this.setState({ item: ddiItem })
    }

    onChangeTranslation(locale, ddiName) {
        let ddiItem = this.state.item

        if (!ddiItem.translations || ddiItem.translations.length <= 0) {
            ddiItem.translations = []
        }

        let trans = ddiItem.translations.find((item) => item.culture === locale)
        let translations = ddiItem.translations.filter(
            (item) => item.culture !== locale
        )

        ddiItem.translations = translations
        if (!trans) {
            trans = { culture: locale, name: ddiName }
        } else {
            trans.name = ddiName
        }
        translations.push(trans)
        ddiItem.translations = translations

        this.setState({ item: ddiItem })
    }

    fetchFields() {
        let url =
            this.props.kedo.api().getDefDossierDefFieldEndpoint() +
            '?limit=500&defDossier=' +
            this.props.defDossier
        this.setState({ fetchingFields: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState({
                    dataFields: response.data.results,
                    fetchingFields: false,
                })
            )
    }

    fetchLinks() {
        let url =
            this.props.kedo.api().getDefDossierLinkEndpoint() +
            '?limit=500&defDossier=' +
            this.props.defDossier
        this.setState({ fetchingLinks: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState({
                    linkFields: response.data.results,
                    fetchingLinks: false,
                })
            )
    }

    fetchDisplayPositions() {
        let url =
            this.props.kedo.api().getDisplayPositionEndpoint() +
            '?limit=500&defDossier=' +
            this.props.defDossier
        this.setState({ fetchingPositions: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState({
                    displayPositions: response.data.results,
                    fetchingPositions: false,
                })
            )
    }

    fetchBaseFields() {
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 500,
            },
        }
        this.setState({ loadingBaseFields: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getDefFieldEndpoint(), params)
            .then((response) =>
                this.setState({
                    baseFields: response.data.results,
                    loadingBaseFields: false,
                })
            )
    }

    fetchRoles() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.get(api.getRoleEndpoint(), {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 200,
            },
        }).then((response) =>
            this.setState({
                loading: false,
                availableRoles: response.data.results,
            })
        )
    }

    fetchExistingItem() {
        const kedo = this.props.kedo
        const api = kedo.api()
        this.setState({ loading: true })
        api.get(api.getDisplayItemEndpoint(this.props.id)).then((response) => {
            let ddi = response.data

            if (ddi && ddi.def_dossier_def_field) {
                ddi.defDossierDefField = ddi.def_dossier_def_field
            }
            if (ddi && ddi.def_dossier_link) {
                ddi.defDossierLink = ddi.def_dossier_link
            }
            ddi.roles = ddi.roles.map((ddiRole) => ddiRole.role.id)

            this.setState({
                loading: false,
                item: ddi,
            })
        })
    }

    componentDidMount() {
        if (this.props.id) {
            this.fetchExistingItem()
        }

        this.fetchFields()
        if (!this.props.item || this.props.item.defDossierLink) {
            this.fetchLinks()
        }
        if (!this.props.item || this.props.item.defDossierDefField) {
            this.fetchBaseFields()
        }
        this.fetchDisplayPositions()
        this.fetchRoles()
    }

    isDataField() {
        return (
            (this.props.item && this.props.item.defDossierDefField) ||
            (this.state.item && this.state.item.defDossierDefField)
        )
    }

    isLinkField() {
        return (
            (this.props.item && this.props.item.defDossierLink) ||
            (this.state.item && this.state.item.defDossierLink)
        )
    }

    renderTranslations(translations) {
        if (!translations || translations.length <= 0) {
            translations = []
        }
        let locales = this.props.kedo.env().getEnvironmentLocales()

        if (!locales) {
            return this.props.kedo.t('No languages set')
        }

        return (
            <Accordion
                defaultActiveKey={
                    locales && locales.length > 0 ? locales[0].id : null
                }
                className={'language_accordion'}
            >
                {locales.map((locale) => {
                    let trans = translations.find(
                        (item) => locale.code === item.culture
                    )
                    if (!trans) {
                        trans = {}
                    }
                    return (
                        <Card key={locale.id}>
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey={locale.id}
                            >
                                <LocaleName
                                    kedo={this.props.kedo}
                                    localeCode={locale.code}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={locale.id}>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>
                                            {this.props.kedo.t('Name')}
                                        </Form.Label>
                                        <Form.Control
                                            value={trans.name}
                                            isInvalid={
                                                !!this.state.errors[locale]
                                            }
                                            type={'text'}
                                            onChange={(event) =>
                                                this.onChangeTranslation(
                                                    locale.code,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            {this.props.kedo.t(
                                                'Information text'
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            value={
                                                trans.settings &&
                                                trans.settings.informationtext
                                                    ? trans.settings
                                                          .informationtext
                                                    : null
                                            }
                                            as="textarea"
                                            rows="3"
                                            onChange={(event) =>
                                                this.onChangeTranslationSettings(
                                                    locale.code,
                                                    event.target.value,
                                                    'informationtext'
                                                )
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            {this.props.kedo.t('Pre text')}
                                        </Form.Label>
                                        <Form.Control
                                            value={
                                                trans.settings &&
                                                trans.settings.pretext
                                                    ? trans.settings.pretext
                                                    : null
                                            }
                                            as="textarea"
                                            rows="3"
                                            onChange={(event) =>
                                                this.onChangeTranslationSettings(
                                                    locale.code,
                                                    event.target.value,
                                                    'pretext'
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })}
            </Accordion>
        )
    }

    onChangeObjectField(fieldName, itemId) {
        let item = null
        if (fieldName === 'defDossierDefField') {
            item = this.state.dataFields.find(
                (dataItem) => dataItem.id === parseInt(itemId)
            )
        } else if (fieldName === 'displayPosition') {
            item = this.state.displayPositions.find(
                (dataItem) => dataItem.id === parseInt(itemId)
            )
        }

        if (!item) {
            return
        }

        let curItem = this.state.item
        curItem[fieldName] = item
        this.setState({ item: curItem })
    }

    renderDisplayPositions() {
        return (
            <Form.Group>
                <Form.Label>
                    {this.props.kedo.t('Location')}{' '}
                    {this.state.fetchingPositions ? <LoadingDefault /> : null}
                </Form.Label>
                <Form.Control
                    onChange={(event) =>
                        this.onChangeObjectField(
                            'displayPosition',
                            event.target.value
                        )
                    }
                    as="select"
                    disabled={
                        (this.props.item && this.props.item.display_position) ||
                        this.state.submitting
                    }
                    value={
                        this.state.item.display_position_id
                            ? this.state.item.display_position_id
                            : null
                    }
                    isInvalid={this.state.errors['displayPosition']}
                >
                    <option>{this.props.kedo.t('Choose an option')}</option>
                    {this.state.displayPositions.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name_plural}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        )
    }

    renderLinkFields() {
        if (this.isDataField() || (this.props.item && !this.isLinkField())) {
            return ''
        }

        return (
            <Form.Group>
                <Form.Label>
                    {this.props.kedo.t('Link field')}{' '}
                    {this.state.fetchingLinks ? <LoadingDefault /> : null}
                </Form.Label>
                <Form.Control
                    disabled
                    onChange={(event) =>
                        this.onChangeObjectField(
                            'defDossierDefField',
                            event.target.value
                        )
                    }
                    as="select"
                    value={
                        this.state.item.defDossierLink &&
                        this.state.item.defDossierLink.id
                            ? this.state.item.defDossierLink.id
                            : null
                    }
                    isInvalid={this.state.errors['defDossierLink']}
                >
                    <option value={''}>
                        {this.props.kedo.t('Choose an option')}
                    </option>
                    {this.state.linkFields.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        )
    }

    renderDataFields() {
        if (this.isLinkField() || (this.props.item && !this.isDataField())) {
            return ''
        }

        return (
            <Form.Group>
                <Form.Label>
                    {this.props.kedo.t('Data field')}{' '}
                    {this.state.fetchingFields ? <LoadingDefault /> : null}
                </Form.Label>
                <Form.Control
                    onChange={(event) =>
                        this.onChangeObjectField(
                            'defDossierDefField',
                            event.target.value
                        )
                    }
                    as="select"
                    disabled={
                        (this.props.item &&
                            this.props.item.defDossierDefField) ||
                        this.state.submitting
                    }
                    value={
                        this.state.item.defDossierDefField &&
                        this.state.item.defDossierDefField.id
                            ? this.state.item.defDossierDefField.id
                            : null
                    }
                    isInvalid={this.state.errors['defDossierDefField']}
                >
                    <option value={''}>
                        {this.props.kedo.t('Choose an option')}
                    </option>
                    {!this.props.type || this.props.type.includes('text') ? (
                        <optgroup label={this.props.kedo.t('Text')}>
                            {this.state.dataFields
                                .filter((item) => this.isTextType(item))
                                .map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </optgroup>
                    ) : null}
                    {!this.props.type || this.props.type.includes('date') ? (
                        <optgroup label={this.props.kedo.t('Date')}>
                            {this.state.dataFields
                                .filter(
                                    (item) => item.def_field.type === 'date'
                                )
                                .map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </optgroup>
                    ) : null}
                    {!this.props.type || this.props.type.includes('list') ? (
                        <optgroup label={this.props.kedo.t('List')}>
                            {this.state.dataFields
                                .filter(
                                    (item) => item.def_field.type === 'list'
                                )
                                .map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </optgroup>
                    ) : null}
                    {!this.props.type ||
                    this.state.dataFields.filter((item) =>
                        this.props.type.includes(item.type)
                    ).length > 0 ? (
                        <optgroup label={this.props.kedo.t('Other')}>
                            {this.state.dataFields
                                .filter(
                                    (item) =>
                                        !this.isTextType(item) &&
                                        item.type !== 'date' &&
                                        item.type !== 'list' &&
                                        item.type !== 'tree'
                                )
                                .map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </optgroup>
                    ) : null}
                </Form.Control>
                {this.renderErrorFeedback(
                    this.state.errors['defDossierDefField']
                )}
            </Form.Group>
        )
    }

    isTextType(item) {
        if (!item.def_field) {
            return false
        }
        if (item.def_field.type === 'url' || item.def_field.type === 'email') {
            return true
        } else if (
            item.def_field.type === 'text' ||
            item.def_field.type === 'textarea'
        ) {
            return true
        }
    }

    hasSufficientTabData(tabName) {
        if (tabName === 'field') {
            if (
                this.state.item.defDossierDefField ||
                this.state.item.defDossierLink
            ) {
                return ''
            }
        }

        if (tabName === 'labels') {
            let missingTranslations = false

            this.props.kedo
                .env()
                .getEnvironmentLocales()
                .map((locale) => {
                    if (!this.state.item.translations) {
                        missingTranslations = true
                    } else if (
                        !this.state.item.translations.find(
                            (translation) => translation.culture === locale.code
                        )
                    ) {
                        missingTranslations = true
                    } else if (
                        !this.state.item.translations.find(
                            (translation) => translation.culture === locale.code
                        ).name
                    ) {
                        missingTranslations = true
                    } else if (
                        this.state.item.translations.find(
                            (translation) => translation.culture === locale.code
                        ).name.length <= 0
                    ) {
                        missingTranslations = true
                    }
                })
        }

        if (tabName === 'layout') {
            let missingLayout = false

            if (
                this.state.item &&
                this.state.item.col >= 0 &&
                this.state.item.colspan >= 0 &&
                this.state.item.rank >= 0
            ) {
                return ''
            } else {
                return (
                    <FontAwesomeIcon
                        className={`validationIcon validationFailed`}
                        icon={faExclamationCircle}
                    />
                )
            }
        }

        return null
    }

    onChangeRole(e) {
        let item = this.state.item
        item.roles = item.roles.filter((roleId) => e.target.value != roleId)

        if (e.target.checked) {
            item.roles.push(parseInt(e.target.value))
        }

        this.setState({ item: item })
    }

    onChangeSettings(name, value) {
        let item = this.state.item
        if (!item.settings) {
            item.settings = {}
        }
        item.settings[name] = value
        this.setState({ item: item })
    }

    renderViewSpecificSettings(displayItem) {
        if (
            displayItem.view === 'search' &&
            displayItem.def_dossier_def_field &&
            displayItem.def_dossier_def_field.def_field
        ) {
            let fieldType = displayItem.def_dossier_def_field.def_field.type

            return (
                <div>
                    {fieldType === 'amount' || fieldType === 'calc' ? (
                        <Form.Group>
                            <Form.Check
                                checked={
                                    this.state.item &&
                                    this.state.item.settings &&
                                    this.state.item.settings.show_slider ===
                                        true
                                }
                                id={'radio-show-slider'}
                                name={'show_slider'}
                                label={this.props.kedo.t('Show slider')}
                                onChange={(e) =>
                                    this.onChangeSettings(
                                        'show_slider',
                                        e.target.checked
                                    )
                                }
                                type="checkbox"
                            />
                        </Form.Group>
                    ) : null}
                    {fieldType === 'amount' || fieldType === 'calc' ? (
                        <Form.Group>
                            <Form.Control
                                value={
                                    this.state.item &&
                                    this.state.item.settings &&
                                    this.state.item.settings.max_slider
                                        ? this.state.item.settings.max_slider
                                        : 1000
                                }
                                id={'radio-max-slider'}
                                name={'max_slider'}
                                label={this.props.kedo.t('Max slider')}
                                onChange={(e) =>
                                    this.onChangeSettings(
                                        'max_slider',
                                        e.target.value
                                    )
                                }
                            />
                        </Form.Group>
                    ) : null}
                </div>
            )
        }
    }

    renderFieldSpecificTabs(displayItem, tabHeader) {
        if (tabHeader) {
            return (
                <Nav.Item>
                    <Nav.Link eventKey="ddi-file-settings">
                        {this.props.kedo.t('Settings')}
                    </Nav.Link>
                </Nav.Item>
            )
        }

        return (
            <Tab.Pane eventKey="ddi-file-settings">
                <DisplayItemSettings
                    onChange={this.onChangeSettings}
                    item={displayItem}
                    kedo={this.props.kedo}
                />
                {this.renderViewSpecificSettings(displayItem)}
            </Tab.Pane>
        )
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal size={'lg'} show={true} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.id ||
                        (this.props.item && this.props.item.id)
                            ? kedo.t('Edit')
                            : kedo.t('Add')}{' '}
                        {kedo.t('Display field').toLowerCase()}
                        {this.props.id ? (
                            <small className={'text-muted'}>
                                {' '}
                                ({this.props.id})
                            </small>
                        ) : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    {this.state.errors['generic'] ? (
                        <Alert variant={'danger'}>
                            {this.state.errors['generic']}
                        </Alert>
                    ) : null}
                    {this.state.loading ? (
                        <LoadingDefault />
                    ) : (
                        <Tab.Container defaultActiveKey="labels">
                            <Row>
                                <Col sm={3} className={'pillsSidebar'}>
                                    <Nav
                                        variant="pills"
                                        className="flex-column"
                                    >
                                        <Nav.Item>
                                            <Nav.Link eventKey="labels">
                                                {kedo.t('Labels')}{' '}
                                                {this.hasSufficientTabData(
                                                    'labels'
                                                )}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="field">
                                                {kedo.t('Field')}{' '}
                                                {this.hasSufficientTabData(
                                                    'field'
                                                )}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="layout">
                                                {kedo.t('Layout')}{' '}
                                                {this.hasSufficientTabData(
                                                    'layout'
                                                )}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="permissions">
                                                {kedo.t('Permissions')}
                                            </Nav.Link>
                                        </Nav.Item>
                                        {this.renderFieldSpecificTabs(
                                            this.state.item,
                                            true
                                        )}
                                        {this.state.item &&
                                        this.state.item.id ? (
                                            <Nav.Item>
                                                <Nav.Link eventKey="info">
                                                    {kedo.t('Info')}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ) : null}
                                    </Nav>
                                </Col>
                                <Col sm={9} className={'pillsContent'}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="labels">
                                            <h3>{kedo.t('Labels')}</h3>
                                            {this.renderTranslations(
                                                this.state.item.translations
                                            )}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="field">
                                            <h3>{kedo.t('Field')}</h3>
                                            {this.renderDataFields()}
                                            {this.renderLinkFields()}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="layout">
                                            <h3>{kedo.t('Layout')}</h3>
                                            {this.state.item &&
                                            (this.state.item.view === 'edit' ||
                                                this.state.item.view === 'show')
                                                ? this.renderDisplayPositions()
                                                : null}
                                            <Form.Label>
                                                {kedo.t('View')}
                                            </Form.Label>
                                            <Form.Control
                                                name={'view'}
                                                as="select"
                                                onChange={this.handleChange}
                                                readOnly={this.state.submitting}
                                                value={this.state.item.view}
                                                isInvalid={
                                                    !!this.state.errors['view']
                                                }
                                            >
                                                <option value={'show/edit'}>
                                                    {kedo.t('Show')}/
                                                    {kedo.t('Edit')}
                                                </option>
                                                <option value={'edit'}>
                                                    {kedo.t('Edit')}
                                                </option>
                                                <option value={'show'}>
                                                    {kedo.t('Show')}
                                                </option>
                                                <option value={'list'}>
                                                    {kedo.t('List')}
                                                </option>
                                                <option value={'search'}>
                                                    {kedo.t('Search')}
                                                </option>
                                                <option
                                                    value={'advanced-search'}
                                                >
                                                    {kedo.t('Advanced search')}
                                                </option>
                                                <option value={'summary'}>
                                                    {kedo.t('Summary')}
                                                </option>
                                            </Form.Control>
                                            {this.renderErrorFeedback(
                                                this.state.errors['view']
                                            )}
                                            <hr />
                                            <Form.Label column={true}>
                                                {kedo.t('Rank')}
                                            </Form.Label>
                                            <Form.Control
                                                name={'rank'}
                                                value={this.state.item.rank}
                                                readOnly={
                                                    this.state.submitting ===
                                                    true
                                                }
                                                type={'number'}
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                                isInvalid={
                                                    this.state.errors['rank']
                                                }
                                            />
                                            {this.renderErrorFeedback(
                                                this.state.errors['rank']
                                            )}
                                            <Form.Label column={true}>
                                                {kedo.t('Column order')}
                                            </Form.Label>
                                            <Form.Control
                                                name={'col'}
                                                value={this.state.item.col}
                                                readOnly={
                                                    this.state.submitting ===
                                                    true
                                                }
                                                type={'number'}
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                                isInvalid={
                                                    this.state.errors['col']
                                                }
                                            />
                                            {this.renderErrorFeedback(
                                                this.state.errors['col']
                                            )}
                                            <Form.Label column={true}>
                                                {kedo.t('Width')}
                                            </Form.Label>
                                            <Form.Control
                                                name={'colspan'}
                                                value={this.state.item.colspan}
                                                readOnly={
                                                    this.state.submitting ===
                                                    true
                                                }
                                                type={'number'}
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                                isInvalid={
                                                    this.state.errors['colspan']
                                                }
                                            />
                                            {this.renderErrorFeedback(
                                                this.state.errors['colspan']
                                            )}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="permissions">
                                            <h3>{kedo.t('Permissions')}</h3>
                                            <Form.Group>
                                                <Form.Label>
                                                    {kedo.t('Roles')}
                                                </Form.Label>
                                                {this.state.availableRoles.map(
                                                    (item) => (
                                                        <Form.Check
                                                            key={item.id}
                                                            value={item.id}
                                                            checked={
                                                                this.state
                                                                    .item &&
                                                                this.state.item
                                                                    .roles &&
                                                                this.state.item.roles.includes(
                                                                    item.id
                                                                )
                                                            }
                                                            onChange={
                                                                this
                                                                    .onChangeRole
                                                            }
                                                            type={'checkbox'}
                                                            label={item.name}
                                                            id={`role-${item.id}`}
                                                        />
                                                    )
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control
                                                    readOnly={
                                                        this.state.submitting
                                                    }
                                                    value={
                                                        this.state.item
                                                            .permissions_type
                                                    }
                                                    onChange={this.handleChange}
                                                    name={'permissions_type'}
                                                    as={'select'}
                                                >
                                                    <option value={''}>
                                                        {kedo.t(
                                                            'Choose an option'
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            'EXCLUDE_SELECTED_ROLES'
                                                        }
                                                    >
                                                        {kedo.t(
                                                            'EXCLUDE_SELECTED_ROLES'
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            'INCLUDE_SELECTED_ROLES'
                                                        }
                                                    >
                                                        {kedo.t(
                                                            'INCLUDE_SELECTED_ROLES'
                                                        )}
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>
                                                    {kedo.t('Condition query')}
                                                </Form.Label>
                                                <SelectList
                                                    currentValue={
                                                        this.state.item
                                                            ?.settings
                                                            ?.condition_query
                                                    }
                                                    disableInit={true}
                                                    onClear={() =>
                                                        this.setState(
                                                            {
                                                                condition_query:
                                                                    null,
                                                            },
                                                            () =>
                                                                this.onChangeSettings(
                                                                    'condition_query',
                                                                    null
                                                                )
                                                        )
                                                    }
                                                    searchName={'search'}
                                                    kedo={this.props.kedo}
                                                    urlParams={{
                                                        defDossier:
                                                            this.state
                                                                .defDossier,
                                                    }}
                                                    onDisplayName={(item) => {
                                                        return (
                                                            item.id +
                                                            ': ' +
                                                            this.props.kedo
                                                                .env()
                                                                .translateItem(
                                                                    item,
                                                                    'dossierquery'
                                                                )
                                                        )
                                                    }}
                                                    url={this.props.kedo
                                                        .api()
                                                        .getDossierQueryEndpoint()}
                                                    onSelect={(item) => {
                                                        this.setState(
                                                            {
                                                                condition_query:
                                                                    item.id,
                                                            },
                                                            () =>
                                                                this.onChangeSettings(
                                                                    'condition_query',
                                                                    item.id
                                                                )
                                                        )
                                                    }}
                                                />
                                            </Form.Group>
                                        </Tab.Pane>
                                        {this.renderFieldSpecificTabs(
                                            this.state.item
                                        )}
                                        {this.state.item &&
                                        this.state.item.id ? (
                                            <Tab.Pane eventKey="info">
                                                <h3>{kedo.t('Info')}</h3>
                                                <ObjectCreatedInfo
                                                    kedo={kedo}
                                                    item={this.state.item}
                                                />
                                            </Tab.Pane>
                                        ) : null}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={
                            this.state.fetchingPositions ||
                            this.state.fetchingLinks ||
                            this.state.fetchingFields
                        }
                        onClick={this.submitDisplayItem}
                        variant="primary"
                    >
                        {this.state.submitting ? (
                            <LoadingDefault />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {this.props.kedo.t('Save')}
                    </Button>{' '}
                    &nbsp;
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                    >
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DisplayItemForm
