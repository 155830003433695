import React, { Component, useMemo } from 'react'
import {
    Button,
    ButtonGroup,
    Col,
    Row,
    Alert,
    Badge,
    DropdownButton,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'
import Loading from '../../Elements/Loading/LoadingContent'
import DossierModalEditButton from '../../Elements/DossierModalEditButton'
import { Gantt } from 'gantt-task-react'
// import styles from 'gantt-task-react';
import './gantt.css'
import SearchDefDossier from './SearchDefDossier'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFilter,
    faTimes,
    faAngleDown,
    faAngleUp,
} from '@fortawesome/free-solid-svg-icons'

const localeDateStringCache = {}
const toLocaleDateStringFactory = (locale) => (date, dateTimeOptions) => {
    const key = date.toString()
    let lds = localeDateStringCache[key]
    if (!lds) {
        lds = date.toLocaleDateString(locale, dateTimeOptions)
        localeDateStringCache[key] = lds
    }
    return lds
}
const dateTimeOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
}

const getMyColor = (dossierId) => {
    let n = (0xfffff * 1000000 + dossierId).toString(16)
    return '#' + n.slice(0, 6)
}

const TaskListHeader = ({ headerHeight, rowWidth, fontFamily, fontSize }) => {
    let styles = {
        ganttTable: '_3_ygE',
        ganttTable_Header: '_1nBOt',
        ganttTable_HeaderItem: '_WuQ0f',
        ganttTable_HeaderSeparator: '_2eZzQ',
    }

    return (
        <div
            className={styles.ganttTable}
            style={{
                fontFamily: fontFamily,
                fontSize: fontSize,
            }}
        >
            <div
                className={styles.ganttTable_Header}
                style={{
                    height: headerHeight - 2,
                }}
            >
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;Name
                </div>
                <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                        height: headerHeight * 0.5,
                        marginTop: headerHeight * 0.2,
                    }}
                />
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;From
                </div>
                <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                        height: headerHeight * 0.5,
                        marginTop: headerHeight * 0.25,
                    }}
                />
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;To
                </div>
                <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                        height: headerHeight * 0.5,
                        marginTop: headerHeight * 0.25,
                    }}
                />
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;Resource
                </div>
            </div>
        </div>
    )
}

const TaskListTable = ({
    rowHeight,
    rowWidth,
    tasks,
    fontFamily,
    fontSize,
    locale,
    onExpanderClick,
}) => {
    const toLocaleDateString = useMemo(
        () => toLocaleDateStringFactory(locale),
        [locale]
    )

    let styles = {
        taskListWrapper: '_3ZbQT',
        taskListTableRow: '_34SS0',
        taskListCell: '_3lLk3',
        taskListNameWrapper: '_nI1Xw',
        taskListExpander: '_2QjE6',
        taskListEmptyExpander: '_2TfEi',
    }

    return (
        <div
            className={styles.taskListWrapper}
            style={{
                fontFamily: fontFamily,
                fontSize: fontSize,
            }}
        >
            {tasks.map((t) => {
                let expanderSymbol = ''
                if (t.hideChildren === false) {
                    expanderSymbol = '▼'
                } else if (t.hideChildren === true) {
                    expanderSymbol = '▶'
                }

                return (
                    <div
                        className={styles.taskListTableRow}
                        style={{ height: rowHeight }}
                        key={`${t.id}row`}
                    >
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                            title={t.name}
                        >
                            <div className={styles.taskListNameWrapper}>
                                <div
                                    className={
                                        expanderSymbol
                                            ? styles.taskListExpander
                                            : styles.taskListEmptyExpander
                                    }
                                    onClick={() => onExpanderClick(t)}
                                >
                                    {expanderSymbol}
                                </div>
                                <div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{t.name}</Tooltip>}
                                    >
                                        <span title="">
                                            {t.name.length > 15
                                                ? `${t.name.slice(0, 15)}...`
                                                : t.name}
                                        </span>
                                    </OverlayTrigger>
                                    {t.tags
                                        ? [
                                              ...new Map(
                                                  t.tags.map((item) => [
                                                      item['id'],
                                                      item,
                                                  ])
                                              ).values(),
                                          ].map((tagItem, tIndex) => (
                                              <Badge
                                                  key={tIndex}
                                                  pill
                                                  style={{
                                                      backgroundColor:
                                                          tagItem.color,
                                                  }}
                                                  variant={'light'}
                                              >
                                                  {tagItem.name}
                                              </Badge>
                                          ))
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                        >
                            &nbsp;{toLocaleDateString(t.start, dateTimeOptions)}
                        </div>
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                        >
                            &nbsp; {toLocaleDateString(t.end, dateTimeOptions)}
                        </div>
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                        >
                            {t.type !== 'project'
                                ? Array.isArray(t.resource)
                                    ? t.resource.map((item) => {
                                          return (
                                              <>
                                                  {item.text}
                                                  <br></br>
                                              </>
                                          )
                                      })
                                    : t.resource
                                : null}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

class DefDossierGanttChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayItem: null,
            dossierQuery: null,
            viewMode: 'Month',
            search: null,
            task_dd_id: null,
            gantt_task_back_link: null,
            pager: null,
            showFilter: false,
            searchItems: [],
            searchItemsLoaded: false,
            knownDdls: [],
            hiddenColumns: [],
            loading: false,
            loadedContent: false,
            contentLoading: false,
            loadingProjTags: false,
            loadingTaskTags: false,
            defDossier: null,
            collapse: false,
            items: [],
            tasks: [],
            customItems: [],
            refs: [],
            tags: [],
            uniqueTags: [],
            resources: [],
        }
        this.getProjectSubTasks = this.getProjectSubTasks.bind(this)
        this.onExpanderClick = this.onExpanderClick.bind(this)
        this.onDblClick = this.onDblClick.bind(this)
        this.attachTags = this.attachTags.bind(this)
        this.fetchSearchFields = this.fetchSearchFields.bind(this)
        this.doSearch = this.doSearch.bind(this)
        this.onTaskChange = this.onTaskChange.bind(this)
        this.renderTaskOnChange = this.renderTaskOnChange.bind(this)
    }

    fetchSearchFields(ddId) {
        let api = this.props.kedo.api()
        let params = {
            params: {
                defDossier: ddId,
                view: ['search', 'advanced-search'],
            },
        }
        api.get(api.getDisplayItemEndpoint(), params).then((response) => {
            this.setState({
                searchItemsLoaded: true,
                searchItems: response.data.results,
            })
        })
    }

    fetchDefDossier() {
        let api = this.props.kedo.api()
        let params = {
            params: {
                defDossier: this.props.defDossierId,
                limit: 1,
            },
        }

        this.setState({ loading: true })
        api.get(
            api.getDefDossierEndpoint(this.props.defDossierId),
            params
        ).then((response) => {
            this.setState(
                {
                    defDossier: response.data,
                    loading: false,
                    gantt_task_link: response.data.settings
                        ? response.data.settings.gantt_task_link
                        : null,
                    gantt_proj_start_date: response.data.settings
                        ? response.data.settings.gantt_proj_start_date
                        : null,
                    gantt_proj_end_date: response.data.settings
                        ? response.data.settings.gantt_proj_end_date
                        : null,
                    gantt_proj_progress:
                        response.data.settings &&
                        response.data.settings.gantt_proj_progress
                            ? response.data.settings.gantt_proj_progress
                            : null,
                    gantt_task_progress:
                        response.data.settings &&
                        response.data.settings.gantt_task_progress
                            ? response.data.settings.gantt_task_progress
                            : null,
                    gantt_task_update_start_date: response.data.settings
                        ? response.data.settings.gantt_task_update_start_date
                        : null,
                    gantt_task_update_end_date: response.data.settings
                        ? response.data.settings.gantt_task_update_end_date
                        : null,
                    gantt_proj_update_start_date: response.data.settings
                        ? response.data.settings.gantt_proj_update_start_date
                        : null,
                    gantt_proj_update_end_date: response.data.settings
                        ? response.data.settings.gantt_proj_update_end_date
                        : null,
                    gantt_proj_tags: response.data.settings
                        ? response.data.settings.gantt_proj_tags
                        : null,
                    gantt_task_tags: response.data.settings
                        ? response.data.settings.gantt_task_tags
                        : null,
                    gantt_task_resource: response.data.settings
                        ? response.data.settings.gantt_task_resource
                        : null,
                },
                () => {
                    this.fetchSearchFields(this.props.defDossierId)
                    this.fetchContent()
                    api.get(
                        api.getDefDossierLinkEndpoint(
                            response.data.settings.gantt_task_link
                        )
                    ).then((ddLinkResponse) => {
                        this.setState({
                            loading: false,
                            task_dd_id:
                                ddLinkResponse.data.child_def_dossier_id,
                            gantt_task_back_link:
                                ddLinkResponse.data.back_link_id,
                        })
                    })
                }
            )
        })
    }

    getContentByView(content, displayItems, viewName) {
        //filter the display items
        let returnContent = {}

        let filterItems = displayItems.filter(
            (displayItem) => displayItem.view === viewName
        )

        if (filterItems.length >= 0) {
            filterItems.map((displayItem, index) => {
                content[index]
                    ? (returnContent[displayItem.id] =
                          content[index].content.content)
                    : null
            })
        }

        return returnContent
    }

    stringToColour(str) {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        var colour = '#'
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xff
            colour += ('00' + value.toString(16)).substr(-2)
        }
        return colour
    }

    getProjectOrTaskTag = async (task, path, foundDossierId) => {
        const api = this.props.kedo.api()

        this.setState({ loadingContent: true })

        if (path.ddl) {
            //Found a link.
            let ddl = null
            if (
                this.state.knownDdls.find(
                    (knownDdl) => knownDdl.id === path.ddl
                )
            ) {
                ddl = this.state.knownDdls.find(
                    (knownDdl) => knownDdl.id === path.ddl
                )
            } else {
                const ddlResponse = await api.getAsync(
                    api.getDefDossierLinkEndpoint(path.ddl)
                )
                let ddls = this.state.knownDdls
                ddls.push(ddlResponse.data)
                this.setState({ knownDdls: ddls })
                ddl = ddlResponse.data
            }

            let params = {
                params: {
                    defDossier: ddl.child_def_dossier_id,
                    limit: 100,
                    embedded: foundDossierId,
                    linkId: ddl.back_link_id,
                },
            }
            const dossierResults = await api.getAsync('/dossier', params)

            const results = await dossierResults.data.results.dossiers.map(
                async (dossierItem) => {
                    await this.getProjectOrTaskTag(
                        task,
                        path.fields[0],
                        dossierItem.id
                    )
                }
            )
        } else {
            //Found the last field.
            let tags = this.state.tags
            const dossierResult = await api.getAsync(
                api.getContentDossierEndpoint(foundDossierId)
            )
            let ddiItems = dossierResult.data.items.filter(
                (ddiItem) =>
                    ddiItem.def_dossier_def_field &&
                    ddiItem.def_dossier_def_field.id === path.dddf &&
                    (ddiItem.def_dossier_def_field.def_field.type === 'user' ||
                        ddiItem.def_dossier_def_field.def_field.type === 'list')
            )

            if (
                ddiItems &&
                ddiItems[0] &&
                ddiItems[0].id &&
                dossierResult.data.content[ddiItems[0].id]
            ) {
                for (
                    let c = 0;
                    c < dossierResult.data.content[ddiItems[0].id].length;
                    c++
                ) {
                    tags.push({
                        task: task.id,
                        color: this.stringToColour(
                            dossierResult.data.content[ddiItems[0].id][c].text
                        ),
                        name: dossierResult.data.content[ddiItems[0].id][c]
                            .text,
                        id: dossierResult.data.content[ddiItems[0].id][c].id,
                    })
                }
            } else {
                tags.push({
                    task: task.id,
                    color: getMyColor(foundDossierId),
                    name:
                        dossierResult.data && dossierResult.data.dossier
                            ? dossierResult.data.dossier.summary
                            : foundDossierId,
                    id: foundDossierId,
                })
            }

            this.setState({
                tags: tags,
                loadingContent: false,
                uniqueTags: [
                    ...new Map(tags.map((item) => [item['id'], item])).values(),
                ],
            })
        }
    }

    attachTags() {
        this.setState({ loadingContent: true })
        let items = this.state.items
        for (let i = 0; i < items.length; i++) {
            let tags = this.state.tags.filter(
                (tagItem) => tagItem.task === items[i].id
            )
            items[i].tags = tags
        }

        this.setState({ items: items, loadingContent: false })
    }

    getTaskTags = async (taskItems) => {
        if (
            !this.state.gantt_task_tags ||
            !taskItems ||
            taskItems.length <= 0
        ) {
            return
        }
        let path = JSON.parse(this.state.gantt_task_tags)
        this.setState({ loadingTaskTags: true }, this.attachTags)
        for (let i = 0; i < taskItems.length; i++) {
            if (taskItems[i].type === 'task') {
                await this.getProjectOrTaskTag(
                    taskItems[i],
                    path,
                    taskItems[i].id
                )
            }
        }
        this.setState({ loadingTaskTags: false }, this.attachTags)
    }

    getDefDossierFromId(defDossierId) {
        return this.props.kedo
            .env()
            .getCurrentEnvironmentDefDossiers()
            .find((item) => item.id === parseInt(defDossierId))
    }

    getProjectTags = async () => {
        let path = JSON.parse(this.state.gantt_proj_tags)
        this.setState({ loadingProjTags: true })
        for (let i = 0; i < this.state.items.length; i++) {
            await this.getProjectOrTaskTag(
                this.state.items[i],
                path,
                this.state.items[i].id
            )
        }
        this.setState({ loadingProjTags: false }, this.attachTags)
    }

    async getProjectSubTasks(task, loading = true) {
        let items = this.state.items
        let project = items.find((item) => item.id === task.id)

        if (project.hideChildren) {
            let params = {
                params: {
                    defDossier: this.state.task_dd_id,
                    limit: 100,
                    embedded: task.id,
                    linkId: this.state.gantt_task_back_link,
                    view: 'Gantt',
                },
            }

            if (loading) {
                this.setState({ loading: true })
            }

            await this.props.kedo
                .api()
                .getAsync('/dossier', params)
                .then((response) => {
                    let newItems = []

                    response.data.results.dossiers.forEach((dossier) => {
                        let start = response.data.results.contents
                            .filter((contents) => contents.id === dossier.id)
                            .find(
                                (contentItems) =>
                                    contentItems.content.id ===
                                    parseInt(
                                        this.state.gantt_task_update_start_date
                                    )
                            )
                        let end = response.data.results.contents
                            .filter((contents) => contents.id === dossier.id)
                            .find(
                                (contentItems) =>
                                    contentItems.content.id ===
                                    parseInt(
                                        this.state.gantt_task_update_end_date
                                    )
                            )
                        let progress = response.data.results.contents
                            .filter((contents) => contents.id === dossier.id)
                            .find(
                                (contentItems) =>
                                    contentItems.content.id ===
                                    parseInt(this.state.gantt_task_progress)
                            )
                        let resource = response.data.results.contents
                            .filter((contents) => contents.id === dossier.id)
                            .find(
                                (contentItems) =>
                                    contentItems.content.id ===
                                    parseInt(this.state.gantt_task_resource)
                            )

                        if (resource.content.content.length > 0) {
                            let resources = this.state.resources

                            resource.content.content.forEach((items) => {
                                if (
                                    this.state.resources.filter(
                                        (item) => item.id === items.id
                                    ).length < 1
                                ) {
                                    resources.push(items)
                                }
                            })
                        }

                        newItems.push({
                            id: dossier.id,
                            project: task.id,
                            dependencies: [task.id],
                            key: dossier.id,
                            dossier: dossier,
                            name: dossier.summary,
                            isDisabled: false,
                            resource: resource
                                ? resource.content.content
                                : null,
                            progress:
                                progress && !isNaN(progress.content.content)
                                    ? parseInt(progress.content.content) < 100
                                        ? parseInt(progress.content.content)
                                        : 100
                                    : 0,
                            type: 'task',
                            start:
                                start && start.content.content
                                    ? new Date(start.content.content)
                                    : task.start,
                            end:
                                end && end.content.content
                                    ? new Date(end.content.content)
                                    : task.end,
                            styles: {
                                progressColor:
                                    progress &&
                                    !isNaN(progress.content.content) &&
                                    parseInt(progress.content.content) > 100
                                        ? 'red'
                                        : 'rgb(130, 130, 245)',
                            },
                        })

                        for (let i = 0; i < items.length; i++) {
                            if (items[i].id === dossier.id) {
                                items.splice(i, 1)
                            }
                        }
                    })

                    let foundIndex = 0

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].project === task.id) {
                            items.splice(i, 1)
                        }
                    }

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === task.id) {
                            items[i].hideChildren = false
                            foundIndex = i
                        }
                    }

                    newItems.map((newItem) =>
                        items.splice(foundIndex + 1, 0, newItem)
                    )

                    this.setState({ items: items }, async () => {
                        if (loading) {
                            this.setState({ loading: false })
                        }
                        await this.getTaskTags(newItems)
                        this.attachTags()
                    })
                })
        } else {
            let tagsRemoved = items.filter((item) => item.project !== task.id)

            for (let i = 0; i < tagsRemoved.length; i++) {
                if (tagsRemoved[i].id === task.id) {
                    tagsRemoved[i].hideChildren = true
                }
            }

            this.setState({ items: tagsRemoved, loading: true }, () => {
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 150)
            })
        }
    }

    doSearch(searchParams, dossierQuery) {
        this.setState(
            {
                search: searchParams,
                dossierQuery: dossierQuery,
                showFilter: false,
            },
            () => {
                this.setState(
                    {
                        searchParams: searchParams,
                        dossierQuery: dossierQuery,
                    },
                    this.fetchContent
                )
            }
        )
    }

    async fetchContent() {
        let api = this.props.kedo.api()

        let params = { defDossier: this.props.defDossierId }

        if (this.state.searchParams && this.state.searchParams.length > 0) {
            this.state.searchParams.map((searchItem) => {
                if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'date'
                ) {
                    params[searchItem.id + '_type'] = searchItem.value.type
                    if (searchItem.value.type === 'between') {
                        params[searchItem.id + '_end'] = searchItem.value.date2
                    }
                    params[searchItem.id] = searchItem.value.date1
                } else if (typeof searchItem.value === 'string') {
                    params[searchItem.id] = searchItem.value
                } else if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'slider'
                ) {
                    params[searchItem.id + '_min'] = searchItem.value.min
                    params[searchItem.id + '_max'] = searchItem.value.max
                    params[searchItem.id] = '1'
                } else if (searchItem.value instanceof Array) {
                    let values = []
                    searchItem.value.map((valItem) => values.push(valItem.id))
                    params[searchItem.id] = values
                }
            })
        }

        if (this.state.dossierQuery) {
            params.dossierQuery = this.state.dossierQuery
        }

        this.setState({ loadingContent: true })

        api.get(api.getContentDossierEndpoint(), { params: params }).then(
            (response) => {
                let items = []
                let errorItems = []

                response.data.results.dossiers.map((dossier) => {
                    let contents = response.data.results.contents.filter(
                        (item) => item.id === dossier.id
                    )
                    let startValue = contents.find(
                        (item) =>
                            item.content.id ===
                            parseInt(this.state.gantt_proj_start_date)
                    )
                    let endValue = contents.find(
                        (item) =>
                            item.content.id ===
                            parseInt(this.state.gantt_proj_end_date)
                    )
                    let progress = contents.find(
                        (item) =>
                            item.content.id ===
                            parseInt(this.state.gantt_proj_progress)
                    )

                    if (
                        startValue &&
                        startValue.content &&
                        startValue.content.content &&
                        endValue &&
                        endValue.content &&
                        endValue.content.content
                    ) {
                        items.push({
                            id: dossier.id,
                            key: dossier.id,
                            dossier: dossier,
                            name: dossier.summary,
                            isDisabled: false,
                            hideChildren: true,
                            progress:
                                progress && !isNaN(progress.content.content)
                                    ? parseInt(progress.content.content) < 100
                                        ? parseInt(progress.content.content)
                                        : 100
                                    : 0,
                            type: 'project',
                            start:
                                startValue &&
                                startValue.content &&
                                startValue.content.content
                                    ? new Date(startValue.content.content)
                                    : new Date(),
                            end:
                                endValue &&
                                endValue.content &&
                                endValue.content.content
                                    ? new Date(endValue.content.content)
                                    : new Date(),
                            styles: {
                                progressColor:
                                    progress &&
                                    !isNaN(progress.content.content) &&
                                    parseInt(progress.content.content) > 100
                                        ? 'red'
                                        : 'rgb(130, 130, 245)',
                            },
                        })
                    } else {
                        errorItems.push({
                            id: dossier.id,
                            dossier: dossier,
                            name: dossier.summary,
                        })
                    }
                })

                this.setState(
                    {
                        loadingContent: false,
                        loadedContent: true,
                        pager: response.data.pager,
                        items: items,
                        errorItems: errorItems,
                    },
                    async () => {
                        await this.getProjectTags()
                        await this.getTaskTags()
                    }
                )
            }
        )
    }
    componentDidMount() {
        this.fetchDefDossier()
    }

    onExpanderClick(task) {
        this.getProjectSubTasks(task)
    }

    onDblClick(task) {
        this.setState({ edit: task.id })
    }

    onProgressChange() {
        //TODO
    }

    onTaskDelete() {
        //TODO
    }

    renderTaskOnChange() {
        let kedo = this.props.kedo

        return (
            <Modal
                size={'lg'}
                show={true}
                backdrop="static"
                centered
                onHide={() => this.setState({ viewModal: null })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{kedo.t('Edit project date')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'container text-center'}>
                        <p className={'p-4'}>
                            {kedo.t('gantt_edit_project_dates')}
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => this.setState({ doUpdateProject: true })}
                    >
                        {kedo.t('Continue')}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => this.setState({ viewModal: null })}
                    >
                        {this.props.kedo.t('No')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    async checkProjectUpdate(callback = null) {
        return new Promise((resolve, reject) => {
            let interval = setInterval(() => {
                if (!this.state.viewModal) {
                    clearInterval(interval)
                    reject(false)
                }

                if (this.state.doUpdateProject) {
                    clearInterval(interval)

                    if (callback) {
                        callback()
                    }

                    resolve(true)
                }
            }, 100)
        })
    }

    async onTaskChange(task) {
        let project = this.state.items.find(
            (project) =>
                project.type === 'project' && project.id === task.project
        )

        if (task.start < project.start || task.end > project.end) {
            if (
                this.props.kedo
                    .env()
                    .isAllowedDefDossier(
                        'edit',
                        project.id,
                        this.props.kedo.user()
                    )
            ) {
                this.setState({ viewModal: true })

                let bool = await this.checkProjectUpdate(() =>
                    this.setState({ viewModal: null, doUpdateProject: null })
                )

                if (bool) {
                    this.updateTaskDate(task, task.start, task.end)
                    this.updateProjectDate(
                        project,
                        task.start < project.start ? task.start : project.start,
                        task.end > project.end ? task.end : project.end
                    )
                }

                return bool
            }

            return false
        } else {
            this.updateTaskDate(task, task.start, task.end)
            return true
        }
    }

    updateTaskDate(contentdossier, start, end) {
        const kedo = this.props.kedo

        let values = {
            [this.state.gantt_task_update_start_date]: start,
            [this.state.gantt_task_update_end_date]: end,
        }

        kedo.api()
            .patch(
                kedo.api().getContentDossierEndpoint(contentdossier.id) +
                    '?displayCustomView=Gantt',
                values
            )
            .then((pResponse) => {
                kedo.api()
                    .get(
                        kedo.api().getContentDossierEndpoint(contentdossier.id)
                    )
                    .then((gResponse) => {
                        let current = this.state.items.find(
                            (item) => item.id === contentdossier.id
                        )
                        current.start = start
                        current.end = end
                        current.name =
                            gResponse && gResponse.data.dossier.summary
                                ? gResponse.data.dossier.summary
                                : current.name
                    })
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    alert(
                        'U heeft onvoldoende rechten om deze actie uit te voeren op taken.'
                    )
                }
            })
    }

    updateProjectDate(contentdossier, start, end) {
        const kedo = this.props.kedo

        let values = {
            [this.state.gantt_proj_update_start_date]: start,
            [this.state.gantt_proj_update_end_date]: end,
        }

        kedo.api()
            .patch(
                kedo.api().getContentDossierEndpoint(contentdossier.id) +
                    '?displayCustomView=Gantt',
                values
            )
            .then((pResponse) => {
                this.fetchDefDossier()
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    alert(
                        'U heeft onvoldoende rechten om deze actie uit te voeren op projecten.'
                    )
                }
            })
    }

    render() {
        const kedo = this.props.kedo
        if (
            this.state.loading ||
            this.state.contentLoading ||
            this.state.loadingProjTags ||
            this.state.loadingTaskTags
        ) {
            return <Loading />
        }

        return (
            <>
                {this.state.viewModal ? this.renderTaskOnChange() : null}
                <Row>
                    <Col md={12} className={'mb-2'}>
                        {this.state.edit ? (
                            <DossierModalEditButton
                                onSuccess={() => this.setState({ edit: null })}
                                onClose={() => this.setState({ edit: null })}
                                key={this.state.edit}
                                edit={true}
                                dossierId={this.state.edit}
                                kedo={this.props.kedo}
                            />
                        ) : null}

                        <div className={'float-right'}>
                            {this.state.loadingProjTags ||
                            this.state.loadingTaskTags ? (
                                <LoadingDefault />
                            ) : (
                                this.state.uniqueTags.map((tagItem, tIndex) => (
                                    <Badge
                                        key={tIndex}
                                        onClick={() => {
                                            if (
                                                this.state.tagFilter ===
                                                tagItem.id
                                            ) {
                                                this.setState({
                                                    tagFilter: null,
                                                })
                                            } else {
                                                this.setState({
                                                    tagFilter: tagItem.id,
                                                })
                                            }
                                        }}
                                        pill
                                        style={{
                                            backgroundColor: tagItem.color,
                                        }}
                                        variant="light"
                                    >
                                        {tagItem.name}{' '}
                                        {this.state.tagFilter === tagItem.id ? (
                                            <FontAwesomeIcon icon={faTimes} />
                                        ) : null}
                                    </Badge>
                                ))
                            )}
                            <ButtonGroup
                                className="ml-2"
                                aria-label="Show filter"
                            >
                                <DropdownButton
                                    as={ButtonGroup}
                                    title={kedo.t(this.state.viewMode)}
                                    id="bg-nested-dropdown"
                                >
                                    <Dropdown.Item
                                        onSelect={() =>
                                            this.setState({ viewMode: 'Day' })
                                        }
                                        eventKey="1"
                                    >
                                        {kedo.t('Day')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onSelect={() =>
                                            this.setState({ viewMode: 'Week' })
                                        }
                                        eventKey="1"
                                    >
                                        {kedo.t('Week')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onSelect={() =>
                                            this.setState({ viewMode: 'Month' })
                                        }
                                        eventKey="2"
                                    >
                                        {kedo.t('Month')}
                                    </Dropdown.Item>
                                </DropdownButton>
                                {this.state.searchLoading ? (
                                    <Button className={'ml-2'} size={'sm'}>
                                        <LoadingDefault />
                                    </Button>
                                ) : (
                                    <Button
                                        className={'ml-2'}
                                        active={this.state.showFilter}
                                        onClick={() =>
                                            this.setState({
                                                showFilter:
                                                    !this.state.showFilter,
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon icon={faFilter} />
                                    </Button>
                                )}
                            </ButtonGroup>
                        </div>

                        <ButtonGroup
                            className="mr-2"
                            aria-label="Hidden columns"
                        >
                            {this.state.resources.map((resources, rIndex) => (
                                <Button
                                    key={rIndex}
                                    className={'mr-1'}
                                    size={'sm'}
                                    onClick={() => {
                                        let filtered = this.state.items.filter(
                                            (items) =>
                                                Array.isArray(items.resource)
                                                    ? items.resource.find(
                                                          (resource) =>
                                                              resource.text ===
                                                              resources.text
                                                      )
                                                    : items.resource ===
                                                      resources.text
                                        )

                                        this.setState({ filtered: filtered })
                                    }}
                                >
                                    <Badge>{resources.text}</Badge>
                                </Button>
                            ))}
                            {this.state.resources.length > 0 ? (
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            resources: [],
                                            filtered: null,
                                            collapse: false,
                                        })
                                        this.fetchDefDossier()
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            ) : null}
                            <Button
                                onClick={() => {
                                    this.setState(
                                        { collapse: !this.state.collapse },
                                        async () => {
                                            if (this.state.collapse) {
                                                this.setState({ loading: true })

                                                await this.state.items.map(
                                                    (items) => {
                                                        if (
                                                            items.type ===
                                                            'project'
                                                        ) {
                                                            this.getProjectSubTasks(
                                                                items,
                                                                false
                                                            )
                                                        }
                                                    }
                                                )

                                                await setTimeout(() => {
                                                    this.setState({
                                                        loading: false,
                                                    })
                                                }, 500)
                                            } else {
                                                let items = this.state.items

                                                this.setState({ loading: true })

                                                for (
                                                    let i = 0;
                                                    i < items.length;
                                                    i++
                                                ) {
                                                    if (
                                                        items[i].type ===
                                                        'project'
                                                    ) {
                                                        items[
                                                            i
                                                        ].hideChildren = true
                                                    }
                                                }

                                                this.setState({ items: items })

                                                await setTimeout(() => {
                                                    this.setState({
                                                        loading: false,
                                                    })
                                                }, 250)
                                            }
                                        }
                                    )
                                }}
                            >
                                {this.state.collapse ? (
                                    <FontAwesomeIcon icon={faAngleUp} />
                                ) : (
                                    <FontAwesomeIcon icon={faAngleDown} />
                                )}
                            </Button>
                        </ButtonGroup>
                    </Col>
                    {this.state.showFilter &&
                    this.state.searchItemsLoaded === true ? (
                        <Col md={12}>
                            <SearchDefDossier
                                defDossierId={this.props.defDossierId}
                                search={this.state.search}
                                dossierQuery={this.state.dossierQuery}
                                defDossier={this.getDefDossierFromId(
                                    this.props.defDossierId
                                )}
                                doSearch={this.doSearch}
                                kedo={this.props.kedo}
                                resetSearch={() =>
                                    this.setState(
                                        { dossierQuery: null, search: null },
                                        this.fetchContent
                                    )
                                }
                                items={this.state.searchItems}
                            />
                        </Col>
                    ) : null}
                    {(this.props.kedo.isAdminOrEnvironmentAdmin() ||
                        this.props.kedo.isAdminOrContractAdmin()) &&
                    this.state.errorItems &&
                    this.state.errorItems.length > 0 ? (
                        <Col md={12}>
                            <Alert variant="warning">
                                {this.props.kedo.t(
                                    'The following projects do not have a start and/or end date'
                                )}
                                ,<br></br>
                                {this.state.errorItems.map((item) => (
                                    <>
                                        <br></br>
                                        <b>{item.name}</b>
                                    </>
                                ))}
                            </Alert>
                        </Col>
                    ) : null}
                    <Col md={12}>
                        {this.state.searchItemsLoaded === true &&
                        this.state.loadedContent === false ? (
                            <Alert variant={'info'}>
                                {kedo.t('First enter some search parameters')}
                            </Alert>
                        ) : null}
                        {this.state.items &&
                        this.state.items.length > 0 &&
                        (!this.state.tagFilter ||
                            this.state.items.filter(
                                (item) =>
                                    item.tags &&
                                    item.tags.find(
                                        (tagItem) =>
                                            tagItem.id === this.state.tagFilter
                                    )
                            ).length > 0) ? (
                            <Gantt
                                TaskListTable={TaskListTable}
                                TaskListHeader={TaskListHeader}
                                tasks={
                                    this.state.tagFilter
                                        ? this.state.filtered
                                            ? this.state.filtered.filter(
                                                  (item) =>
                                                      item.tags &&
                                                      item.tags.find(
                                                          (tagItem) =>
                                                              tagItem.id ===
                                                              this.state
                                                                  .tagFilter
                                                      )
                                              )
                                            : this.state.items.filter(
                                                  (item) =>
                                                      item.tags &&
                                                      item.tags.find(
                                                          (tagItem) =>
                                                              tagItem.id ===
                                                              this.state
                                                                  .tagFilter
                                                      )
                                              )
                                        : this.state.filtered &&
                                          this.state.filtered.length > 0
                                        ? this.state.filtered
                                        : this.state.items
                                }
                                viewMode={this.state.viewMode}
                                onDateChange={this.onTaskChange}
                                onExpanderClick={this.onExpanderClick}
                                onTaskDelete={this.onTaskDelete}
                                onProgressChange={this.onProgressChange}
                                onDoubleClick={this.onDblClick}
                            />
                        ) : this.state.loadedContent === true ? (
                            <Alert variant={'info'}>
                                {kedo.t('No items found.')}
                            </Alert>
                        ) : null}
                    </Col>
                </Row>
            </>
        )
    }
}

export default DefDossierGanttChart
