import React, { Component } from 'react'
import { Button, ButtonGroup, Form, Modal, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'

class BrpModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            id: null,
            saving: false,
            showModal: false,
            querystring: this.props.querystring ? this.props.querystring : null,
            results: [],
        }
        this.doLookup = this.doLookup.bind(this)
    }

    doLookup() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        let data = {
            environment_id: this.props.kedo.env().getEnvironmentId(),
            querystring: this.state.querystring,
        }

        api.post('/module/brp/lookup', data)
            .then((response) => {
                this.setState({
                    loading: false,
                    results: response.data.results,
                })
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        if (this.props.querystring) {
            this.doLookup()
        }
    }

    renderResults(items) {
        if (!items || items.length <= 0) {
            return ''
        }
        return (
            <Table striped hover>
                <thead>
                    <tr>
                        <th>Voornamen</th>
                        <th>Tussenvoegsels</th>
                        <th>Achternaam</th>
                        <th>BSN</th>
                        <th>Geboortedatum</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.voornamen}</td>
                            <td>{item.voorvoegsels}</td>
                            <td>{item.geslachtsnaam}</td>
                            <td>{item.bsn}</td>
                            <td>{item.geboortedatum}</td>
                            <td>
                                <Button
                                    onClick={() => this.props.enrichData(item)}
                                >
                                    Gebruik
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal
                size={'lg'}
                scrollable={true}
                show={true}
                onHide={this.props.onClose}
            >
                <Modal.Header
                    closeButton
                    closeLabel={this.props.kedo.t('Close')}
                >
                    <Modal.Title>
                        Zoek op BSN of postcode gevolgd door huisnummer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    <Form.Group>
                        <Form.Label>Zoek</Form.Label>
                        <Form.Control
                            value={this.state.querystring}
                            onChange={(e) =>
                                this.setState({ querystring: e.target.value })
                            }
                            name={'querystring'}
                        />
                    </Form.Group>
                    {this.state.loading ? (
                        <center>
                            <LoadingDefault />
                        </center>
                    ) : null}
                    {this.renderResults(this.state.results)}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup className={'float-right'}>
                        <Button
                            disabled={this.state.saving}
                            onClick={this.doLookup}
                            variant={'primary'}
                        >
                            <FontAwesomeIcon icon={faSearch} />{' '}
                            {kedo.t('Search')}
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            variant={'secondary'}
                        >
                            {kedo.t('Cancel')}
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default BrpModal
