import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import BlockToolbar from "./BlockToolbar";
import LoadingDefault from "../../Elements/Loading/LoadingDefault";


export default class BlockDateStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            block: this.props.block,
            data: null,
            loading: false,
            listFieldTotal: 0,
            pager: null,
            blockSettings: this.props.block ? this.props.block.settings : null,
            pageError: false,
        };
        this.transFormData = this.transFormData.bind(this);
    }

    fetchData (disableLoader) {
        let environment = this.props.kedo.env().getEnvironment();
        if (!environment) {
            return;
        }

        if (!this.props.block.id) {
            return;
        }

        let api = this.props.kedo.api();

        let params = {params: {}};

        if (this.props.dossier && this.props.dossier.id) {
            params.dossier = this.props.dossier.id;
            params.params.dossier = this.props.dossier.id;
            params.params.linkId = this.props.link;
            params.linkId = this.props.link;
        }

        if (disableLoader !== false) {
            this.setState({loading: true});
        }

        api.get(api.getMyPageBlockEndpoint(this.props.block.id)+'/data', params)
            .then(response => {

                if (this.state.blockSettings.list_field) {

                    api.get(api.getDefDossierDefFieldEndpoint(this.state.blockSettings.list_field))
                        .then(dddfResponse => {
                            this.setState({
                                data: response.data,
                                listField: dddfResponse.data,
                                listFieldTotal: dddfResponse.data && dddfResponse.data.def_field && dddfResponse.data.def_field.def_field_lists ? dddfResponse.data.def_field.def_field_lists.length : 0,
                                loading: false,
                            })
                        })
                } else {
                    this.setState({
                        data: response.data,
                        loading: false,
                    });
                }




            })
    }

    transFormData(data) {
        const kedo = this.props.kedo;
        if (!this.state.listField) {
            return null;
        }


        let defFieldLists = this.state.listField.def_field.def_field_lists;
        let results = [];
        let dates = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].period !== null && !dates.includes(data[i].period.toString())) {
                dates.push(data[i].period.toString());
            }
        }

        for (let i = 0;i < dates.length;i++) {
            let newResult = {};
            newResult.period = dates[i];
            for (let b=0;b < defFieldLists.length;b++) {
                let keyName = kedo.env().translateItem(defFieldLists[b], 'def_field_list');
                let keyColor = defFieldLists[b] && defFieldLists[b].color ? keyName+'Color':  null;
                let periodTotal = 0;
                for (let d = 0; d < data.length; d++) {
                    if (data[d].period !== null && data[d].period.toString() === dates[i].toString() && parseInt(data[d].def_field_list_id) === defFieldLists[b].id) {
                        periodTotal = data[d].total_items;
                        break;
                    }
                }
                newResult[keyName] = periodTotal;
                newResult[keyColor] = defFieldLists[b] && defFieldLists[b].color ? defFieldLists[b].color : null;
            }

            results.push(newResult);
        }

        return results;
    }

    componentDidMount() {
        this.fetchData();
    }

    getKeys(blockSettings, listField, linkField) {
        if (!listField && !linkField) {
            return ['total_minutes'];
        }

        if (listField) {
            return this.state.listField.def_field.def_field_lists.map(item => this.props.kedo.env().translateItem(item, 'def_field_list'));
        }

        let items = [];

        return '';
    }

    renderData(data) {
        if (!data || data.length <= 0) {
            return '';
        }

        let keys = this.getKeys(this.state.blockSettings, this.state.listField, this.state.linkField);

        return <ResponsiveBar
            data={this.transFormData(data)}
            keys={keys}
            groupMode="grouped"
            enableLabel={false}
            colors={({ id, data }) => String(data[`${id}Color`])}
            indexBy="period"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: this.props.kedo.t('Date'),
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                format: e => Math.floor(e) === e && e,
                tickRotation: 0,
                legend: this.props.kedo.t('Totals'),
                legendPosition: 'middle',
                legendOffset: -40
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 50,
                    itemsSpacing: 2,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            theme={{
                axis: {
                    legend: {
                        text: {
                            fontWeight: 700
                        }
                    },
                    ticks: {
                        text: {
                            fontWeight: 300,
                        }
                    }
                }
            }}
            tooltip={ set => `${this.props.kedo.t(set.id)} ${set.value}` }
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            margin={{ top: 20, right: 130, bottom: 70, left: 60 }}
            padding={0.7}
            valueScale={{ type: 'linear' }}
        />;
    }

    getBlockLink() {
        if (!this.props.block || !this.props.block.def_dossier_id) {
            return '#';
        }

        let url = `/defdossier/${this.props.block.def_dossier_id}`;

        if (this.props.block.dossier_query_id) {
            let env = this.props.kedo.env().getEnvironment();

            if (env && env.defdossiers) {
                let dd = env.defdossiers.find(item => item.id === this.props.block.def_dossier_id);
                if (dd && dd.dossierqueries) {
                    let q = dd.dossierqueries.find(item => item.id === this.props.block.dossier_query_id);
                    if (q && q.uuid) {
                        url = url+'?dossierQuery='+q.uuid;
                    }
                }
            }
        }

        return url;
    }

    render() {
        const kedo = this.props.kedo;
        const blockLink = this.getBlockLink();

        return   <div className={!this.props.dashboard ? 'kedo-block' : null} style={{height: "100%", marginBottom: '30px', minHeight: "300px", minWidth: "300px"}}>
            <BlockToolbar
                onClose={this.props.onClose}
                onSuccess={this.props.onSuccess}
                block={this.props.block}
                kedo={kedo}
                fetchData={() => this.fetchData()}
            />
            {this.props.misc && this.props.misc.hideTitle == true ? null : <h3><a href={blockLink}>{kedo.env().translateItem(this.props.block, 'mypageblock')}</a></h3>}
            {this.state.loading ? <center><LoadingDefault /></center> : this.renderData(this.state.data)}
        </div>
    }
}