import * as React from 'react'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import DefaultGrid from '../../Grid/DefaultGrid.jsx'

const Sidebar = (props) => {
    const kedo = props.kedo
    const leftSideBar = props.leftSideBar
    const view = leftSideBar
        ? isNaN(leftSideBar)
            ? leftSideBar
            : 'show/edit'
        : null

    const [dossier, setDossier] = useState({})
    const [contents, setContents] = useState([])
    const [items, setItems] = useState([])

    const getContentByView = (displayContents, displayItems, viewName) => {
        const returnContent = {}

        const filterItems = displayItems.filter(
            (displayItem) => displayItem.view === viewName
        )

        if (filterItems.length >= 0) {
            filterItems.map((displayItem) => {
                const find = displayContents.find(
                    (content) => content.content.id == displayItem.id
                )
                find
                    ? (returnContent[displayItem.id] = find.content.content)
                    : null
            })
        }

        return returnContent
    }

    const fetchCustomDisplayView = async (defDossier, dosId) => {
        const params: Record<string, unknown> = {
            defDossier: defDossier,
            dossierIds: dosId,
        }

        if (leftSideBar && isNaN(leftSideBar)) {
            params.view = leftSideBar
        }

        if (leftSideBar && !isNaN(leftSideBar)) {
            params.displayPosition = leftSideBar
        }

        await kedo
            .api()
            .get(kedo.api().getContentDossierEndpoint(), { params: params })
            .then((result) => {
                setDossier(
                    result &&
                        result.data.results &&
                        result.data.results.dossiers &&
                        result.data.results.dossiers.length > 0
                        ? result.data.results.dossiers[0]
                        : {}
                )
                setContents(
                    result &&
                        result.data.results &&
                        result.data.results.contents
                        ? result.data.results.contents
                        : []
                )
                setItems(
                    result && result.data.results && result.data.results.items
                        ? result.data.results.items
                        : []
                )
            })
    }

    useEffect(() => {
        fetchCustomDisplayView(props.embeddedDefDossierId, props.dossierId)
    }, [])

    return (
        <>
            <div>
                <Row>
                    <Col xs={12}>
                        <h3>{kedo.t('Related')}</h3>
                        <DefaultGrid
                            key={props.dossierId}
                            defDossierSettings={
                                props.defdossier && props.defdossier.settings
                                    ? props.defdossier.settings
                                    : null
                            }
                            conditions={
                                props.defdossier &&
                                props.defdossier.settings &&
                                props.defdossier.settings.conditions
                                    ? props.defdossier.settings.conditions
                                    : []
                            }
                            content={getContentByView(
                                contents.filter(
                                    (contentItem) =>
                                        contentItem.id === props.dossierId
                                ),
                                items,
                                view
                            )}
                            dossier={dossier}
                            items={items.filter(
                                (displayItem) => displayItem.view === view
                            )}
                            kedo={kedo}
                            mode={isNaN(leftSideBar) ? leftSideBar : 'show'}
                            withoutEdit={true}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Sidebar
