import React, {Component} from 'react'
import {Button, ButtonToolbar, Card} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronRight} from "@fortawesome/free-solid-svg-icons"
import logo from "../../../images/xlab-logo.jpg";
import EnvironmentInfo from "./EnvironmentDetails";

class EnvironmentCard extends Component {
    getLogo(item) {
        if (!item.logo || !item.logo) {
            return logo;
        }

        return item.logo
    }

    addDefaultSrc(ev){
        ev.target.src = '/static/images/xlab-logo.jpg';
    }

    render() {
        const kedo = this.props.kedo;
        return  <Card className={`environmentCard d-flex flex-column h-100 `+(this.props.item.active !== true ? ' environment-inactive' : '')}>
                    <div className={"environmentAvatar"} onClick={this.props.item.active ? this.props.environment : null}>
                        <Card.Img onError={this.addDefaultSrc} src={`/images/environment/${this.props.item.id}/logo.png`} />
                    </div>
                    <Card.Body onClick={this.props.item.active ? this.props.environment : null}>
                        <div className={"environmentBody"}>
                            <Card.Title>{this.props.item.name}</Card.Title>
                            {this.props.item.active
                                ? window.innerWidth > 576 ? <Card.Text>{this.props.item.description}</Card.Text> : null
                                : <i>{this.props.kedo.t('Environment is inactive')}</i>
                            }
                        </div>
                    </Card.Body>
                    <Card.Footer className={"clear-cardfooter"}>
                        <ButtonToolbar className={"float-right"}>
                            <EnvironmentInfo kedo={kedo} environment={this.props.environment} item={this.props.item} /> &nbsp;&nbsp;
                            <Button disabled={!this.props.item.active} onClick={this.props.environment} title={kedo.t("Open environment")} value={this.props.item.id} variant="primary">
                                <FontAwesomeIcon icon={faChevronRight} style={{width: "16px"}}/>
                            </Button>
                        </ButtonToolbar>
                    </Card.Footer>
                </Card>
    }
}

export default EnvironmentCard