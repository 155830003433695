import React, { Component } from 'react'
import { Button, Form, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons'

class QueryBuilderField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.field.id,
            uuid: this.props.field.uuid,
            type: this.props.field.type ? this.props.field.type : null,
            dataField: this.props.dataField,
            value: this.props.field.value ? this.props.field.value : null,
            error: null,
        }
        this.onChange = this.onChange.bind(this)
        this.onChangeMultiple = this.onChangeMultiple.bind(this)
    }

    onChangeMultiple(e) {
        let values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )

        let field = {
            id: this.state.id,
            value: values,
            type: this.state.type,
        }

        field[e.target.name] = values
        this.setState({ [e.target.name]: values })
        this.props.onChange(this.props.index, field)
    }

    onChange(e) {
        let field = {
            id: this.state.id,
            value: this.state.value,
            type: this.state.type,
        }

        field[e.target.name] = e.target.value
        this.setState({ [e.target.name]: e.target.value })
        this.props.onChange(this.props.index, field)
    }

    renderSettings() {
        if (!this.state.dataField) {
            return ''
        }
        const kedo = this.props.kedo
        let defField = this.state.dataField.def_field
        if (!defField || !defField.type) {
            return ''
        }

        let fieldType = defField.type
        let settings = defField.settings

        let settingItems = []
        switch (fieldType) {
            case 'list':
                return (
                    <div>
                        <Form.Group>
                            <Form.Label>{kedo.t('Type')}</Form.Label>
                            <Form.Control
                                value={this.state.type}
                                onChange={this.onChange}
                                name={'type'}
                                as={'select'}
                            >
                                <option>{kedo.t('Choose an option')}</option>
                                <option value="equals">
                                    {kedo.t('is equal to')}
                                </option>
                                <option value="not_equals">
                                    {kedo.t('is NOT equal to')}
                                </option>
                                <option value="in">
                                    {kedo.t('is one of the selected values.')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{kedo.t('Value')}</Form.Label>
                            <Form.Control
                                value={this.state.value}
                                onChange={this.onChange}
                                name={'value'}
                                as={'select'}
                            >
                                <option>
                                    {this.props.kedo.t('Choose an option')}
                                </option>
                                <option value={'NULL'}>
                                    {kedo.t('Empty')}
                                </option>
                                {defField.def_field_lists.map((listOption) => (
                                    <option
                                        key={listOption.id}
                                        value={listOption.id}
                                    >
                                        {this.props.kedo
                                            .env()
                                            .translateItem(
                                                listOption,
                                                'def_field_list'
                                            )}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                )
            case 'user':
                return (
                    <div>
                        <Form.Group>
                            <Form.Label>{kedo.t('Type')}</Form.Label>
                            <Form.Control
                                value={this.state.type}
                                onChange={this.onChange}
                                name={'type'}
                                as={'select'}
                            >
                                <option>{kedo.t('Choose an option')}</option>
                                <option value="equals">
                                    {kedo.t('is equal to')}
                                </option>
                                <option value="not_equals">
                                    {kedo.t('is NOT equal to')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{kedo.t('Type')}</Form.Label>
                            <Form.Control
                                value={this.state.value}
                                onChange={this.onChange}
                                name={'value'}
                                as={'select'}
                            >
                                <option>{kedo.t('Choose an option')}</option>
                                <option value={'{user}'}>
                                    {kedo.t('the logged-in user himself')}
                                </option>
                                <option value={'null'}>
                                    {kedo.t('Empty selection (0 users)')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                )
            case 'date':
                return (
                    <div>
                        <Form.Group>
                            <Form.Label>{kedo.t('Type')}</Form.Label>
                            <Form.Control
                                value={this.state.type}
                                onChange={this.onChange}
                                name={'type'}
                                as={'select'}
                            >
                                <option>{kedo.t('Choose an option')}</option>
                                <optgroup label={'Conditions'}>
                                    <option value="equals">
                                        {kedo.t('Date equals to (exact match)')}
                                    </option>
                                    <option value="not_equals">
                                        {kedo.t(
                                            'Date is not equal to (exact match)'
                                        )}
                                    </option>
                                    <option value="date_gt">
                                        Date is greater &gt;
                                    </option>
                                    <option value="date_gte">
                                        Date is greater or equals to &gt;=
                                    </option>
                                    <option value="date_lt">
                                        Date is lesser &lt;
                                    </option>
                                    <option value="date_lte">
                                        Date is lesser or equals to &lt;=
                                    </option>
                                </optgroup>
                                <optgroup label={'Add'}>
                                    <option value="max_days_old">
                                        Add number of days to content. The
                                        result must be in the future (or equals
                                        today).
                                    </option>
                                    <option value="min_days_old">
                                        Add number of days from content. The
                                        result must be in the past (or equals
                                        today).
                                    </option>
                                    <option value="max_weeks_old">
                                        Add number of weeks to content. The
                                        result must be in the future (or equals
                                        today).
                                    </option>
                                    <option value="min_weeks_old">
                                        Add number of weeks from content. The
                                        result must be in the past (or equals
                                        today).
                                    </option>
                                    <option value="max_months_old">
                                        Add number of months to content. The
                                        result must be in the future (or equals
                                        today).
                                    </option>
                                    <option value="min_months_old">
                                        Add number of months from content. The
                                        result must be in the past (or equals
                                        today).
                                    </option>
                                    <option value="max_days_to_go">
                                        Add number of days to content. The
                                        result must be in the future.
                                    </option>
                                    <option value="min_days_to_go">
                                        Add number of days from content. The
                                        result must be in the past.
                                    </option>
                                    <option value="max_years_old">
                                        Add number of years to content. The
                                        result must be in the future (or equals
                                        today).
                                    </option>
                                    <option value="min_years_old">
                                        Add number of years from content. The
                                        result must be in the past (or equals
                                        today).
                                    </option>
                                </optgroup>
                                <optgroup label={'Substract'}>
                                    <option value="max_days_old_subtract">
                                        Subtract number of days to content. The
                                        result must be in the future (or equals
                                        today).
                                    </option>
                                    <option value="min_days_old_subtract">
                                        Subtract number of days from content.
                                        The result must be in the past (or
                                        equals today).
                                    </option>
                                    <option value="max_weeks_old_subtract">
                                        Subtract number of weeks to content. The
                                        result must be in the future (or equals
                                        today).
                                    </option>
                                    <option value="min_weeks_old_subtract">
                                        Subtract number of weeks from content.
                                        The result must be in the past (or
                                        equals today).
                                    </option>
                                    <option value="max_months_old_subtract">
                                        Subtract number of months to content.
                                        The result must be in the future (or
                                        equals today).
                                    </option>
                                    <option value="min_months_old_subtract">
                                        Subtract number of months from content.
                                        The result must be in the past (or
                                        equals today).
                                    </option>
                                    <option value="max_days_to_go_subtract">
                                        Subtract number of days to content. The
                                        result must be in the future.
                                    </option>
                                    <option value="min_days_to_go_subtract">
                                        Subtract number of days from content.
                                        The result must be in the past.
                                    </option>
                                    <option value="max_years_old_subtract">
                                        Subtract number of years to content. The
                                        result must be in the future (or equals
                                        today).
                                    </option>
                                    <option value="min_years_old_subtract">
                                        Subtract number of years from content.
                                        The result must be in the past (or
                                        equals today).
                                    </option>
                                </optgroup>
                                <optgroup label={'Between'}>
                                    <option value="date_between">
                                        Date is between. Syntax for input field:
                                        DATE1,DATE2. The comma is mandatory.
                                    </option>
                                    <option value="between_days">
                                        Between given number of days and TODAY
                                    </option>
                                    <option value="between_weeks">
                                        Between given number of weeks and TODAY
                                    </option>
                                    <option value="between_months">
                                        Between given number of months and TODAY
                                    </option>
                                    <option value="between_years">
                                        Between given number of year and TODAY
                                    </option>
                                    <option value="birthday_weeks">
                                        Given content date on today OR between
                                        TODAY and given number of weeks.
                                    </option>
                                </optgroup>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Value (enter a number, NULL, NOT NULL, or a
                                date):
                            </Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                value={this.state.value}
                                name={'value'}
                            />
                        </Form.Group>
                    </div>
                )
            case 'text':
            case 'autoIncrement':
            case 'calc':
            case 'url':
            case 'email':
            case 'amount':
                return (
                    <div>
                        <Form.Group>
                            <Form.Label>{kedo.t('Type')}</Form.Label>
                            <Form.Control
                                value={this.state.type}
                                onChange={this.onChange}
                                name={'type'}
                                as={'select'}
                            >
                                <option>{kedo.t('Choose an option')}</option>
                                <option value="string_empty">is EMPTY</option>
                                <option value="string_is_not_empty">
                                    is NOT EMPTY
                                </option>
                                <option value="string_exact_equals">
                                    is equal to (exact match)
                                </option>
                                <option value="string_exact_not_equals">
                                    is NOT equal to (exact match)
                                </option>
                                <option value="string_like_equals">
                                    IS LIKE (pattern)
                                </option>
                                <option value="string_not_like_equals">
                                    IS NOT LIKE (pattern)
                                </option>
                                <option value="string_numerical_equal">
                                    Numeric input: equal =
                                </option>
                                <option value="string_numerical_not_equal">
                                    Numeric input: not equal &lt;&gt;
                                </option>
                                <option value="string_numerical_bt">
                                    Numeric: &gt;
                                </option>
                                <option value="string_numerical_bte">
                                    Numeric: &gt;=
                                </option>
                                <option value="string_numerical_st">
                                    Numeric: &lt;
                                </option>
                                <option value="string_numerical_ste">
                                    Numeric: &lt;=
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{kedo.t('Value')}</Form.Label>
                            <Form.Control
                                readOnly={
                                    this.state.type === 'string_empty' ||
                                    this.state.type === 'string_is_not_empty'
                                }
                                onChange={this.onChange}
                                value={this.state.value}
                                name={'value'}
                            />
                        </Form.Group>
                    </div>
                )
            default:
                settingItems = []
        }
    }

    fetchField() {
        let url = this.props.kedo.api().getDefDossierDefFieldEndpoint()
        this.props.kedo
            .api()
            .get(url + '/' + this.props.field.id)
            .then((response) =>
                this.setState({
                    dataField: response.data,
                })
            )
            .catch((err) => {
                this.setState({
                    error: err.response.status,
                })
            })
    }

    componentDidMount() {
        this.fetchField()
    }

    render() {
        const kedo = this.props.kedo
        return (
            <div className="card mb-3">
                <div className="card-header text-white bg-dark">
                    {this.state.dataField
                        ? this.state.dataField.name
                        : this.state.id}
                    {this.state.error && this.state.error === 404 ? (
                        <Badge variant={'warning'}>
                            {kedo.t('Item not found')}{' '}
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Badge>
                    ) : null}
                    {this.state.error && this.state.error !== 404 ? (
                        <Badge variant={'warning'}>
                            {kedo.t('Something went wrong')}{' '}
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Badge>
                    ) : null}

                    <Button
                        className={'float-right'}
                        size={'sm'}
                        onClick={() => this.props.onDelete(this.state.uuid)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                <div className="card-body bg-light">
                    {this.renderSettings()}
                </div>
            </div>
        )
    }
}

export default QueryBuilderField
