import * as React from 'react'
import { useState, useEffect } from 'react'
import {
    Button,
    Modal,
    Form,
    Row,
    Col,
    ProgressBar,
    Alert,
    InputGroup,
} from 'react-bootstrap'
import { Kedo } from '../../../../Kedo'
import * as Datetime from 'react-datetime'
import { RRule, Options } from 'rrule'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCalendar,
    faPencilAlt,
    faCog,
} from '@fortawesome/free-solid-svg-icons'
import DisplayItemFilter from '../../../DisplayItems/Filter/DisplayItemFilter'
import * as moment from 'moment'

type MultiAddProps = {
    kedo: Kedo
    handleGetValues: () => Array<any>
    handleClose: () => void
    handleSuccess: () => void
    displayItems: Array<any>
}

const ShowMultiAdd = ({
    kedo,
    handleGetValues,
    displayItems,
    handleClose,
    handleSuccess,
}: MultiAddProps) => {
    const [item, setItem] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [modalRecur, setModalRecur] = useState(false)
    const [curPeriod, setCurPeriod] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [startOpen, setStartOpen] = useState(false)
    const [endDate, setEndDate] = useState(null)
    const [endOpen, setEndOpen] = useState(false)
    const [days, setDays] = useState([])
    const [dates, setDates] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [interval, setInterval] = useState(1)
    const [done, setDone] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState([])
    const [mode, setMode] = useState(null)
    const [byMonthDay, setByMonthDay] = useState('1')
    const [bySetPos, setBySetPos] = useState(null)

    useEffect(() => {
        setStartDate(new Date())
        setEndDate(new Date())
    }, [])

    const handleRecurChange = (e) => {
        if (e.target.value === '') {
            setCurPeriod(null)
            return
        }

        setCurPeriod(e.target.value)
        setModalRecur(true)
    }

    const renderChangeRecurType = () => {
        return (
            <InputGroup>
                <Form.Control
                    onChange={(e) => handleRecurChange(e)}
                    as={'select'}
                >
                    <option value={''}>{kedo.t('Not recurrent')}</option>
                    <option value={'daily'}>{kedo.t('Daily')}</option>
                    <option value={'weekly'}>{kedo.t('Weekly')}</option>
                    <option value={'monthly'}>{kedo.t('Monthly')}</option>
                </Form.Control>
                <span className="input-group-append">
                    <Button
                        disabled={!curPeriod ? true : false}
                        className="border-left-0"
                        onClick={() => setModalRecur(true)}
                    >
                        <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
                    </Button>
                </span>
            </InputGroup>
        )
    }

    const changeDay = (e): void => {
        if (curPeriod === 'monthly') {
            setDays(e.target.value.split(','))
        } else {
            let newDays = [...days]
            newDays = newDays.filter((item) => item !== e.target.value)

            if (e.target.checked) {
                newDays.push(e.target.value)
            }

            setDays(newDays)
        }
    }

    useEffect(() => {
        setErrors([])

        if (!startDate || !endDate) {
            return
        }
        setDateRules()
    }, [days, startDate, endDate, interval])

    const setDateRules = () => {
        const weekDays = []
        const errorsArray = []

        if (days.includes('Sunday')) {
            weekDays.push(RRule.SU)
        }
        if (days.includes('Monday')) {
            weekDays.push(RRule.MO)
        }
        if (days.includes('Tuesday')) {
            weekDays.push(RRule.TU)
        }
        if (days.includes('Wednesday')) {
            weekDays.push(RRule.WE)
        }
        if (days.includes('Thursday')) {
            weekDays.push(RRule.TH)
        }
        if (days.includes('Friday')) {
            weekDays.push(RRule.FR)
        }
        if (days.includes('Saturday')) {
            weekDays.push(RRule.SA)
        }

        const data = {
            interval: interval,
            freq:
                curPeriod === 'daily'
                    ? RRule.DAILY
                    : curPeriod === 'weekly'
                    ? RRule.WEEKLY
                    : RRule.MONTHLY,
            dtstart: startDate,
            until: endDate,
        } as Options

        if (weekDays.length > 0) {
            data.byweekday = weekDays
        }

        if (mode === 'BYSETPOS') {
            data.bysetpos = bySetPos
        }

        if (mode === 'BYMONTHDAY') {
            data.bymonthday = parseInt(byMonthDay)
        }

        const rule = new RRule(data)
        const all = rule.all()

        setErrors(errorsArray)
        setDates(all)
    }

    const changeMonthMode = (e): void => {
        if (e.target.name !== mode) {
            setDays([])
            setBySetPos(null)
            setByMonthDay(null)
            setMode(e.target.name)
            initMonthMode(e.target.name)
        }
    }

    const initMonthMode = (mode): void => {
        if (mode === 'BYMONTHDAY') {
            setByMonthDay('1')
        }

        if (mode === 'BYSETPOS') {
            setBySetPos('1')
            setDays(['Monday'])
        }
    }

    const renderRecurMonthly = () => {
        return (
            <Row className={'p-3'}>
                <Col md={6}>
                    <Form.Row className={'align-items-center p-2'}>
                        <Form.Label className={'no-margin'} sm={3}>
                            {kedo.t('Repeat every')}
                        </Form.Label>
                        <Col sm={4} xs={2}>
                            <Form.Control
                                defaultValue={interval}
                                onChange={(e) =>
                                    setInterval(parseInt(e.target.value))
                                }
                                as={'select'}
                                size="sm"
                            >
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                <option value={'4'}>4</option>
                                <option value={'5'}>5</option>
                            </Form.Control>
                        </Col>
                        <Form.Label className={'no-margin'} sm={3}>
                            {kedo.t(translateDays(curPeriod)).toLowerCase()}
                        </Form.Label>
                    </Form.Row>
                    <Form.Row className={'align-items-center p-2'}>
                        <Form.Check
                            checked={mode === 'BYMONTHDAY' ? true : false}
                            onChange={changeMonthMode}
                            name={'BYMONTHDAY'}
                            type="checkbox"
                        />
                        <Form.Label className={'no-margin'} sm={3}>
                            {kedo.t('On day')}
                        </Form.Label>
                        <Col sm={4} xs={2}>
                            <Form.Control
                                defaultValue={byMonthDay}
                                disabled={mode !== 'BYMONTHDAY' ? true : false}
                                onChange={(e) => setByMonthDay(e.target.value)}
                                as={'select'}
                                size="sm"
                            >
                                {Array.from(Array(32).keys())
                                    .slice(1)
                                    .map((item, index) => (
                                        <option key={index} value={`${item}`}>
                                            {item}
                                        </option>
                                    ))}
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className={'align-items-center p-2'}>
                        <Form.Check
                            checked={mode === 'BYSETPOS' ? true : false}
                            onChange={changeMonthMode}
                            name={'BYSETPOS'}
                            type="checkbox"
                        />
                        <Form.Label className={'no-margin'} sm={3}>
                            {kedo.t('On the')}
                        </Form.Label>
                        <Col sm={4} xs={3}>
                            <Form.Control
                                defaultValue={bySetPos}
                                disabled={mode !== 'BYSETPOS' ? true : false}
                                onChange={(e) => setBySetPos(e.target.value)}
                                as={'select'}
                                size="sm"
                            >
                                <option value={'1'}>{kedo.t('First')}</option>
                                <option value={'2'}>{kedo.t('Second')}</option>
                                <option value={'3'}>{kedo.t('Third')}</option>
                                <option value={'4'}>{kedo.t('Fourth')}</option>
                                <option value={'-1'}>{kedo.t('Last')}</option>
                            </Form.Control>
                        </Col>
                        <Col sm={4} xs={3}>
                            <Form.Control
                                defaultValue={days.toString()}
                                disabled={mode !== 'BYSETPOS' ? true : false}
                                onChange={changeDay}
                                as={'select'}
                                size="sm"
                            >
                                <option value={'Monday'}>
                                    {kedo.t('days_full.Monday')}
                                </option>
                                <option value={'Tuesday'}>
                                    {kedo.t('days_full.Tuesday')}
                                </option>
                                <option value={'Wednesday'}>
                                    {kedo.t('days_full.Wednesday')}
                                </option>
                                <option value={'Thursday'}>
                                    {kedo.t('days_full.Thursday')}
                                </option>
                                <option value={'Friday'}>
                                    {kedo.t('days_full.Friday')}
                                </option>
                                <option value={'Saturday'}>
                                    {kedo.t('days_full.Saturday')}
                                </option>
                                <option value={'Sunday'}>
                                    {kedo.t('days_full.Sunday')}
                                </option>
                                <option
                                    value={
                                        'Monday,Tuesday,Wednesday,Thursday,Friday'
                                    }
                                >
                                    {kedo.t('Weekday')}
                                </option>
                                <option value={'Saturday,Sunday'}>
                                    {kedo.t('Weekend day')}
                                </option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                </Col>
            </Row>
        )
    }

    const translateDays = (period) => {
        switch (period) {
            case 'daily':
                return 'Day'
            case 'yearly':
                return 'Year'
            case 'monthly':
                return 'Month'
            case 'weekly':
                return 'Week'
            default:
                return ''
        }
    }

    const translateOrdinals = (number) => {
        switch (number) {
            case '1':
                return kedo.t('First')
            case '2':
                return kedo.t('Second')
            case '3':
                return kedo.t('Third')
            case '4':
                return kedo.t('Fourth')
            case '-1':
                return kedo.t('Last')
            default:
                return ''
        }
    }

    const renderRecurWeekly = () => {
        return (
            <Row className={'p-2'}>
                {renderRecurDays()}
                <Col md={6}>
                    <Form.Row className={'d-flex justify-items-center'}>
                        <Form.Label sm={3}>{kedo.t('Repeat every')}</Form.Label>
                        <Col sm={2} xs={2}>
                            <Form.Control
                                onChange={(e) =>
                                    setInterval(parseInt(e.target.value))
                                }
                                as={'select'}
                                size="sm"
                            >
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                <option value={'4'}>4</option>
                                <option value={'5'}>5</option>
                            </Form.Control>
                        </Col>
                        <Form.Label sm={3}>
                            {kedo.t(translateDays(curPeriod)).toLowerCase()}
                        </Form.Label>
                    </Form.Row>
                </Col>
            </Row>
        )
    }

    const renderRecurDays = () => {
        const avDays = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ]

        return (
            <Col md={6}>
                {avDays.map((avDay) => (
                    <Form.Group key={avDay}>
                        <Form.Check
                            checked={days.includes(avDay)}
                            onChange={changeDay}
                            type="checkbox"
                            value={avDay}
                            label={kedo.t('days_full.' + avDay)}
                        />
                    </Form.Group>
                ))}
            </Col>
        )
    }

    const renderShowRecurDetail = () => {
        return (
            <Modal
                size={'lg'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => setModalRecur(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{kedo.t('Select recurrency')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {curPeriod === 'daily' ? renderRecurDays() : null}
                    {curPeriod === 'weekly' ? renderRecurWeekly() : null}
                    {curPeriod === 'monthly' ? renderRecurMonthly() : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalRecur(false)}>
                        {kedo.t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const isValidStartDate = (current) => {
        if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'before_and_eq_current_date'
        ) {
            return current.isSameOrBefore(moment())
        } else if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'after_and_eq_current_date'
        ) {
            return current.isSameOrAfter(moment())
        } else {
            return current
        }
    }

    const isValidEndDate = (current) => {
        if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'before_and_eq_current_date'
        ) {
            return current.isSameOrBefore(moment())
        } else if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'after_and_eq_current_date'
        ) {
            return current.isSameOrAfter(moment())
        } else {
            return current >= moment(startDate).add(-1, 'days')
        }
    }

    const submitDossier = async (date) => {
        const values = handleGetValues()

        const itemId = item.id
        values[itemId] = date.toISOString()
        setCurrentItem(date.toLocaleString())
        const response = await kedo
            .api()
            .post(kedo.api().getContentDossierEndpoint(), values)
    }

    const submitDossiers = async () => {
        const errorsArray = []

        if (!isValidStartDate(moment(startDate))) {
            errorsArray.push(kedo.t('end_or_start_date_future'))
        }

        if (!isValidEndDate(moment(endDate))) {
            errorsArray.push(kedo.t('end_date_out_of_range'))
        }

        if (errorsArray.length >= 0) {
            setErrors(errorsArray)
        }

        if (dates.length <= 0) {
            return
        }

        setTotalItems(dates.length)
        setSubmitting(true)

        const forLoop = async () => {
            for (let index = 0; index < dates.length; index++) {
                await submitDossier(dates[index])
                setDone(index + 1)
            }
        }
        await forLoop()
        setSuccess(true)
        setTimeout(() => {
            setSubmitting(false)
            handleSuccess()
        }, 2000)
    }

    const getCurrentLocale = () => {
        const locale = kedo.env().getSelectedLocale()
        if (locale && locale.code) {
            return locale.code.replace('_', '-')
        }

        return 'en'
    }

    const renderItem = () => {
        if (!item) {
            return
        }
        if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.def_field &&
            item.def_dossier_def_field.def_field.type === 'date'
        ) {
            return (
                <Row>
                    <Col lg={8}>
                        <Form.Group>
                            <Form.Row>
                                <Form.Label column="sm" lg={2}>
                                    {kedo.t('Start')}
                                </Form.Label>
                                <Col>
                                    <InputGroup>
                                        <Datetime
                                            locale={getCurrentLocale()}
                                            inputProps={{
                                                disabled: submitting,
                                                readOnly: true,
                                            }}
                                            closeOnSelect={true}
                                            open={startOpen}
                                            onChange={(newDate) => {
                                                if (
                                                    typeof newDate === 'string'
                                                ) {
                                                    setStartDate(
                                                        new Date(newDate)
                                                    )
                                                    return
                                                }
                                                setStartDate(
                                                    new Date(newDate.format())
                                                )
                                                setStartOpen(false)
                                            }}
                                            value={startDate}
                                            isValidDate={isValidStartDate}
                                            utc={false}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                onClick={() => {
                                                    setEndOpen(false)
                                                    setStartOpen(!startOpen)
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCalendar}
                                                />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Form.Label column="sm" lg={2}>
                                    {kedo.t('End')}
                                </Form.Label>
                                <Col>
                                    <InputGroup>
                                        <Datetime
                                            locale={getCurrentLocale()}
                                            inputProps={{
                                                disabled: submitting,
                                                readOnly: true,
                                            }}
                                            closeOnSelect={true}
                                            onChange={(newDate) => {
                                                if (
                                                    typeof newDate === 'string'
                                                ) {
                                                    setEndDate(
                                                        new Date(newDate)
                                                    )
                                                    return
                                                }
                                                setEndDate(
                                                    new Date(newDate.format())
                                                )
                                                setEndOpen(false)
                                            }}
                                            open={endOpen}
                                            value={endDate}
                                            isValidDate={isValidEndDate}
                                            utc={false}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                onClick={() => {
                                                    setStartOpen(false)
                                                    setEndOpen(!endOpen)
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCalendar}
                                                />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group>
                            <Form.Row>
                                <Col>{renderChangeRecurType()}</Col>
                            </Form.Row>
                        </Form.Group>
                    </Col>
                </Row>
            )
        }

        return
    }

    const renderAlert = () => {
        if (item?.def_dossier_def_field?.def_field?.settings?.restriction) {
            const restriction =
                item.def_dossier_def_field.def_field.settings.restriction

            if (restriction === 'before_and_eq_current_date') {
                return (
                    <Alert variant="warning">
                        {`${kedo.t(
                            'Before and equal to the current date'
                        )}: ${kedo.t('Enabled')}`}
                    </Alert>
                )
            } else if (restriction === 'after_and_eq_current_date') {
                return (
                    <Alert variant="warning">
                        {`${kedo.t(
                            'After and equal to the current date'
                        )}: ${kedo.t('Enabled')}`}
                    </Alert>
                )
            }
        }
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>{kedo.t('Multi add items')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {item ? renderAlert() : null}
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" md={12}>
                            {kedo.t('Choose a field to create items by a set')}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                as="select"
                                onChange={(e) => {
                                    setItem(
                                        displayItems.find(
                                            (ddiItem) =>
                                                ddiItem.id ===
                                                parseInt(e.target.value)
                                        )
                                    )
                                }}
                            >
                                <option>{kedo.t('Choose an option')}</option>
                                {DisplayItemFilter.byTypes(displayItems, [
                                    'date',
                                    'datetime',
                                ]).map((displayItem) => (
                                    <option
                                        value={displayItem.id}
                                        key={displayItem.id}
                                    >
                                        {kedo.translateItem(
                                            displayItem,
                                            'displayitem'
                                        )}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Row>
                </Form.Group>
                {item ? renderItem() : null}
                {modalRecur ? renderShowRecurDetail() : null}
                {(curPeriod === 'daily' ||
                    curPeriod === 'weekly' ||
                    curPeriod === 'monthly') &&
                days.length ? (
                    <Col>
                        {curPeriod === 'weekly' || curPeriod === 'monthly' ? (
                            <>
                                {`${kedo.t('Repeat every')} ${kedo
                                    .t(translateDays(curPeriod))
                                    .toLowerCase()} `}
                                <span className={'badge badge-secondary'}>
                                    {interval}x
                                </span>
                            </>
                        ) : null}
                        {curPeriod === 'monthly' ? (
                            <>
                                <br />
                                {`${kedo.t('On the')} `}
                                <span className={'badge badge-secondary'}>
                                    {kedo.t(translateOrdinals(bySetPos))}
                                </span>
                            </>
                        ) : null}
                        <br />
                        {days.map((day) => (
                            <span
                                key={day}
                                className={'badge badge-secondary mr-2'}
                            >
                                {kedo.t('days_full.' + day)}
                            </span>
                        ))}
                    </Col>
                ) : null}

                {submitting && !success ? (
                    <div className={'text-center'}>
                        <span>{currentItem}</span>
                    </div>
                ) : null}
                {submitting && !success ? (
                    <ProgressBar min={0} max={totalItems} animated now={done} />
                ) : null}
                {success ? (
                    <Alert variant={'success'}>
                        {kedo.t('Item has been saved')}
                    </Alert>
                ) : null}
                {errors && errors.length > 0 ? (
                    <Alert variant={'danger'}>
                        {errors.map((error, index) => (
                            <>
                                <span key={index}>{error}</span>
                                <br />
                            </>
                        ))}
                    </Alert>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={submitting}
                    variant={'primary'}
                    onClick={submitDossiers}
                >
                    {kedo.t('Save')}
                </Button>
                <Button
                    disabled={submitting}
                    onClick={handleClose}
                    variant={'secondary'}
                >
                    {kedo.t('Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ShowMultiAdd
