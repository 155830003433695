import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Alert, Modal } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import DossierModalEditButton from '../../Elements/DossierModalEditButton'
import DisplayItem from '../../DisplayItems/DisplayItem'
import NewContentDossier from '../ContentDossier/NewContentDossier'

const localizer = momentLocalizer(moment)

function Event({ event }) {
    if (event.use_custom === true) {
        return (
            <div>
                <strong>{event.title}</strong>
                <div>
                    {event.items.map((listItem) => (
                        <>
                            <DisplayItem
                                kedo={event.kedo}
                                changeValue={() => {
                                    //TODO
                                }}
                                content={
                                    event.content
                                        ? event.content[listItem.id]
                                        : null
                                }
                                dossier={event.dossier}
                                errors={null}
                                item={listItem}
                                key={listItem.id}
                                mode={event.view}
                            />{' '}
                            <br />
                        </>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <span>
            <strong>{event.title}</strong>
            {event.desc && ':  ' + event.desc}
        </span>
    )
}

function EventAgenda({ event }) {
    return (
        <span>
            <em style={{ color: 'magenta' }}>{event.title}</em>
            <p>{event.desc}</p>
        </span>
    )
}

class DefDossierCalender extends Component {
    constructor(props) {
        super(props)

        this.state = {
            items: [],
            defDossier: null,
            edit: false,
            calendar_date_field: null,
            calendar_date_list_field: null,
            startDate: null,
            endDate: null,
        }

        this.fetchContent = this.fetchContent.bind(this)
    }

    getContentByView(content, displayItems, viewName) {
        //filter the display items
        let returnContent = {}

        let filterItems = displayItems.filter(
            (displayItem) => displayItem.view === viewName
        )

        if (filterItems.length >= 0) {
            filterItems.map((displayItem, index) => {
                content[index]
                    ? (returnContent[displayItem.id] =
                          content[index].content.content)
                    : null
            })
        }

        return returnContent
    }

    fetchDefDossier() {
        let api = this.props.kedo.api()
        let localeCode = this.props.kedo.env().getSelectedLocale()
            ? this.props.kedo.env().getSelectedLocale().code
            : null
        api.get(api.getDefDossierEndpoint(this.props.defDossierId)).then(
            (response) => {
                let startDate = null
                let endDate = null
                if (response.data.settings.calendar_default === 'week') {
                    startDate = moment()
                        .clone()
                        .startOf('week')
                        .format('YYYY-MM-DD hh:mm')
                    endDate = moment()
                        .clone()
                        .endOf('week')
                        .format('YYYY-MM-DD hh:mm')
                } else {
                    startDate = moment()
                        .clone()
                        .startOf('month')
                        .format('YYYY-MM-DD hh:mm')
                    endDate = moment()
                        .clone()
                        .endOf('month')
                        .format('YYYY-MM-DD hh:mm')
                }

                this.setState(
                    {
                        defDossier: response.data,
                        localeCode: localeCode,
                        calendar_date_field:
                            response.data.settings.calendar_date_field,
                        calendarDateError: !response.data.settings
                            .calendar_date_field
                            ? true
                            : false,
                        calendar_custom_view: response.data.settings
                            .calendar_custom_view
                            ? response.data.settings.calendar_custom_view
                            : null,
                        calendar_date_list_field:
                            response.data.settings.calendar_date_list_field,
                        calendar_date_list_field_end:
                            response.data.settings.calendar_date_list_field_end,
                        calendar_edit_start:
                            response.data.settings.calendar_edit_start,
                        calendar_edit_end:
                            response.data.settings.calendar_edit_end,
                        calendarDateListError: !response.data.settings
                            .calendar_date_list_field
                            ? true
                            : false,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    this.fetchContent
                )
            }
        )
    }

    fetchContent() {
        let api = this.props.kedo.api()
        let dateId = this.state.calendar_date_field
        let dateTypeName = this.state.calendar_date_field + '_type'
        let dateEndName = this.state.calendar_date_field + '_end'

        let params = {
            params: {
                defDossier: this.props.defDossierId,
                [dateId]: this.state.startDate,
                [dateTypeName]: 'between',
                [dateEndName]: this.state.endDate,
            },
        }

        if (this.state.calendar_custom_view) {
            params.params.view = this.state.calendar_custom_view
        }

        this.setState({ loading: true })
        api.get(api.getContentDossierEndpoint(), params).then((response) => {
            let items = []
            let withTime = false
            let dateField = response.data.results.items.find(
                (item) =>
                    item.id === parseInt(this.state.calendar_date_list_field)
            )
            if (dateField) {
                withTime =
                    dateField.def_dossier_def_field &&
                    dateField.def_dossier_def_field.def_field &&
                    dateField.def_dossier_def_field.def_field.settings &&
                    dateField.def_dossier_def_field.def_field.settings
                        .withtime === true
                        ? true
                        : false
            }

            response.data.results.dossiers.map((dossier) => {
                let contents = response.data.results.contents.filter(
                    (item) => item.id === dossier.id
                )
                let dateValue = contents.find(
                    (item) =>
                        item.content.id ===
                        parseInt(this.state.calendar_date_list_field)
                )
                let endDateValue = null
                if (this.state.calendar_date_list_field_end) {
                    endDateValue = contents.find(
                        (item) =>
                            item.content.id ===
                            parseInt(this.state.calendar_date_list_field_end)
                    )
                }

                items.push({
                    key: dossier.id,
                    dossier: dossier,
                    view: this.state.calendar_custom_view,
                    use_custom: this.state.calendar_custom_view ? true : false,
                    items: this.state.calendar_custom_view
                        ? response.data.results.items.filter(
                              (displayItem) =>
                                  displayItem.view ===
                                      this.state.calendar_custom_view &&
                                  displayItem.id !==
                                      parseInt(
                                          this.state.calendar_date_list_field
                                      )
                          )
                        : null,
                    title: dossier.summary,
                    defDossier: this.state.defDossier,
                    kedo: this.props.kedo,
                    content: this.state.calendar_custom_view
                        ? this.getContentByView(
                              response.data.results.contents.filter(
                                  (contentItem) => contentItem.id === dossier.id
                              ),
                              response.data.results.items,
                              this.state.calendar_custom_view
                          )
                        : null,
                    start: new Date(dateValue.content.content),
                    end:
                        endDateValue &&
                        endDateValue.content &&
                        endDateValue.content.content
                            ? new Date(endDateValue.content.content)
                            : new Date(dateValue.content.content),
                    allDay: withTime === true ? false : true,
                })
            })

            this.setState({
                loading: false,
                items: items,
            })
        })
    }

    handleSelect = ({ start, end }) => {
        let newState = {
            showNew: true,
            newStart: start,
            newEnd: end,
            newContent: {},
        }
        if (this.state.calendar_edit_start) {
            newState.newContent[this.state.calendar_edit_start] = start
        }
        if (this.state.calendar_edit_end) {
            newState.newContent[this.state.calendar_edit_end] = end
        }
        this.setState(newState)
    }

    componentDidMount() {
        this.fetchDefDossier()
    }

    render() {
        const kedo = this.props.kedo
        if (this.state.calendarDateError === true) {
            return (
                <Alert variant={'warning'}>
                    Please contact your admin, date search field missing.
                </Alert>
            )
        }

        if (this.state.calendarDateListError === true) {
            return (
                <Alert variant={'warning'}>
                    Please contact your admin, date list field missing.
                </Alert>
            )
        }

        return (
            <div>
                {this.state.showNew ? (
                    <Modal
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={true}
                        size="xl"
                        onHide={() => this.setState({ showNew: false })}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.kedo.t('New')}{' '}
                                {this.state.defDossier
                                    ? this.props.kedo
                                          .env()
                                          .translateItem(
                                              this.state.defDossier,
                                              'defdossier'
                                          )
                                    : this.props.kedo
                                          .t('Dossier')
                                          .toLowerCase()}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <NewContentDossier
                                kedo={this.props.kedo}
                                content={this.state.newContent}
                                defDossierId={this.state.defDossier.id}
                                onClose={() =>
                                    this.setState({ showNew: false })
                                }
                                onSuccess={() => {
                                    this.setState(
                                        { showNew: false },
                                        this.fetchContent
                                    )
                                }}
                            />
                        </Modal.Body>
                    </Modal>
                ) : null}
                {this.state.edit ? (
                    <DossierModalEditButton
                        key={this.state.edit}
                        edit={true}
                        dossierId={this.state.edit}
                        kedo={this.props.kedo}
                    />
                ) : null}
                {this.state.loading ? <LoadingDefault /> : null}
                <Calendar
                    localizer={localizer}
                    selectable
                    onSelectEvent={(item) => this.setState({ edit: item.key })}
                    onRangeChange={(range) => {
                        if (range.start && range.end) {
                            this.setState(
                                {
                                    startDate: range.start.toISOString(),
                                    endDate: range.end.toISOString(),
                                },
                                this.fetchContent
                            )
                        } else if (Array.isArray(range) && range.length === 7) {
                            this.setState(
                                {
                                    startDate: range[0].toISOString(),
                                    endDate: range[6].toISOString(),
                                },
                                this.fetchContent
                            )
                        } else if (range.toISOString) {
                            this.setState(
                                {
                                    startDate: range.toISOString(),
                                    endDate: range.toISOString(),
                                },
                                this.fetchContent
                            )
                        } else {
                            this.setState(
                                {
                                    startDate: range.start.toISOString(),
                                    endDate: range.end.toISOString(),
                                },
                                this.fetchContent
                            )
                        }
                    }}
                    onSelectSlot={this.handleSelect}
                    culture={this.state.localeCode}
                    components={{
                        event: Event,
                        agenda: {
                            event: EventAgenda,
                        },
                    }}
                    events={this.state.items}
                    startAccessor="start"
                    messages={{
                        previous: kedo.t('Back'),
                        next: kedo.t('Next'),
                        month: kedo.t('Month'),
                        week: kedo.t('Week'),
                        work_week: kedo.t('Work week'),
                        day: kedo.t('Day'),
                        today: kedo.t('Today'),
                    }}
                    endAccessor="end"
                    style={{ minHeight: 600 }}
                    views={['month', 'week', 'work_week', 'day']}
                />
            </div>
        )
    }
}

export default DefDossierCalender
