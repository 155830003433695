import React, { Component } from 'react'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button } from 'react-bootstrap'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify'

class VerlofOverviewIndex extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            dossierId: null,
            htmlContent: null,
            items: [],
            pager: {},
        }
    }

    async fetchData() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        const response = await api.get(
            '/module/verlofoverview/' + this.props.dossierId
        )
        this.setState({
            htmlContent: response.data,
            loading: false,
        })
    }

    componentDidMount() {
        this.fetchData()
    }

    render() {
        const kedo = this.props.kedo

        let settings = {
            ALLOWED_TAGS: [
                'b',
                'br',
                'span',
                'table',
                'ol',
                'tbody',
                'li',
                'i',
                'strong',
                'tr',
                'td',
                'th',
                'thead',
                'div',
                'p',
                'h1',
                'h2',
            ],
            ALLOWED_ATTR: ['style', 'border', 'class'],
        }

        if (this.state.error === true) {
            return (
                <Alert variant={'warning'}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    <span className={'ml-2'}>
                        {this.props.kedo.t('Something went wrong')}
                    </span>
                </Alert>
            )
        }

        if (this.state.loading) {
            return <LoadingDefault />
        }

        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        this.state.htmlContent,
                        settings
                    ),
                }}
            />
        )
    }
}

export default VerlofOverviewIndex
