import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-duotone-svg-icons'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'

class ContentDossierArchive extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.archive = this.archive.bind(this)
        this.dearchive = this.dearchive.bind(this)
    }

    archive() {
        let url =
            this.props.kedo.api().getContentDossierEndpoint() +
            '/' +
            this.props.dossier.id +
            '/archive'
        this.setState({ archiving: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) => {
                let dossier = this.props.dossier
                dossier.archived = true
                this.setState({ dossier: dossier, archiving: false })
            })
    }

    dearchive() {
        let url =
            this.props.kedo.api().getContentDossierEndpoint() +
            '/' +
            this.props.dossier.id +
            '/dearchive'
        this.setState({ archiving: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) => {
                let dossier = this.props.dossier
                dossier.archived = false
                this.setState({ dossier: dossier, archiving: false })
            })
    }

    render() {
        if (this.props.dossier.archived === true) {
            return (
                <Button
                    onClick={this.dearchive}
                    variant="outline-primary"
                    title="De-archiveren"
                    className={'active'}
                >
                    {this.state.archiving === true ? (
                        <LoadingDefault as={'span'} size={'sm'} />
                    ) : (
                        <FontAwesomeIcon icon={faFolder} />
                    )}
                </Button>
            )
        }

        return (
            <Button
                size={this.props.size}
                onClick={this.archive}
                variant="outline-primary"
                title="Archiveren"
            >
                {this.state.archiving === true ? (
                    <LoadingDefault as={'span'} size={'sm'} />
                ) : (
                    <FontAwesomeIcon icon={faFolder} />
                )}
            </Button>
        )
    }
}

export default ContentDossierArchive
