import React, {Component} from 'react'
import {Form} from "react-bootstrap";
import LoadingDefault from "../../../Elements/Loading/LoadingDefault";

class BlockSortSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sortSettings: this.props.sort ? this.props.sort : [],
            ddis: [],
        };
        this.changeSort = this.changeSort.bind(this);
    }

    changeSort(e, ddiItem) {
        let sortSettings = this.state.sortSettings.filter(item => ddiItem.def_dossier_def_field && parseInt(item.dddfId) !== ddiItem.def_dossier_def_field.id);

        if (e.target.value) {
            sortSettings.push({dddfId: ddiItem.def_dossier_def_field.id, sort: e.target.value});
        }

        this.setState({sortSettings: sortSettings});
        this.props.onChangeSort(sortSettings);
    }

    fetchListItems() {
        const api = this.props.kedo.api();
        this.setState({loading: true});
        api.get(api.getDisplayItemEndpoint(), {params: {
            defDossier: this.props.defDossierId,
            view: this.props.viewName ? this.props.viewName : 'list',
            limit: 500
        }}).then(response => this.setState({
            loading: false,
            ddis: response.data.results.filter(item => item.def_dossier_def_field)
        }))
    }

    componentDidMount() {
        this.fetchListItems();
    }

    render() {
        const kedo = this.props.kedo;

        return  <div>
            <h3>{kedo.t('Default sorting')}</h3>
            {this.state.loading ? <LoadingDefault /> : null}
            <table className={'table table-striped'}>
                <tbody>
                    {this.state.ddis.map(ddiItem => {
                        let existingVal = this.state.sortSettings.find(item => parseInt(item.dddfId) === ddiItem.def_dossier_def_field.id);

                        return <tr key={`sort-${ddiItem.id}`}>
                        <td>{kedo.env().translateItem(ddiItem, 'displayitem')}</td>
                        <td>
                            <Form.Control value={existingVal ? existingVal.sort : ''} onChange={(e) => this.changeSort(e, ddiItem)} name={`sort-${ddiItem.id}`} key={`dd-sort-${ddiItem.id}`} as={'select'}>
                                <option value={''}>{kedo.t('Choose an option')}</option>
                                <option value={'ASC'}>{kedo.t('ASC')}</option>
                                <option value={'DESC'}>{kedo.t('DESC')}</option>
                            </Form.Control>
                        </td>
                    </tr>})}
                </tbody>
            </table>
        </div>
    }
}

export default BlockSortSettings