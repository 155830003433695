import React, { Component } from 'react'
import LoadingDefault from './Loading/LoadingDefault'
import {
    Alert,
    Button,
    ButtonGroup,
    FormControl,
    InputGroup,
    ListGroup,
    Modal,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExclamationCircle,
    faPenAlt,
    faPlus,
    faSyncAlt,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'

export default class SelectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentItem: null,
            title: this.props.title ? this.props.title : null,
            searchName: this.props.searchName ? this.props.searchName : null,
            searchVal: '',
            url: this.props.url ? this.props.url : null,
            urlParams: this.props.urlParams ? this.props.urlParams : null,
            loading: false,
            error: false,
            loadingName: false,
            page: 0,
            hasNext: false,
            limit: 10,
            items: [],
        }

        this.onKeyUp = this.onKeyUp.bind(this)
        this.updateCurrentItem = this.updateCurrentItem.bind(this)
    }

    fetchItems(fetchNext, resetSearch) {
        if (!this.state.url) {
            return
        }

        this.setState({ loading: true, error: false })
        let params = {
            params: {
                limit: this.state.limit,
                page: fetchNext ? this.state.page + 1 : this.state.page,
            },
        }

        if (
            this.state.searchName &&
            (this.state.searchVal.length > 0 || resetSearch)
        ) {
            params.params[this.state.searchName] = this.state.searchVal
            params.params.page = 0
        }

        if (this.props.urlParams) {
            params.params = Object.assign(
                {},
                params.params,
                this.props.urlParams
            )
        }

        let url = this.state.url
        this.props.kedo
            .api()
            .get(url, params)
            .then((response) => {
                this.setState({
                    loading: false,
                    items: fetchNext
                        ? [...this.state.items, ...response.data.results]
                        : response.data.results,
                    page: response.data.pager.current,
                    hasNext:
                        response.data.pager.pages > response.data.pager.current,
                })
            })
            .catch(() => {
                this.setState({ loading: false, error: true })
            })
    }

    onKeyUp(event) {
        if (!event.target.value || event.target.value === '') {
            return
        }

        if (this.state.timeout) clearTimeout(this.state.timeout)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.timeout = setTimeout(() => {
            this.fetchItems(false)
        }, 300)
    }

    renderSearch() {
        return (
            <li className={'list-group-item'}>
                <InputGroup>
                    <FormControl
                        className="form-control"
                        name={'searchVal'}
                        onChange={(event) =>
                            this.setState({ searchVal: event.target.value })
                        }
                        onKeyUp={this.onKeyUp}
                        placeholder={this.props.kedo.t('Search') + '...'}
                        type="text"
                        value={this.state.searchVal}
                    />
                    <InputGroup.Append>
                        {this.state.searchName.length > 0 &&
                        this.state.searchVal.length > 0 ? (
                            <button
                                onClick={() => {
                                    this.setState(
                                        { searchVal: '', page: 0, items: [] },
                                        () => this.fetchItems(false, true)
                                    )
                                }}
                                className="btn bg-transparent"
                                style={{ marginLeft: '-40px', zIndex: 100 }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        ) : null}
                    </InputGroup.Append>
                    {this.props.addComponent ? (
                        <Button
                            onClick={() => this.setState({ showAdd: true })}
                            className={'ml-2'}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Add
                        </Button>
                    ) : null}
                </InputGroup>
            </li>
        )
    }

    renderLoadMoreAndFooter() {
        if (!this.state.hasNext) {
            return
        }

        return (
            <ListGroup.Item
                variant={'info'}
                onClick={() => this.fetchItems(true)}
            >
                <i>{this.props.kedo.t('Load more')}...</i>
            </ListGroup.Item>
        )
    }

    getDisplayName(item) {
        if (this.props.onDisplayName) {
            return this.props.onDisplayName(item)
        }

        return item.name
    }

    updateCurrentItem(currentId, callBack) {
        if (!currentId || this.state.loadingName) {
            return
        }

        let url = this.props.url + '/' + currentId
        this.setState({ loadingName: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState(
                    {
                        loadingName: false,
                        currentItem: response.data,
                    },
                    callBack ? () => callBack(response.data) : null
                )
            )
    }

    componentDidMount() {
        if (!this.props.currentValue && !this.props.disableInit) {
            this.fetchItems(false)
        }

        if (this.props.currentValue && !this.state.currentItem) {
            this.updateCurrentItem(
                this.props.currentValue,
                this.props.onUpdateCurrentItem
            )
        }
    }

    render() {
        const kedo = this.props.kedo

        if (this.props.location === 'TemplateForm') {
            return (
                <Modal.Body>
                    {this.state.error ? (
                        <Alert variant={'warning'}>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                            &nbsp; {this.props.kedo.t(
                                'Something went wrong'
                            )}{' '}
                            <Button
                                className={'float-right'}
                                style={{ marginTop: '-8px' }}
                                variant={'warning'}
                                onClick={() => this.fetchItems(false)}
                            >
                                <FontAwesomeIcon icon={faSyncAlt} />
                                &nbsp; {this.props.kedo.t('Retry')}
                            </Button>
                        </Alert>
                    ) : null}
                    <ListGroup variant="flush">
                        {this.state.searchName ? this.renderSearch() : null}
                        {this.state.loading ? (
                            <ListGroup.Item>
                                <LoadingDefault />
                            </ListGroup.Item>
                        ) : null}
                        {this.state.items.map((item) => (
                            <ListGroup.Item
                                action
                                key={item.id}
                                onClick={() => {
                                    this.props.onSelect(item)
                                    this.updateCurrentItem(item.id)
                                }}
                            >
                                {this.getDisplayName(item)}
                            </ListGroup.Item>
                        ))}
                        {this.renderLoadMoreAndFooter()}
                    </ListGroup>
                </Modal.Body>
            )
        }

        return (
            <div>
                {this.state.title ? <h2>{this.state.title}</h2> : null}
                {this.state.showAdd
                    ? this.props.addComponent(
                          (newItemId) => {
                              this.updateCurrentItem(
                                  newItemId,
                                  this.props.onSelect
                              )
                              this.setState({ showAdd: false })
                          },
                          () => this.setState({ showAdd: false })
                      )
                    : null}
                {this.state.showEdit
                    ? this.props.editComponent(() =>
                          this.setState({ showEdit: false })
                      )
                    : null}
                <ButtonGroup size={'sm'}>
                    {this.props.currentValue && this.props.editComponent ? (
                        <Button
                            title={kedo.t('Edit')}
                            className={'mr-1'}
                            onClick={() => this.setState({ showEdit: true })}
                        >
                            <FontAwesomeIcon icon={faPenAlt} />
                        </Button>
                    ) : null}
                    {this.props.currentValue ? (
                        <Button
                            className={'badge badge-secondary inline-block'}
                            variant={'secondary'}
                            disabled
                            readOnly
                        >
                            {this.state.currentItem
                                ? this.props.onDisplayName(
                                      this.state.currentItem
                                  )
                                : this.props.currentValue}
                        </Button>
                    ) : null}
                    {this.props.currentValue ? (
                        <Button
                            title={kedo.t('Clear')}
                            onClick={() =>
                                this.setState(
                                    { currentItem: null },
                                    this.props.onClear
                                )
                            }
                            className={'badge badge-secondary inline-block'}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    ) : this.props.currentValue ? (
                        <Button
                            className={'badge badge-secondary'}
                            onClick={this.props.onClear}
                        >
                            {this.state.loadingName ? <LoadingDefault /> : null}
                            {this.props.currentValue}{' '}
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    ) : null}
                </ButtonGroup>
                {this.state.error ? (
                    <Alert variant={'warning'}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        &nbsp; {this.props.kedo.t('Something went wrong')}{' '}
                        <Button
                            className={'float-right'}
                            style={{ marginTop: '-8px' }}
                            variant={'warning'}
                            onClick={() => this.fetchItems(false)}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} />
                            &nbsp; {this.props.kedo.t('Retry')}
                        </Button>
                    </Alert>
                ) : null}
                {!this.props.currentValue ? (
                    <ListGroup variant="flush">
                        {this.state.searchName ? this.renderSearch() : null}
                        {this.state.loading ? (
                            <ListGroup.Item>
                                <LoadingDefault />
                            </ListGroup.Item>
                        ) : null}
                        {this.state.items.map((item) => (
                            <ListGroup.Item
                                action
                                key={item.id}
                                onClick={() => {
                                    this.props.onSelect(item)
                                    this.updateCurrentItem(item.id)
                                }}
                            >
                                {this.getDisplayName(item)}
                            </ListGroup.Item>
                        ))}
                        {this.renderLoadMoreAndFooter()}
                    </ListGroup>
                ) : null}
            </div>
        )
    }
}
