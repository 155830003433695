import React from 'react'
import NotFound from '../Pages/NotFound'
import { Button, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-duotone-svg-icons'
import { Trans } from 'react-i18next'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            errorInfo: null,
            hasError: false,
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
    }

    async clearCachesAndRefresh() {
        if (caches) {
            const names = await caches.keys()
            await Promise.all(names.map((name) => caches.delete(name)))
        }
        window.location.reload(true)
    }

    render() {
        const { hasError, error, errorInfo } = this.state

        if (
            hasError &&
            this.state.error &&
            this.state.error.toString().toLowerCase().indexOf('chunk') !== -1
        ) {
            return (
                <div
                    className="mainContainer"
                    style={{ justifyContent: 'flex-start' }}
                >
                    <Row className={'d-flex align-items-center'}>
                        <div>
                            <h1>
                                <Trans i18nKey="New release">New release</Trans>
                            </h1>
                            <p>
                                <Trans i18nKey="new_release_description">
                                    There is a new release please click on the
                                    button to refresh or hit (Ctrl + F5) or
                                    (Ctrl + Shift + R).
                                </Trans>
                            </p>
                            <div>
                                <Button
                                    onClick={this.clearCachesAndRefresh}
                                    variant={'primary'}
                                >
                                    <FontAwesomeIcon icon={faSync} />
                                </Button>{' '}
                                &nbsp;
                                <Button
                                    onClick={() => window.location.reload(true)}
                                    variant={'primary'}
                                >
                                    <FontAwesomeIcon icon={faSync} />
                                </Button>{' '}
                                &nbsp;
                            </div>
                        </div>
                    </Row>
                </div>
            )
        }

        return hasError ? (
            <NotFound
                {...this.props}
                errorType={'dossier'}
                error={this.state.error}
                errorInfo={this.state.errorInfo}
            />
        ) : (
            this.props.children
        )
    }
}
