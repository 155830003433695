import React, { Component } from 'react'
import {
    Alert,
    Button,
    Form,
    Modal,
    Table,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle,
    faExclamationCircle,
    faFileCsv,
    faFileDownload,
    faFileExport,
    faFilePdf,
    faFileWord,
    faInfoCircle,
    faPlay,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from 'react-csv'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'
import DefaultSearchbar from '../../../Elements/Search/DefaultSearchbar'

class ContentDossierExport extends Component {
    constructor(props) {
        super(props)

        this.state = {
            csvData: [],
            csvHeaders: [],
            fields: [],
            metaFields: [
                { id: 'created_by', label: 'created_by' },
                { id: 'created_at', label: 'created_at' },
                { id: 'modified_by', label: 'modified_by' },
                { id: 'modified_at', label: 'modified_at' },
            ],
            fileType: null,
            kedocx: [],
            loading: false,
            selectedFields: [],
            running: false,
            finished: false,
            total: 0,
            done: 0,
            search: '',
            showCSVModal: false,
            showExportSuccess: false,
        }

        this.fetchAllItems = this.fetchAllItems.bind(this)
        this.fetchSingularItems = this.fetchSingularItems.bind(this)
        this.getAllData = this.getAllData.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handleSearchFields = this.handleSearchFields.bind(this)
        this.handleSearchKedocx = this.handleSearchKedocx.bind(this)
        this.onChangeFields = this.onChangeFields.bind(this)
        this.onClose = this.onClose.bind(this)
        this.selectAllFields = this.selectAllFields.bind(this)
        this.renderCsvSettings = this.renderCsvSettings.bind(this)
        this.resetButtonFields = this.resetButtonFields.bind(this)
        this.resetButtonKedocx = this.resetButtonKedocx.bind(this)
        this.isAllowed = this.isAllowed.bind(this)
    }

    getMultiContent(multiContent) {
        if (multiContent.length <= 0) {
            return ''
        }

        // User field
        if (multiContent[0].team && multiContent[0].text) {
            return Array.prototype.map
                .call(multiContent, function (item) {
                    return item.text
                })
                .join('|')
        }

        // Link field
        if (multiContent[0].summary) {
            return Array.prototype.map
                .call(multiContent, function (item) {
                    return item.summary
                })
                .join('|')
        } else if (multiContent[0].translations) {
            return Array.prototype.map
                .call(multiContent, function (item) {
                    return item.translations[0].text
                })
                .join('|')
        }
        return ''
    }

    fetchKedocx() {
        let url = this.props.kedo.api().getKedocxEndpoint()
        let filters = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                defDossier: this.props.defDossierId,
            },
        }

        if (this.props.dossier && this.props.dossier.def_dosssier_id) {
            filters.params.defDossier = this.props.dossier.def_dosssier_id
        }

        if (this.state.search !== null && this.state.search.length > 0) {
            filters.params.search = this.state.search
        }

        this.setState({ loading: true })

        this.props.kedo
            .api()
            .get(url, filters)
            .then((response) =>
                this.setState({
                    kedocx: response.data.results,
                    loading: false,
                })
            )
    }

    fetchFields() {
        if (this.state.loading) {
            return
        }

        const kedo = this.props.kedo

        this.setState({ loading: true })

        let params = {
            params: {
                displayPosition: 'main_content',
                defDossier: this.props.defDossierId,
            },
        }

        if (this.state.search !== null && this.state.search.length > 0) {
            params.params.search = this.state.search
        }

        kedo.api()
            .get(kedo.api().getDisplayItemEndpoint(), params)
            .then((response) => {
                this.setState({
                    fields: response.data.results,
                    loading: false,
                })
            })
    }

    async getAllData() {
        let filter = this.props.filter()
        filter.page = -1
        this.setState({ running: true })
        await this.fetchAllItems(filter, null, true)
    }

    async fetchAllItems(filter, headers, first) {
        if (this.props.all === false || !this.props.filter) {
            return (
                <Alert variant={'danger'}>
                    {this.props.kedo.t('Something went wrong')}
                </Alert>
            )
        }
        const kedo = this.props.kedo
        filter.page = filter.page + 1
        const response = await kedo
            .api()
            .get(kedo.api().getContentDossierEndpoint(), { params: filter })
        let csvData = this.state.csvData

        if (first === true) {
            this.setState({ total: response.data.pager.items })
        }

        if (!headers) {
            headers = this.state.selectedFields
            let headerRow = []
            headerRow.push({ key: 'content_dossier_id', label: 'dossier_id' })
            headers.map((item) => {
                headerRow.push({
                    key: item.id.toString(),
                    label: kedo.env().translateItem(item, 'displayitem'),
                })
            })
            headerRow.push({ key: 'created_by', label: 'created_by' })
            headerRow.push({ key: 'created_at', label: 'created_at' })
            headerRow.push({ key: 'modified_by', label: 'modified_by' })
            headerRow.push({ key: 'modified_at', label: 'modified_at' })
            this.setState({ csvHeaders: headerRow })
        }

        //let selectedDossiers = response.data.results.dossiers.map(dossier => dossier.id);
        let counter = 0

        const forLoop = async (_) => {
            for (
                let index = 0;
                index < response.data.results.dossiers.length;
                index++
            ) {
                let listDossier = response.data.results.dossiers[index]
                const contentDossierResponse = await kedo
                    .api()
                    .get(
                        kedo.api().getContentDossierEndpoint() +
                            '/' +
                            listDossier.id
                    )
                let dossierContent = contentDossierResponse.data.content
                let dossierRow = {}
                dossierRow['content_dossier_id'] = listDossier.id
                headers.map((header) => {
                    let contentItem = dossierContent[header.id]
                    let date = false

                    if (
                        kedo.utils().dateFormat(contentItem) &&
                        contentItem.length === 25
                    ) {
                        date = kedo.utils().dateFormat(contentItem)
                    }

                    if (contentItem) {
                        if (Array.isArray(contentItem)) {
                            dossierRow[header.id.toString()] =
                                this.getMultiContent(contentItem)
                        } else {
                            dossierRow[header.id.toString()] = date
                                ? date
                                : contentItem.replace(/"/g, '""')
                        }
                    } else {
                        dossierRow[header.id.toString()] = ''
                    }
                })
                dossierRow['created_by'] =
                    listDossier.created_by && listDossier.created_by.username
                        ? listDossier.created_by.username
                        : ''
                dossierRow['created_at'] = listDossier.created_at
                    ? listDossier.created_at
                    : ''
                dossierRow['modified_by'] =
                    listDossier.modified_by && listDossier.modified_by.username
                        ? listDossier.modified_by.username
                        : ''
                dossierRow['modified_at'] = listDossier.modified_at
                    ? listDossier.modified_at
                    : ''
                csvData.push(dossierRow)
                counter = counter + 1

                this.setState({ done: this.state.done + 1 })
            }
        }
        await forLoop()

        if (response.data.pager.current >= response.data.pager.pages) {
            this.setState({
                running: false,
                csvData: csvData,
                finished: true,
            })
        } else {
            this.setState({
                csvData: csvData,
            })
            await this.fetchAllItems(filter, headers)
        }
    }

    fetchSingularItems(filter, headers) {
        this.setState({ running: true })

        let csvData = this.state.csvData
        if (!headers) {
            headers = this.state.selectedFields

            let headerRow = []
            headers.map((item) => {
                headerRow.push({
                    key: item.id.toString(),
                    label: this.props.kedo
                        .env()
                        .translateItem(item, 'displayitem'),
                })
            })

            this.setState({ csvHeaders: headerRow })
        }

        let selectedDossiers = this.props.selected
        let x
        let allSelectedDossiers = selectedDossiers.length
        let counter = 0

        for (x of selectedDossiers) {
            this.props.kedo
                .api()
                .get(
                    this.props.kedo.api().getContentDossierEndpoint() + '/' + x
                )
                .then((response) => {
                    let dossierContent = response.data.content
                    let dossierItem = response.data.dossier
                    let dossierRow = {}

                    headers.map((header) => {
                        let contentItem = dossierContent[header.id]
                        if (contentItem) {
                            if (Array.isArray(contentItem)) {
                                dossierRow[header.id.toString()] =
                                    this.getMultiContent(contentItem)
                            } else {
                                dossierRow[header.id.toString()] = contentItem
                            }
                        } else {
                            let metaField = this.state.metaFields.find(
                                (item) => item.id === header.id
                            )
                            if (metaField) {
                                dossierRow[metaField.id] =
                                    typeof dossierItem[metaField.id] == 'object'
                                        ? dossierItem[metaField.id].username
                                        : dossierItem[metaField.id]
                            } else {
                                dossierRow[header.id.toString()] = ''
                            }
                        }
                    })

                    csvData.push(dossierRow)
                    counter = counter + 1

                    if (counter === allSelectedDossiers) {
                        this.setState({
                            running: false,
                            csvData: csvData,
                            finished: true,
                        })
                    }
                })
        }
    }

    isAllowed(credential) {
        return this.props.kedo
            .env()
            .isAllowedDefDossier(credential, this.props.defDossierId)
    }

    runExport(fileType) {
        if (!this.props.selected) {
            return
        }

        let extra = '?contentDossierIds[]=' + this.props.selected
        let type = '&outputType=' + fileType
        let baseUrl = this.props.kedo.api().getBaseUrl()
        window.open(
            baseUrl + '/kedocx/generate/' + this.state.selected + extra + type
        )

        this.setState({ showExportSuccess: true })
        setTimeout(() => {
            this.setState({ showExportSuccess: false })
        }, 10000)
    }

    renderSuccessAlert() {
        return (
            <Alert key={'success'} variant={'success'}>
                <FontAwesomeIcon icon={faCheckCircle} />
                &nbsp; {this.props.kedo.t('export_succesfully')}
                <FontAwesomeIcon
                    icon={faTimes}
                    className={'float-right'}
                    onClick={() => this.setState({ showExportSuccess: false })}
                    style={{ marginTop: '5px' }}
                />
            </Alert>
        )
    }

    renderCsvSettings() {
        let total = this.state.total
        var done = this.state.done
        let perc = 0
        perc = ((done / total) * 100).toFixed(3)

        return (
            <div>
                {this.props.all ||
                (this.props.selected && this.props.selected.length > 3) ? (
                    <Alert variant={'info'}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        &nbsp; {this.props.kedo.t('export_all_dossiers')}
                    </Alert>
                ) : null}
                {this.getFields()}
                {this.state.running && !this.state.finished ? (
                    <div className="progress">
                        <div
                            className="progress-bar"
                            style={{ width: perc.toString() + '%' }}
                            role="progressbar"
                            aria-valuenow={perc}
                            aria-valuemin="0"
                            aria-valuemax={this.state.total}
                        >
                            {this.state.done} / {this.state.total}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }

    handleFilterChange(event) {
        this.setState({
            search: event.target.value,
        })
    }

    handleSearchFields(event) {
        if (event.key !== 'Enter') {
            return
        }

        this.setState(
            { search: event.target.value.toString() },
            this.fetchFields
        )
    }

    handleSearchKedocx(event) {
        if (event.key !== 'Enter') {
            return
        }

        this.setState(
            { search: event.target.value.toString() },
            this.fetchKedocx
        )
    }

    resetButtonFields() {
        return (
            <button
                onClick={() => {
                    this.setState({ search: '', fields: [] }, this.fetchFields)
                }}
                className="btn bg-transparent resetButton"
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
        )
    }

    resetButtonKedocx() {
        return (
            <button
                onClick={() => {
                    this.setState({ search: '', fields: [] })
                    setTimeout(() => this.fetchKedocx(), 100)
                }}
                className="btn bg-transparent resetButton"
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
        )
    }

    getFields() {
        return (
            <Form.Group>
                <Form.Label column={true}>
                    <h5>{this.props.kedo.t('Fields to export')}</h5>
                </Form.Label>
                <DefaultSearchbar
                    kedo={this.props.kedo}
                    handleChange={this.handleFilterChange}
                    handleSearch={this.handleSearchFields}
                    searchName={this.state.search}
                    resetButton={this.resetButtonFields}
                />
                {!this.state.loading &&
                (!this.state.fields || this.state.fields.length <= 0) ? (
                    <Alert variant={'warning'}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        &nbsp; {this.props.kedo.t('No fields found')}
                    </Alert>
                ) : null}
                {this.state.loading ? (
                    <LoadingDefault />
                ) : (
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check
                                        checked={
                                            this.state.selectedFields.length ===
                                            this.state.fields.length +
                                                this.state.metaFields.length
                                        }
                                        label={
                                            <strong>
                                                {this.props.kedo.t(
                                                    'Select all'
                                                )}
                                            </strong>
                                        }
                                        onChange={(event) =>
                                            this.selectAllFields(event)
                                        }
                                        type="checkbox"
                                    />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.fields.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <Form.Check
                                            size={'lg'}
                                            checked={this.state.selectedFields.find(
                                                (field) => field.id === item.id
                                            )}
                                            label={this.props.kedo.translateItem(
                                                item,
                                                'displayitem'
                                            )}
                                            onChange={(event) =>
                                                this.onChangeFields(event)
                                            }
                                            type="checkbox"
                                            value={item.id}
                                        />
                                    </td>
                                    <td>
                                        <small>{this.getItemMode(item)}</small>
                                    </td>
                                </tr>
                            ))}
                            {this.state.metaFields.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <Form.Check
                                            size={'lg'}
                                            checked={this.state.selectedFields.find(
                                                (field) => field.id === item.id
                                            )}
                                            onChange={(event) =>
                                                this.onChangeFields(event)
                                            }
                                            label={item.label}
                                            type="checkbox"
                                            value={item.id}
                                        />
                                    </td>
                                    <td>
                                        <small>Metadata</small>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Form.Group>
        )
    }

    getItemMode(item) {
        let view = this.props.kedo.t('Unknown')

        if (item.view === 'show') {
            view = this.props.kedo.t('Show')
        }

        if (item.view === 'show/edit') {
            view = this.props.kedo.t('Show') + '/' + this.props.kedo.t('Edit')
        }

        return view
    }

    onChangeFields(event) {
        if (this.state.finished) {
            this.setState({ finished: false })
        }

        let fields = this.state.selectedFields
        let fieldId = parseInt(event.target.value)
        let checked = event.target.checked

        fields = fields.filter(
            (item) => item.id !== fieldId && item.id !== event.target.value
        )

        if (checked) {
            if (!fieldId) {
                fields.push(
                    this.state.metaFields.find(
                        (item) => item.id === event.target.value
                    )
                )
            } else {
                fields.push(
                    this.state.fields.find((item) => item.id === fieldId)
                )
            }
        }

        this.setState({ selectedFields: fields })
    }

    selectAllFields(event) {
        if (this.state.finished) {
            this.setState({ finished: false })
        }

        let fields = []
        let checked = event.target.checked

        if (checked) {
            this.state.fields.map((item) => fields.push(item))
            this.state.metaFields.map((item) => fields.push(item))
            this.setState({ selectedFields: fields })
        } else {
            this.setState({ selectedFields: [] })
        }
    }

    onClose() {
        this.setState({
            csvHeaders: [],
            csvData: [],
            fileType: null,
            kedocx: [],
            search: '',
            selectedFields: [],
            showCSVModal: false,
            showFullModal: false,
        })
    }

    toggleSettings() {
        if (this.state.fileType === 'csv') {
            return this.renderCsvSettings()
        } else if (
            this.state.fileType === 'pdf' ||
            this.state.fileType === 'docx'
        ) {
            return this.renderKedocxs()
        }
    }

    onChangeKedocx(event) {
        this.setState({ selected: event.target.value })
    }

    renderKedocxs() {
        if (this.state.loading) {
            return <LoadingDefault />
        }

        return (
            <div>
                <br />
                <h5>{this.props.kedo.t('Select Kedocx')}</h5>
                <DefaultSearchbar
                    kedo={this.props.kedo}
                    handleChange={this.handleFilterChange}
                    handleSearch={this.handleSearchKedocx}
                    searchName={this.state.search}
                    resetButton={this.resetButtonKedocx}
                />
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>&nbsp;</th>
                            <th style={{ width: '5%' }}>#</th>
                            <th>{this.props.kedo.t('Name')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.kedocx && this.state.kedocx.length > 0 ? (
                            this.state.kedocx.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <input
                                            checked={
                                                parseInt(
                                                    this.state.selected
                                                ) === item.id
                                            }
                                            onChange={(event) =>
                                                this.onChangeKedocx(event)
                                            }
                                            type={'radio'}
                                            value={item.id}
                                        />
                                    </td>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">
                                    <Alert variant={'warning'}>
                                        <FontAwesomeIcon
                                            icon={faExclamationCircle}
                                        />
                                        &nbsp;{' '}
                                        {this.props.kedo.t('No files found')}
                                    </Alert>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <br />
                {this.state.fileType ? (
                    <Button
                        onClick={() => this.runExport(this.state.fileType)}
                        readOnly={this.state.selected}
                        variant={'primary'}
                    >
                        <FontAwesomeIcon icon={faFileExport} />
                        &nbsp; {this.props.kedo.t('Export')}
                    </Button>
                ) : null}
            </div>
        )
    }

    render() {
        const kedo = this.props.kedo
        if (this.state.showCSVModal) {
            return (
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    size={'lg'}
                    show={true}
                    onHide={this.onClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.all
                                ? kedo.t('Export all files')
                                : kedo.t('export_selected')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showExportSuccess
                            ? this.renderSuccessAlert()
                            : null}
                        {this.renderCsvSettings()}
                    </Modal.Body>
                    <Modal.Footer>
                        {!this.state.running && !this.state.finished ? (
                            <Button
                                onClick={
                                    this.props.selected &&
                                    this.props.selected.length > 0
                                        ? this.fetchSingularItems
                                        : this.getAllData
                                }
                                variant={'primary'}
                            >
                                <FontAwesomeIcon icon={faPlay} />
                                &nbsp; {kedo.t('Prepare file')}
                            </Button>
                        ) : null}
                        {this.state.finished ? (
                            <CSVLink
                                enclosingCharacter={'"'}
                                data={this.state.csvData}
                                filename={`${this.props.defDossierId}_${
                                    this.props.defDossier
                                        ? this.props.kedo.translateItem(
                                              this.props.defDossier,
                                              'defdossier',
                                              true
                                          )
                                        : 'export'
                                }_${Date.now()}.csv`}
                                headers={this.state.csvHeaders}
                            >
                                <Button variant={'primary'}>
                                    <FontAwesomeIcon icon={faFileDownload} />
                                    &nbsp; {kedo.t('Download')}
                                </Button>
                            </CSVLink>
                        ) : null}
                        <Button
                            onClick={() =>
                                this.setState({
                                    showCSVModal: false,
                                    fileType: null,
                                    selectedFields: [],
                                })
                            }
                            variant={'secondary'}
                            title={'Cancel'}
                        >
                            {kedo.t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        if (this.state.showFullModal) {
            return (
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    size={'lg'}
                    show={true}
                    onHide={this.onClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{kedo.t('export_selected')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showExportSuccess
                            ? this.renderSuccessAlert()
                            : null}
                        <h4>{kedo.t('Select type')}</h4>
                        {this.isAllowed('print') && (
                            <>
                                <Button
                                    variant={'outline-secondary'}
                                    onClick={() => {
                                        this.setState({
                                            fileType: 'pdf',
                                            search: '',
                                        })
                                        this.fetchKedocx()
                                    }}
                                    active={this.state.fileType === 'pdf'}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                    &nbsp; PDF
                                </Button>
                                &nbsp;
                                <Button
                                    variant={'outline-secondary'}
                                    onClick={() => {
                                        this.setState({
                                            fileType: 'docx',
                                            search: '',
                                        })
                                        this.fetchKedocx()
                                    }}
                                    active={this.state.fileType === 'docx'}
                                >
                                    <FontAwesomeIcon icon={faFileWord} />
                                    &nbsp; Word (.docx)
                                </Button>
                                &nbsp;
                            </>
                        )}

                        {this.isAllowed('export') ? (
                            <Button
                                variant={'outline-secondary'}
                                onClick={() => {
                                    this.setState({
                                        fileType: 'csv',
                                        search: '',
                                        kedocx: [],
                                    })
                                    this.fetchFields()
                                }}
                                active={this.state.fileType === 'csv'}
                            >
                                <FontAwesomeIcon icon={faFileCsv} />
                                &nbsp; Excel (CSV)
                            </Button>
                        ) : null}
                        <br />
                        <br />
                        {this.toggleSettings()}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.fileType === 'csv' &&
                        !this.state.running &&
                        !this.state.finished ? (
                            <Button
                                onClick={
                                    this.props.selected &&
                                    this.props.selected.length > 0
                                        ? this.fetchSingularItems
                                        : this.getAllData
                                }
                                variant={'primary'}
                            >
                                <FontAwesomeIcon icon={faPlay} />
                                &nbsp; {kedo.t('Prepare file')}
                            </Button>
                        ) : null}
                        {this.state.fileType === 'csv' &&
                        this.state.finished ? (
                            <CSVLink
                                data={this.state.csvData}
                                filename={`${this.props.defDossierId}_${
                                    this.props.defDossier
                                        ? this.props.kedo.translateItem(
                                              this.props.defDossier,
                                              'defdossier',
                                              true
                                          )
                                        : 'export'
                                }_${Date.now()}.csv`}
                                headers={this.state.csvHeaders}
                            >
                                <Button variant={'primary'}>
                                    <FontAwesomeIcon icon={faFileDownload} />
                                    &nbsp; {kedo.t('Download')}
                                </Button>
                            </CSVLink>
                        ) : null}
                        <Button
                            onClick={this.onClose}
                            variant={'secondary'}
                            title={'Cancel'}
                        >
                            {kedo.t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        if (this.props.mode === 'all') {
            return (
                <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip>{kedo.t('CSV Export')}</Tooltip>}
                >
                    <Button
                        size={this.props.size}
                        onClick={() => {
                            this.setState({ showCSVModal: true })
                            this.fetchFields()
                        }}
                        title="Export"
                        variant={'outline-secondary'}
                    >
                        <FontAwesomeIcon icon={faFileExport} />
                    </Button>
                </OverlayTrigger>
            )
        } else if (this.props.mode === 'dossier') {
            return (
                <Button
                    size={this.props.size}
                    onClick={() => this.setState({ showFullModal: true })}
                    variant="outline-primary"
                    title="Export"
                >
                    <FontAwesomeIcon icon={faFileExport} />
                </Button>
            )
        } else if (this.props.selected.length === 1) {
            return (
                <Button
                    size={this.props.size}
                    onClick={() => this.setState({ showFullModal: true })}
                    title="Export"
                    variant={'outline-secondary'}
                >
                    <FontAwesomeIcon icon={faFileExport} />
                    &nbsp; {kedo.t('Export')}
                </Button>
            )
        } else {
            return (
                <Button
                    size={this.props.size}
                    onClick={() => {
                        this.setState({ showCSVModal: true })
                        this.fetchFields()
                    }}
                    title="Export"
                    variant={'outline-secondary'}
                >
                    <FontAwesomeIcon icon={faFileCsv} />
                    &nbsp; {kedo.t('CSV Export')}
                </Button>
            )
        }
    }
}

export default ContentDossierExport
