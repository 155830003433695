import React from 'react'
import {
    Tabs,
    Tab,
    Row,
    Col,
    Nav,
    Carousel,
    Card,
    Accordion,
} from 'react-bootstrap'
import Block from './Block'
import BlockCountList from './BlockCountList'
import BlockListPercentageOptionMatching from './BlockListPercentageOptionMatching'
import BlockSumFields from './BlockSumFields'
import ContentDossierStatistics from './ContentDossierStatistics'
import UserMostActiveStatistics from './UserMostActiveStatistics'
import UserStatistics from './UserStatistics'
import BlockDateStatistics from './BlockDateStatistics'
import BlockCumulio from './BlockCumulio'
import BlockToolbar from './BlockToolbar'
import BlockCountLink from './BlockCountLink'

export default class BlockContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            block: this.props.block,
            blockSettings: this.props.block ? this.props.block.settings : null,
            eventKey: '0',
            showToolbar: '',
        }
        this.parRef = React.createRef()
    }

    getParentRef() {
        return this.parRef
    }

    renderBlock(item, kedo, settings) {
        let miscSettings = settings ? settings : {}
        let blockProps = {
            kedo: kedo,
            block: item,
            dashboard: true,
            container: true,
            //onClose: this.props.onClose,
        }

        switch (item.type) {
            case 'cumulio':
                return <BlockCumulio misc={miscSettings} {...blockProps} />
            case 'block_container':
                return <BlockContainer misc={miscSettings} {...blockProps} />
            case 'dashboard':
                return <Block misc={miscSettings} {...blockProps} />
            case 'count_list_options':
                return <BlockCountList misc={miscSettings} {...blockProps} />
            case 'list_option_percentage_conditions':
                return (
                    <BlockListPercentageOptionMatching
                        misc={miscSettings}
                        {...blockProps}
                    />
                )
            case 'count_link_options':
                return <BlockCountLink misc={miscSettings} {...blockProps} />
            case 'sum_fields':
                return <BlockSumFields misc={miscSettings} {...blockProps} />
            case 'new_dossier_statistics':
                return (
                    <ContentDossierStatistics
                        misc={miscSettings}
                        {...blockProps}
                    />
                )
            case 'active_users':
                return (
                    <UserMostActiveStatistics
                        misc={miscSettings}
                        {...blockProps}
                    />
                )
            case 'online_users':
                return <UserStatistics misc={miscSettings} {...blockProps} />
            case 'date_statistics':
                return (
                    <BlockDateStatistics misc={miscSettings} {...blockProps} />
                )
        }

        return ''
    }

    renderBlocks(blocks, kedo) {
        let layout =
            this.props.block.settings && this.props.block.settings.layout
                ? this.props.block.settings.layout
                : 'horizontal'

        if (layout === 'vertical') {
            return (
                <Tab.Container defaultActiveKey="0">
                    <Row>
                        <Col sm={3}>
                            <Nav
                                onSelect={(eventKey) => {
                                    this.setState({ eventKey: eventKey })
                                }}
                                variant="pills"
                                className="flex-column"
                            >
                                {blocks.map((blockItem, blockIndex) => (
                                    <Nav.Item key={blockItem.id}>
                                        <Nav.Link
                                            eventKey={blockIndex.toString()}
                                        >
                                            {kedo
                                                .env()
                                                .translateItem(
                                                    blockItem,
                                                    'mypageblock'
                                                )}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {blocks.map((blockItem, blockIndex) => (
                                    <Tab.Pane
                                        key={blockItem.id}
                                        eventKey={blockIndex.toString()}
                                        onMouseEnter={() =>
                                            this.setState({
                                                showToolbar: blockItem.id,
                                            })
                                        }
                                        onMouseLeave={() =>
                                            this.setState({
                                                showToolbar:
                                                    this.state.block.id,
                                            })
                                        }
                                        className={`${
                                            (this.state.showToolbar ==
                                                blockItem.id) &
                                            (blockItem.type !=
                                                'block_container')
                                                ? 'show-toolbar'
                                                : ''
                                        }`}
                                    >
                                        {this.state.eventKey ===
                                        blockIndex.toString()
                                            ? this.renderBlock(blockItem, kedo)
                                            : null}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            )
        } else if (layout === 'carousel') {
            return (
                <Carousel>
                    {blocks.map((blockItem) => (
                        <Carousel.Item
                            key={blockItem.id}
                            onMouseEnter={() =>
                                this.setState({
                                    showToolbar: blockItem.id,
                                })
                            }
                            onMouseLeave={() =>
                                this.setState({
                                    showToolbar: this.state.block.id,
                                })
                            }
                            className={`${
                                (this.state.showToolbar == blockItem.id) &
                                (blockItem.type != 'block_container')
                                    ? 'show-toolbar'
                                    : ''
                            }`}
                        >
                            <h3>
                                {kedo
                                    .env()
                                    .translateItem(blockItem, 'mypageblock')}
                            </h3>
                            {this.renderBlock(blockItem, kedo)}
                        </Carousel.Item>
                    ))}
                </Carousel>
            )
        } else if (layout === 'accordion') {
            return (
                <Accordion
                    defaultActiveKey={'0'}
                    onSelect={(eventKey) => {
                        this.setState({ eventKey: eventKey })
                    }}
                    activeKey={this.state.eventKey}
                    className={'container-accordion'}
                >
                    {blocks.map((blockItem, blockIndex) => (
                        <Card key={blockItem.id}>
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey={blockIndex.toString()}
                            >
                                {kedo
                                    .env()
                                    .translateItem(blockItem, 'mypageblock')}
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={blockIndex.toString()}
                            >
                                <Card.Body
                                    onMouseEnter={() =>
                                        this.setState({
                                            showToolbar: blockItem.id,
                                        })
                                    }
                                    onMouseLeave={() =>
                                        this.setState({
                                            showToolbar: this.state.block.id,
                                        })
                                    }
                                    className={`${
                                        (this.state.showToolbar ==
                                            blockItem.id) &
                                        (blockItem.type != 'block_container')
                                            ? 'show-toolbar'
                                            : ''
                                    }`}
                                >
                                    {this.state.eventKey ===
                                    blockIndex.toString()
                                        ? this.renderBlock(blockItem, kedo)
                                        : null}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            )
        }

        return (
            <Tabs
                onSelect={(eventKey) => {
                    this.setState({ eventKey: eventKey })
                }}
                defaultActiveKey="0"
                transition={false}
            >
                {blocks.map((blockItem, blockIndex) => (
                    <Tab
                        key={blockItem.id}
                        eventKey={blockIndex.toString()}
                        title={kedo
                            .env()
                            .translateItem(blockItem, 'mypageblock')}
                        onMouseEnter={() =>
                            this.setState({
                                showToolbar: blockItem.id,
                            })
                        }
                        onMouseLeave={() =>
                            this.setState({
                                showToolbar: this.state.block.id,
                            })
                        }
                        className={`${
                            (this.state.showToolbar == blockItem.id) &
                            (blockItem.type != 'block_container')
                                ? 'show-toolbar'
                                : ''
                        }`}
                    >
                        {this.state.eventKey === blockIndex.toString()
                            ? this.renderBlock(blockItem, kedo, {
                                  hideTitle: true,
                              })
                            : null}
                    </Tab>
                ))}
            </Tabs>
        )
    }
    render() {
        const kedo = this.props.kedo
        return (
            <div
                ref={this.parRef}
                className={`kedo-block-container ${
                    !this.props.dashboard ? 'kedo-block' : ''
                } ${
                    this.state.showToolbar == this.props.block.id
                        ? 'container-show-toolbar'
                        : ''
                }`}
                style={{
                    height: '300px',
                    marginBottom: '30px',
                    minHeight: '300px',
                    minWidth: '300px',
                }}
                onMouseEnter={() =>
                    this.setState({
                        showToolbar: this.props.block.id,
                    })
                }
                onMouseLeave={() =>
                    this.setState({
                        showToolbar: '',
                    })
                }
            >
                {' '}
                <div className="block-header">
                    <BlockToolbar
                        setFilterAndFetchData={this.setFilterAndFetchData}
                        onClose={this.props.onClose}
                        block={this.props.block}
                        onMax={this.props.onMax}
                        kedo={kedo}
                    />
                    {this.props.getBlockHeader
                        ? this.props.getBlockHeader(
                              this.props.block,
                              this.props.dossier
                          )
                        : null}
                </div>
                {this.renderBlocks(
                    this.props.block.children.sort((a, b) => {
                        if (a.rank < b.rank) {
                            return -1
                        }
                        if (a.rank > b.rank) {
                            return 1
                        }
                        // a must be equal to b
                        return 0
                    }),
                    kedo
                )}
            </div>
        )
    }
}
