import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import ConfirmDeleteDialog from '../../../Form/ConfirmDeleteDialog'

class ContentDossierDelete extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            DeleteDefDossierForm: false,
        }
    }

    render() {
        return (
            <span>
                {this.state.DeleteDefDossierForm ? (
                    <ConfirmDeleteDialog
                        kedo={this.props.kedo}
                        onClose={() =>
                            this.setState({ DeleteDefDossierForm: false })
                        }
                        url={
                            this.props.kedo.api().getContentDossierEndpoint() +
                            '/' +
                            this.props.dossier.id
                        }
                        onSuccess={
                            this.props.onDeleteSuccess
                                ? this.props.onDeleteSuccess
                                : () => {
                                      window.location =
                                          '/defdossier/' +
                                          this.props.dossier.def_dossier_id
                                  }
                        }
                    />
                ) : null}
                <Button
                    size={this.props.size}
                    onClick={() =>
                        this.setState({
                            DeleteDefDossierForm: true,
                            loading: true,
                        })
                    }
                    variant={'outline-danger'}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </span>
        )
    }
}

export default ContentDossierDelete
