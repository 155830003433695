import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/pro-duotone-svg-icons'
import ContentDossierLog from '../ContentDossierLog'

class ContentDossierHistory extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showForm: false,
        }
    }

    render() {
        return (
            <span>
                {this.state.showForm ? (
                    <ContentDossierLog
                        dossier={this.props.dossier}
                        onClose={() => this.setState({ showForm: false })}
                        kedo={this.props.kedo}
                    />
                ) : null}
                <Button
                    size={this.props.size ? 'sm' : null}
                    onClick={() =>
                        this.setState({ showForm: true, loading: true })
                    }
                    variant="outline-primary"
                    title="Bekijk wijzigingen"
                >
                    <FontAwesomeIcon icon={faHistory} />
                </Button>
            </span>
        )
    }
}

export default ContentDossierHistory
