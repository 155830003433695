import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

class DisplayItemListSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fast_switch:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.fast_switch
                    : null,
            checkbox_view:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.checkbox_view
                    : null,
            default_list_option:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.default_list_option
                    : null,
        }

        this.fetchOptions = this.fetchOptions.bind(this)
    }

    fetchOptions(defFieldId) {
        const kedo = this.props.kedo
        let params = {
            params: {
                defField: defFieldId,
                limit: 500,
            },
        }
        this.setState({
            loading: true,
        })
        kedo.api()
            .get(kedo.api().getDefFieldListEndpoint(), params)
            .then((response) => {
                this.setState({
                    options: response.data.results,
                    loading: false,
                })
            })
            .catch((error) => {
                this.setState({
                    error: true,
                    loading: false,
                })
            })
    }

    componentDidMount() {
        if (
            this.props.item &&
            this.props.item.def_dossier_def_field &&
            this.props.item.def_dossier_def_field.def_field &&
            this.props.item.def_dossier_def_field.def_field.id
        ) {
            this.fetchOptions(
                this.props.item.def_dossier_def_field.def_field.id
            )
        }
    }

    getCurrentTranslationItem(listOption, locale) {
        if (listOption.translations && listOption.translations.length > 0) {
            let trans = listOption.translations.find(
                (option) => option.culture === locale.code
            )
            if (trans && trans.text) {
                return trans
            }
        }
        return false
    }

    getCurrentTranslationItemText(listOption, locale) {
        let found = this.getCurrentTranslationItem(listOption, locale)
        if (found) {
            return found.text
        }

        if (listOption.translations && listOption.translations.length > 0) {
            return listOption.translations[0].text
        }

        return listOption.id
    }

    render() {
        const kedo = this.props.kedo
        const locale = kedo.env().getSelectedLocale()

        return (
            <div>
                <h3>List settings</h3>
                <Form.Group>
                    <Form.Check
                        checked={this.state.fast_switch === true}
                        id={'radio-fast-switch'}
                        name={'fast_switch'}
                        label={this.props.kedo.t('Fast switch')}
                        onChange={(e) => {
                            this.setState({ fast_switch: e.target.checked })
                            this.props.onChange('fast_switch', e.target.checked)
                        }}
                        type="checkbox"
                    />
                    <Form.Check
                        checked={this.state.checkbox_view === true}
                        id={'checkbox-view'}
                        name={'checkbox_view'}
                        label={this.props.kedo.t('Basic view')}
                        onChange={(e) => {
                            this.setState({ checkbox_view: e.target.checked })
                            this.props.onChange(
                                'checkbox_view',
                                e.target.checked
                            )
                        }}
                        type="checkbox"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        {this.props.kedo.t('Select default option')}
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={this.state.default_list_option}
                        name={'default_list_option'}
                        onChange={(e) => {
                            this.setState(
                                { default_list_option: e.target.value },
                                this.props.onChange(
                                    'default_list_option',
                                    e.target.value !== ''
                                        ? parseInt(e.target.value)
                                        : null
                                )
                            )
                        }}
                    >
                        <option value="">{kedo.t('Switched off')}</option>
                        <optgroup label={kedo.t('Options')}>
                            {this.state.options &&
                                this.state.options.map((listOption) => (
                                    <option
                                        key={listOption.id}
                                        value={listOption.id}
                                    >
                                        {this.getCurrentTranslationItemText(
                                            listOption,
                                            locale
                                        )}
                                    </option>
                                ))}
                        </optgroup>
                    </Form.Control>
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemListSettings
