import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-duotone-svg-icons'

class Pagination extends React.Component {
    render() {
        const pageNumbers = []
        for (let i = 1; i <= this.props.pager.pages; i++) {
            pageNumbers.push(i)
        }

        if (pageNumbers.length == 1) {
            pageNumbers.pop()
        }

        let current = this.props.pager.current
        let firstPage = 0
        let previousPage = current - 1
        let nextPage = current + 1
        let lastPage = this.props.pager.pages - 1

        return this.props.pager.pages > 1 ? (
            <Row>
                <Col
                    xs={12}
                    className={
                        'defaultPagination d-flex justify-content-center'
                    }
                >
                    {current >= 1 ? (
                        <div
                            className={'pagination-button'}
                            onClick={() => {
                                this.props.onChangePage(previousPage)
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </div>
                    ) : null}
                    <div
                        className={`pagination-button ${
                            current === 0 ? 'page-item active' : 'page-item'
                        }`}
                        onClick={() => {
                            this.props.onChangePage(firstPage)
                        }}
                    >
                        1
                    </div>
                    {pageNumbers.length > 6 && current > 3 ? (
                        <div className={'pagination-button transparant'}>
                            ...
                        </div>
                    ) : null}

                    {pageNumbers.map((i) => {
                        if (i > 1 && i < pageNumbers.length) {
                            if (pageNumbers.length > 5) {
                                if (
                                    i === current - 1 ||
                                    i === current ||
                                    i === current + 1 ||
                                    i === current + 2 ||
                                    i === current + 3
                                ) {
                                    return (
                                        <div
                                            className={`pagination-button ${
                                                current === i - 1
                                                    ? 'page-item active'
                                                    : 'page-item'
                                            }`}
                                            value={i}
                                            key={i}
                                            onClick={() => {
                                                this.props.onChangePage(i - 1)
                                            }}
                                        >
                                            {i}
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div
                                        className={`pagination-button ${
                                            current === i - 1
                                                ? 'page-item active'
                                                : 'page-item'
                                        }`}
                                        value={i}
                                        key={i}
                                        onClick={() => {
                                            this.props.onChangePage(i - 1)
                                        }}
                                    >
                                        {i}
                                    </div>
                                )
                            }
                        }
                    })}

                    {pageNumbers.length > 6 &&
                    current < pageNumbers.length - 2 &&
                    current < pageNumbers.length - 3 &&
                    current < pageNumbers.length - 4 ? (
                        <div className={'pagination-button transparant'}>
                            ...
                        </div>
                    ) : null}
                    <div
                        className={`pagination-button ${
                            current === pageNumbers.length - 1
                                ? 'page-item active'
                                : 'page-item'
                        }`}
                        onClick={() => {
                            this.props.onChangePage(lastPage)
                        }}
                    >
                        {pageNumbers.length}
                    </div>
                    {current <= this.props.pager.pages - 2 ? (
                        <div
                            className={'pagination-button'}
                            onClick={() => {
                                this.props.onChangePage(nextPage)
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                    ) : null}
                </Col>
            </Row>
        ) : null
    }
}

export default Pagination
