import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-duotone-svg-icons'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import DefDossierLinkForm from '../Form/DefDossierLinkForm'
import DisplayItemForm from '../Form/DisplayItemForm'
import DefDossierDefFieldForm from '../Form/DefDossierDefFieldForm'
import DefFieldForm from '../Form/DefFieldForm'
import { Link } from 'react-router-dom'

class AdminQuickEditMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editDdl: false,
            editDdi: false,
            editDddf: false,
            editDf: false,
            withoutEdit: false,
        }
    }

    handleLinkClick = (url) => {
        window.open(url, '_blank')
    }

    render() {
        const kedo = this.props.kedo

        return (
            <>
                {this.state.editDdl ? (
                    <DefDossierLinkForm
                        defDossier={this.state.editDdl.def_dossier_id}
                        onClose={() => this.setState({ editDdl: false })}
                        item={this.state.editDdl.def_dossier_link}
                        kedo={kedo}
                        onSuccess={() => {
                            this.setState({ editDdl: false })
                        }}
                    />
                ) : null}
                {this.state.editDdi ? (
                    <DisplayItemForm
                        defDossier={this.state.editDdi.def_dossier_id}
                        onClose={() => {
                            this.setState({ editDdi: false })
                        }}
                        id={this.state.editDdi.id}
                        kedo={kedo}
                    />
                ) : null}
                {this.state.editDddf ? (
                    <DefDossierDefFieldForm
                        defDossier={this.state.editDddf.def_dossier_id}
                        onClose={() => {
                            this.setState({ editDddf: false })
                        }}
                        item={this.state.editDddf.def_dossier_def_field}
                        kedo={kedo}
                    />
                ) : null}
                {this.state.editDf ? (
                    <DefFieldForm
                        defDossier={this.state.editDf.def_dossier_id}
                        onClose={() => {
                            this.setState({ editDf: false })
                        }}
                        item={this.state.editDf.def_dossier_def_field.def_field}
                        kedo={kedo}
                    />
                ) : null}
                {this.props.kedo.isAdminOrEnvironmentAdmin() &&
                !this.state.withoutEdit ? (
                    <DropdownButton
                        size={'sm'}
                        className={
                            this.props.className
                                ? this.props.className
                                : 'admin-edit-buttons'
                        }
                        title={<FontAwesomeIcon icon={faCog} />}
                        variant={'transparant'}
                        drop={'right'}
                    >
                        {this.props.item ? (
                            <Dropdown.Item
                                onClick={() =>
                                    this.setState({ editDdi: this.props.item })
                                }
                                eventKey="1"
                            >
                                {kedo.t('Edit display field')}
                            </Dropdown.Item>
                        ) : null}
                        {this.props.item.def_dossier_def_field ? (
                            <Dropdown.Item
                                onClick={() =>
                                    this.setState({ editDddf: this.props.item })
                                }
                                eventKey="1"
                            >
                                {kedo.t('Edit data field')}
                            </Dropdown.Item>
                        ) : null}
                        {this.props.item.def_dossier_def_field &&
                        this.props.item.def_dossier_def_field.def_field ? (
                            <Dropdown.Item
                                onClick={() =>
                                    this.setState({ editDf: this.props.item })
                                }
                                eventKey="2"
                            >
                                {kedo.t('Edit base field')}
                            </Dropdown.Item>
                        ) : null}
                        {this.props.item.def_dossier_link ? (
                            <Dropdown.Item
                                onClick={() =>
                                    this.setState({ editDdl: this.props.item })
                                }
                                eventKey="3"
                            >
                                {kedo.t('Edit link field')}
                            </Dropdown.Item>
                        ) : null}
                        {this.props.item.def_dossier_link &&
                        this.props.item.def_dossier_link
                            .child_def_dossier_id ? (
                            <Dropdown.Item eventKey="4">
                                <span
                                    onClick={() =>
                                        this.handleLinkClick(
                                            `/fieldeditor/${this.props.item.def_dossier_link.child_def_dossier_id}`
                                        )
                                    }
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {kedo.t('Field designer')}
                                </span>
                            </Dropdown.Item>
                        ) : null}
                        {this.props.item.def_dossier_link &&
                        this.props.item.def_dossier_link
                            .child_def_dossier_id ? (
                            <Dropdown.Item eventKey="5">
                                <span
                                    onClick={() =>
                                        this.handleLinkClick(
                                            `/fieldeditor/${this.props.item.def_dossier_link.child_def_dossier_id}?editData=1`
                                        )
                                    }
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {kedo.t('ERD editor')}
                                </span>
                            </Dropdown.Item>
                        ) : null}
                    </DropdownButton>
                ) : null}
            </>
        )
    }
}

export default AdminQuickEditMenu
