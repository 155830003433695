import React from 'react'
import {
    Badge,
    Button,
    Col,
    FormControl,
    InputGroup,
    ListGroup,
    Row,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExclamationCircle,
    faSearch,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'

class UpperMenuQuickSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            show: false,
            search: '',
            showSearch: false,
        }

        this.fetchItems = this.fetchItems.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    fetchItems() {
        const kedo = this.props.kedo
        if (this.state.loading) {
            return
        }
        let url = kedo.api().getContentDossierEndpoint() + '/search?limit=10'
        this.setState({ loading: true, items: [] })
        let params = {
            params: {
                environment: kedo.env().getEnvironment().id,
                search: this.state.search,
            },
        }
        kedo.api()
            .get(url, params)
            .then((response) =>
                this.setState({
                    loading: false,
                    loaded: true,
                    show: true,
                    items: response.data,
                })
            )
            .catch(() =>
                this.setState({
                    loading: false,
                    loaded: true,
                })
            )
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    onKeyUp() {
        if (this.state.timeout) clearTimeout(this.state.timeout)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.timeout = setTimeout(() => {
            this.fetchItems()
        }, 200)
    }

    shortenText(text) {
        let dotsNeeded = text.length >= 50
        let trimmedString = text.substr(0, 50)

        trimmedString = trimmedString.substr(
            0,
            Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
        )
        trimmedString = dotsNeeded ? trimmedString + '...' : trimmedString

        return trimmedString
    }

    getDate(item) {
        if (!item.created_at) {
            return
        }

        let date = new Date(item.created_at)
        let dateFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
            timeStyle: 'medium',
        })

        return <Badge variant="secondary">{dateFormat.format(date)}</Badge>
    }

    getDefDossierName(item, defDossiers, selectedLocale) {
        if (!selectedLocale) {
            return
        }

        let defDossier = defDossiers.find(
            (ddItem) => ddItem.id === item.def_dossier_id
        )
        if (
            !defDossier ||
            !defDossier.translations ||
            defDossier.translations.length <= 0
        ) {
            return
        }

        let translation = defDossier.translations.find(
            (trItem) => trItem.code === selectedLocale.code
        )

        if (!translation || !translation.name_plural) {
            return
        }

        return (
            <Link
                onClick={() => this.setState({ items: [], search: '' })}
                to={`/defdossier/${item.def_dossier_id}`}
            >
                <Badge variant="primary">{translation.name_plural}</Badge>
            </Link>
        )
    }

    /**
     * @param {Kedo} kedo
     * @returns {*}
     */
    renderItems(kedo) {
        let defDossiers = []
        let selectedLocale = null
        if (this.state.items.length > 0) {
            defDossiers = kedo.env().getCurrentEnvironmentDefDossiers()
            selectedLocale = kedo.env().getSelectedLocale()
        }

        return this.state.loading ? (
            <ListGroup className={'quickSearchItemlist'}>
                <ListGroup.Item>
                    <LoadingDefault as={'span'} size={'sm'} />
                    {kedo.t('Searching for items...')}
                </ListGroup.Item>
            </ListGroup>
        ) : this.state.items.length > 0 ? (
            <ListGroup className={'quickSearchItemlist'}>
                {this.state.items.map((item) => (
                    <Link
                        key={item.id}
                        onClick={() => {
                            this.setState({ items: [], search: '' })
                            setTimeout(
                                (window.location.href = `/contentdossier/${item.id}`),
                                200
                            )
                        }}
                        to={`/contentdossier/${item.id}`}
                    >
                        <ListGroup.Item action>
                            <div className={'quickSearchPills'}>
                                {this.getDefDossierName(
                                    item,
                                    defDossiers,
                                    selectedLocale
                                )}{' '}
                                &nbsp;
                                {this.getDate(item)}
                            </div>
                            <small>{item.summary}</small>
                        </ListGroup.Item>
                    </Link>
                ))}
            </ListGroup>
        ) : (
            <ListGroup className={'quickSearchItemlist'}>
                <ListGroup.Item variant="warning">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp; {kedo.t('No items found')}
                </ListGroup.Item>
            </ListGroup>
        )
    }

    render() {
        const kedo = this.props.kedo

        if (this.state.showSearch) {
            return (
                <Row className={'d-none d-sm-block'}>
                    <Col xs={12} className="UpperSearchField">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                autoComplete="off"
                                className={'form-control'}
                                name={'search'}
                                onChange={this.onChange}
                                onKeyUp={this.onKeyUp}
                                placeholder={kedo.t('Quick Search') + '...'}
                                type={'text'}
                                value={this.state.search}
                            />
                            <InputGroup.Append>
                                {this.state.search.length > 0 ? (
                                    <button
                                        className="btn bg-transparent exitQuickSearch"
                                        onClick={() => {
                                            this.setState({
                                                search: '',
                                                items: [],
                                            })
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                ) : null}
                            </InputGroup.Append>
                        </InputGroup>
                        {this.state.search.length > 0
                            ? this.renderItems(kedo)
                            : null}
                    </Col>
                </Row>
            )
        }

        return (
            <Button
                title={kedo.t('Quick Search')}
                variant={'light'}
                onClick={() => this.setState({ showSearch: true })}
                className={'uppermenu-buttons'}
            >
                <FontAwesomeIcon icon={faSearch} />
            </Button>
        )
    }
}

export default UpperMenuQuickSearch
