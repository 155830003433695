import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { KeDoContext } from '../kedo-context'
class AzureConnect extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    static contextType = KeDoContext

    render() {
        const kedo = this.context

        let params = new URLSearchParams(this.props.location.search)

        if (params.has('username')) {
            kedo.user().updateInfoFromJWTToken({
                username: params.get('username'),
                iat: params.get('iat'),
                exp: params.get('exp'),
                user_id: parseInt(params.get('id')),
                roles: params.get('roles').split(','),
            })
            localStorage.setItem(
                'tokenData',
                JSON.stringify({
                    username: params.get('username'),
                    iat: params.get('iat'),
                    exp: params.get('exp'),
                    user_id: parseInt(params.get('id')),
                    roles: params.get('roles').split(','),
                })
            )
            setTimeout(() => (window.location = '/environment'), 200)
        } else {
            window.location = '/'
        }

        return <Row className="d-flex justify-content-center"></Row>
    }
}
export default AzureConnect
