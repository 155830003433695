import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons'
import ComposeMessage from '../../Messages/ComposeMessage'

class ContentDossierSend extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            compose: false,
            showSuccess: false,
        }
    }

    getComposeMessageForm() {
        return (
            <ComposeMessage
                kedo={this.props.kedo}
                dossier={this.props.dossier}
                onCloseModal={() => this.setState({ compose: false })}
            />
        )
    }

    render() {
        if (this.state.compose) {
            return this.getComposeMessageForm()
        }

        if (this.state.showSuccess) {
            return (
                <Button
                    size={this.props.size}
                    onClick={() => this.setState({ compose: true })}
                    variant="success"
                    title={this.props.kedo.t('Send message')}
                >
                    <FontAwesomeIcon icon={faEnvelope} />
                </Button>
            )
        }

        return (
            <Button
                size={this.props.size}
                onClick={() => this.setState({ compose: true })}
                variant="outline-primary"
                title={this.props.kedo.t('Send message')}
            >
                <FontAwesomeIcon icon={faEnvelope} />
            </Button>
        )
    }
}

export default ContentDossierSend
