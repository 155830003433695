import React, { Component } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import QueryBuilderField from './QueryBuilderField'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'
import QueryBuilderLink from './QueryBuilderLink'

class QueryBuilderCombinator extends Component {
    constructor(props) {
        super(props)

        let fields =
            this.props.field && this.props.field.fields
                ? this.props.field.fields
                : []
        fields.map((item, index) => {
            if (!item.uuid) {
                fields[index].uuid = this.props.kedo.utils().uuidv4()
            }
        })
        let links =
            this.props.field && this.props.field.links
                ? this.props.field.links
                : []
        links.map((item, index) => {
            if (!item.uuid) {
                links[index].uuid = this.props.kedo.utils().uuidv4()
            }
        })

        let combinators =
            this.props.field && this.props.field.combinators
                ? this.props.field.combinators
                : []
        combinators.map((item, index) => {
            if (!item.uuid) {
                combinators[index].uuid = this.props.kedo.utils().uuidv4()
            }
        })

        this.state = {
            uuid: this.props.field
                ? this.props.field.uuid
                : this.props.kedo.utils().uuidv4(),
            combine:
                this.props.field && this.props.field.combine
                    ? this.props.field.combine
                    : 'AND',
            fields: fields,
            links: links,
            combinators: combinators,
            sellink: null,
            loading: false,
            seldata: null,
            dataFields: [],
            linkFields: [],
        }

        this.addCombinator = this.addCombinator.bind(this)
        this.addDataField = this.addDataField.bind(this)
        this.addLinkField = this.addLinkField.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.onChangeCombinator = this.onChangeCombinator.bind(this)
        this.onChangeField = this.onChangeField.bind(this)
        this.onChangeLink = this.onChangeLink.bind(this)
        this.fetchDataFields = this.fetchDataFields.bind(this)
        this.fetchLinkFields = this.fetchLinkFields.bind(this)
        this.onDeleteCombinator = this.onDeleteCombinator.bind(this)
        this.onDeleteLink = this.onDeleteLink.bind(this)
        this.onDeleteField = this.onDeleteField.bind(this)
    }

    fetchDataFields(ddId) {
        let params = { params: { defDossier: ddId, limit: 500 } }
        this.setState({ loadingDataFieldItems: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getDefDossierDefFieldEndpoint(), params)
            .then((response) =>
                this.setState({
                    dataFields: response.data.results,
                    loadingDataFieldItems: false,
                })
            )
    }

    fetchLinkFields(ddId) {
        let params = {
            params: {
                defDossier: ddId,
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 500,
            },
        }
        this.setState({ loadingLinkFields: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getDefDossierLinkEndpoint(), params)
            .then((response) =>
                this.setState({
                    linkFields: response.data.results,
                    loadingLinkFields: false,
                })
            )
    }

    fetchFields() {
        //First node
        if (this.props.defdossier) {
            this.fetchDataFields(this.props.defdossier)
            this.fetchLinkFields(this.props.defdossier)
        } else {
            //From link
            let ddlUrl = this.props.kedo.api().getDefDossierLinkEndpoint()
            this.props.kedo
                .api()
                .get(ddlUrl + '/' + this.props.defdossierlink)
                .then((response) => {
                    this.fetchDataFields(response.data.child_def_dossier_id)
                    this.fetchLinkFields(response.data.child_def_dossier_id)
                })
        }
    }

    componentDidMount() {
        this.fetchFields()
    }

    onChangeField(index, field) {
        let fields = this.state.fields
        fields[index] = field
        this.setState({ fields: fields })
        this.handleOnChange()
    }

    onChangeCombinator(index, field) {
        let combinators = this.state.combinators
        combinators[index] = field
        this.setState({ combinators: combinators })
        this.handleOnChange()
    }

    onChangeLink(index, field) {
        let links = this.state.links
        links[index] = field
        this.setState({ links: links })
        this.handleOnChange()
    }

    handleOnChange() {
        let combinator = {
            combine: this.state.combine,
            combinators: this.state.combinators,
            fields: this.state.fields,
            links: this.state.links,
        }

        if (this.props.defdossierlink) {
            combinator.id = this.props.defdossierlink
        }

        this.props.onChange(this.props.index, combinator)
    }

    addCombinator() {
        let combinators = this.state.combinators
        let newCombinator = { uuid: this.props.kedo.utils().uuidv4() }

        if (this.props.ddObject && this.props.ddObject.id) {
            newCombinator.id = this.props.ddObject.id
        } else if (this.props.linkField && this.props.linkField.id) {
            newCombinator.id = this.props.linkField.id
        }

        combinators.push(newCombinator)
        this.setState({ combinators: combinators })

        this.handleOnChange()
    }

    addLinkField() {
        if (!this.state.sellink) {
            return
        }
        let newUuid = this.props.kedo.utils().uuidv4()
        let links = this.state.links
        links.push({
            id: parseInt(this.state.sellink),
            uuid: newUuid,
        })
        this.setState({ links: links })

        this.handleOnChange()
    }

    addDataField() {
        if (!this.state.seldata) {
            return
        }

        let newUuid = this.props.kedo.utils().uuidv4()

        let fields = this.state.fields
        fields.push({
            id: parseInt(this.state.seldata),
            uuid: newUuid,
        })
        this.setState({ fields: fields })
        this.handleOnChange()
    }

    onDeleteField(uuid) {
        let newItems = this.state.fields.filter((item) => item.uuid !== uuid)

        this.setState(
            {
                fields: newItems,
            },
            this.handleOnChange
        )
    }

    onDeleteLink(uuid) {
        let links = this.state.links.filter((item) => item.uuid !== uuid)
        this.setState(
            {
                links: links,
            },
            this.handleOnChange
        )
    }

    onDeleteCombinator(uuid) {
        let combinators = this.state.combinators.filter(
            (item) => item.uuid !== uuid
        )
        this.setState({ combinators: combinators })
    }

    render() {
        const fieldTypes = [
            'text',
            'autoIncrement',
            'calc',
            'email',
            'url',
            'user',
            'list',
            'date',
            'amount',
        ]
        const kedo = this.props.kedo
        if (this.state.loading) {
            return <LoadingDefault />
        }
        return (
            <div
                className={`card mb-3 ${
                    this.props.index % 2 == 0
                        ? 'border-primary'
                        : 'border-secondary border-success'
                }`}
            >
                <div className="card-header text-white bg-secondary">
                    Combinator{' '}
                    {this.props.ddObject ? this.props.ddObject.name_plural : ''}{' '}
                    {this.props.linkField ? this.props.linkField.name : null}
                    {this.state.loadingDataFieldItems ||
                    this.state.loadingLinkFields ? (
                        <LoadingDefault as={'span'} />
                    ) : null}
                    <div className="float-right input-group col-md-2">
                        <Form.Control
                            size={'sm'}
                            value={this.state.combine}
                            onChange={(e) => {
                                this.setState(
                                    { combine: e.target.value },
                                    this.handleOnChange
                                )
                            }}
                            name={'combine'}
                            as={'select'}
                        >
                            <option value={'AND'}>{kedo.t('AND')}</option>
                            <option value={'OR'}>{kedo.t('OR')}</option>
                        </Form.Control>
                        <div className="input-group-append">
                            <Button
                                className={'float-right'}
                                size={'sm'}
                                onClick={this.addCombinator}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <Button
                                variant={'secondary'}
                                className={'float-right'}
                                size={'sm'}
                                onClick={() =>
                                    this.props.onDelete(this.state.uuid)
                                }
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                {kedo.t('Data field')}{' '}
                                {this.state.loadingDataFieldItems ? (
                                    <LoadingDefault />
                                ) : null}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            name={'seldata'}
                            onChange={(e) =>
                                this.setState({
                                    [e.target.name]: e.target.value,
                                })
                            }
                            as={'select'}
                        >
                            <option>{kedo.t('Choose an option')}</option>
                            {fieldTypes.map((fieldType) => (
                                <optgroup key={fieldType} label={fieldType}>
                                    {this.state.dataFields
                                        .filter(
                                            (dataField) =>
                                                dataField.def_field &&
                                                dataField.def_field.type ===
                                                    fieldType
                                        )
                                        .map((dataField) => (
                                            <option
                                                key={dataField.id}
                                                value={dataField.id}
                                            >
                                                {dataField.name}
                                            </option>
                                        ))}
                                </optgroup>
                            ))}
                        </Form.Control>
                        <InputGroup.Append>
                            <Button onClick={this.addDataField}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                {kedo.t('Link field')}{' '}
                                {this.state.loadingLinkFields ? (
                                    <LoadingDefault />
                                ) : null}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            name={'sellink'}
                            onChange={(e) =>
                                this.setState({
                                    [e.target.name]: e.target.value,
                                })
                            }
                            as={'select'}
                        >
                            <option>{kedo.t('Choose an option')}</option>
                            {this.state.linkFields.map((linkField) => (
                                <option key={linkField.id} value={linkField.id}>
                                    {linkField.name} - {linkField.id}
                                </option>
                            ))}
                        </Form.Control>
                        <InputGroup.Append>
                            <Button onClick={this.addLinkField}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <br />
                    {this.state.fields.map((field, fieldIndex) => (
                        <QueryBuilderField
                            key={field.uuid}
                            index={fieldIndex}
                            field={field}
                            onDelete={this.onDeleteField}
                            onChange={this.onChangeField}
                            kedo={this.props.kedo}
                            dataField={this.state.dataFields.find(
                                (dataItem) => dataItem.id === field.id
                            )}
                        />
                    ))}
                    {this.state.links.map((field, linkIndex) => (
                        <QueryBuilderLink
                            field={field}
                            key={field.uuid}
                            kedo={this.props.kedo}
                            defdossierlink={field.id}
                            onDelete={this.onDeleteLink}
                            index={linkIndex}
                            onChange={this.onChangeLink}
                            linkField={this.state.linkFields.find(
                                (linkItem) => linkItem.id === field.id
                            )}
                        />
                    ))}
                    {this.state.combinators.map((field, linkIndex) => (
                        <QueryBuilderCombinator
                            field={field}
                            key={field.uuid}
                            defdossier={this.props.defdossier}
                            kedo={this.props.kedo}
                            onDelete={this.onDeleteCombinator}
                            defdossierlink={field.id}
                            index={linkIndex}
                            onChange={this.onChangeCombinator}
                            linkField={this.state.linkFields.find(
                                (linkItem) => linkItem.id === field.id
                            )}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default QueryBuilderCombinator
