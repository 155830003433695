import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import Pagination from '../../Elements/Pagination'
import DefDossierKanbanCard from './DefDossierKanbanCard'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
    margin: 8px;
    border-radius: 3px;
    width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`
const Title = styled.h3`
    padding: 8px;
    text-shadow: 7px 2px 9px rgba(84, 84, 84, 0.25);
    background-color: red;
    color: white;
    border-radius: 5px;
    margin-bottom: 15px;
    text-transform: uppercase;
    text-align: center;
    font-size: 17px;
`
const TaskList = styled.div`
    padding: 15px;
    border: dashed 3px #dcecf7;
    transition: background-color 0.2s ease;
    background-color: ${(props) =>
        props.isDraggingOver ? 'rgba(0, 0, 0, .04)' : 'white'};
    min-height: 100px;
`

class DefDossierKanbanColumn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dossiers: [],
            contents: [],
            displayItems: [],
            pager: {},
            page: 0,
            loading: false,
            cardLoading: null,
            searchParams: null,
            dossierQuery: null,
            sort: this.props.sort,
        }

        this.renderDossiers = this.renderDossiers.bind(this)
        this.removeDossier = this.removeDossier.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.doSearch = this.doSearch.bind(this)
        this.fetchDossiers = this.fetchDossiers.bind(this)
    }

    addDossier(dossier) {
        let updatedContents = this.state.contents.filter(
            (item) => item.id !== parseInt(dossier[0][0].id)
        )
        dossier[2].map((item) => {
            updatedContents.push(item)
        })

        this.setState((previousState) => ({
            dossiers: [...previousState.dossiers, dossier[0][0]],
            displayItems: dossier[1],
            contents: updatedContents,
        }))

        let api = this.props.kedo.api()

        let values = []
        if (this.props.defFieldList && this.props.defFieldList.id) {
            values.push(this.props.defFieldList.id)
        }

        api.post(
            api.getContentListEndpoint(
                parseInt(dossier[0][0].id),
                this.props.defDossier.settings.kanban_list_field,
                'set'
            ),
            values
        ).then((response) => {
            if (this.props.kanbanCustomView) {
                this.fetchDossiers(parseInt(dossier[0][0].id))
            }
        })
    }

    removeDossier(dossierId) {
        let dossierToAdd = this.state.dossiers.filter(
            (item) => item.id === parseInt(dossierId)
        )
        let ContentToAdd = this.state.contents.filter(
            (item) => item.id === parseInt(dossierId)
        )

        //Remove this "dossier contents" from column
        let updatedContents = this.state.contents.filter(
            (item) => item.id !== parseInt(dossierId)
        )
        this.setState(
            {
                contents: updatedContents,
            },
            () => {
                //console.log([this.state.contents, 'removeDossier: this.state.contents'])
            }
        )

        //Remove dossier from column
        let dossiers = this.state.dossiers.filter(
            (item) => item.id !== parseInt(dossierId)
        )
        this.setState({ dossiers: dossiers })
        return Promise.resolve([
            dossierToAdd,
            this.state.displayItems,
            ContentToAdd,
        ])
    }

    renderDossiers(dossiers) {
        return dossiers.map((item, index) => (
            <DefDossierKanbanCard
                key={item.id}
                content={this.state.contents}
                kedo={this.props.kedo}
                dossier={item}
                onRefresh={this.props.onRefresh}
                displayItems={this.state.displayItems}
                defDossier={this.props.defDossier}
                index={index}
                onClick={this.props.onClick}
                kanbanCustomView={this.props.kanbanCustomView}
                isLoading={this.state.cardLoading}
            />
        ))
    }

    doSearch(searchParams, dossierQuery) {
        this.setState(
            {
                searchParams: searchParams,
                dossierQuery: dossierQuery,
            },
            this.fetchDossiers
        )
    }

    fetchDossiers(dossierId) {
        const api = this.props.kedo.api()
        let ddiId = this.props.displayItem.id
        let params = {}
        if (this.state.sort && this.state.sort.length > 0) {
            params.sort = []
            params.sortOrder = []
            this.state.sort.map((filterItem) => {
                params.sort.push(
                    filterItem.item.def_dossier_def_field
                        ? filterItem.item.def_dossier_def_field.id
                        : filterItem.item.settings.dossier_data
                )
                params.sortOrder.push(filterItem.order)
            })
        }

        if (this.state.searchParams && this.state.searchParams.length > 0) {
            this.state.searchParams.map((searchItem) => {
                if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'date'
                ) {
                    params[searchItem.id + '_type'] = searchItem.value.type
                    if (searchItem.value.type === 'between') {
                        params[searchItem.id + '_end'] = searchItem.value.date2
                    }
                    params[searchItem.id] = searchItem.value.date1
                } else if (typeof searchItem.value === 'string') {
                    params[searchItem.id] = searchItem.value
                } else if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'slider'
                ) {
                    params[searchItem.id + '_min'] = searchItem.value.min
                    params[searchItem.id + '_max'] = searchItem.value.max
                    params[searchItem.id] = '1'
                } else if (searchItem.value instanceof Array) {
                    let values = []
                    searchItem.value.map((valItem) => values.push(valItem.id))
                    params[searchItem.id] = values
                }
            })
        }

        params.defDossier = this.props.defDossier.id
        params.page = this.state.page
        params.limit = 50

        if (this.props.embedded) {
            params.embedded = this.props.embedded
            params.linkId = this.props.linkId
        }

        if (dossierId !== undefined) {
            this.setState({ cardLoading: dossierId })
            params.dossierIds = dossierId
        } else {
            this.setState({ loading: true })
            params[ddiId] = [this.props.defFieldList.id]
        }

        if (this.props.kanbanCustomView) {
            params.view = this.props.kanbanCustomView
        }

        api.get(api.getContentDossierEndpoint(), { params: params }).then(
            (response) => {
                if (dossierId !== undefined) {
                    var dossiersState = this.state.contents.filter(
                        (item) => item.id !== parseInt(dossierId)
                    )
                    response.data.results.contents.map((item) => {
                        dossiersState.push(item)
                    })
                }

                this.setState((previousState) => ({
                    loading: false,
                    cardLoading: null,
                    dossiers:
                        dossierId !== undefined
                            ? [...previousState.dossiers]
                            : response.data.results.dossiers,
                    contents:
                        dossierId !== undefined
                            ? dossiersState
                            : response.data.results.contents,
                    displayItems:
                        dossierId !== undefined
                            ? [...previousState.displayItems]
                            : response.data.results.items,
                    pager: response.data.pager,
                    page: response.data.pager.page,
                }))
            }
        )
    }

    componentDidMount() {
        this.fetchDossiers()
    }

    onChangePage(page) {
        this.setState({ page: page })
        setTimeout(() => {
            this.fetchDossiers()
        }, 50)
    }

    render() {
        let styles = {}
        if (this.props.defFieldList.color) {
            styles.backgroundColor = this.props.defFieldList.color
        }

        return (
            <div className={'kanban-col'}>
                <Container>
                    <Title style={styles}>
                        {this.props.kedo
                            .env()
                            .translateItem(
                                this.props.defFieldList,
                                'def_field_list'
                            )}
                        <Button
                            onClick={this.props.onHideColumn}
                            className={'hide-btn float-right'}
                            size={'sm'}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Title>
                    <Droppable
                        droppableId={this.props.defFieldList.id.toString()}
                        type="task"
                    >
                        {(provided, snapshot) => (
                            <TaskList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                isDraggingOver={snapshot.isDraggingOver}
                            >
                                {this.state.loading ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                        }}
                                    >
                                        <LoadingDefault size="lg" />
                                    </div>
                                ) : (
                                    this.renderDossiers(this.state.dossiers)
                                )}
                                {provided.placeholder}
                            </TaskList>
                        )}
                    </Droppable>
                </Container>
                <Pagination
                    pager={this.state.pager}
                    onChangePage={this.onChangePage}
                />
            </div>
        )
    }
}

export default DefDossierKanbanColumn
