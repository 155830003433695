import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import DefFieldListSettings from './DefFieldListSettings'
import DefFieldDateSettings from './DefFieldDateSettings'
import DefFieldAmountSettings from './DefFieldAmountSettings'
import DefFieldTextSettings from './DefFieldTextSettings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons'
import DefFieldImageCodeSettings from './DefFieldImageCodeSettings'
import DefFieldUserSettings from './DefFieldUserSettings'

class DefFieldSettings extends Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(name, value) {
        if (name == 'rank') {
            this.props.onRankChange(value)
        } else {
            this.props.onSettingsChange(name, value)
        }
    }

    render() {
        if (!this.props.item || !this.props.item.type) {
            return (
                <Alert variant={'warning'}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp; {this.props.kedo.t('First select a field type')}
                </Alert>
            )
        }

        let compProps = {
            item: this.props.item,
            onChange: this.onChange,
            kedo: this.props.kedo,
        }

        if (this.props.item.type === 'list') {
            return <DefFieldListSettings {...compProps} />
        } else if (this.props.item.type === 'date') {
            return <DefFieldDateSettings {...compProps} />
        } else if (this.props.item.type === 'amount') {
            return <DefFieldAmountSettings {...compProps} />
        } else if (this.props.item.type === 'text') {
            return <DefFieldTextSettings {...compProps} />
        } else if (this.props.item.type === 'imageCode') {
            return <DefFieldImageCodeSettings {...compProps} />
        } else if (this.props.item.type === 'user') {
            return <DefFieldUserSettings {...compProps} />
        }

        return (
            <div>
                <h3>{this.props.kedo.t('Settings')}</h3>
                <Alert variant={'info'}>
                    {this.props.kedo.t('No settings available')}
                </Alert>
            </div>
        )
    }
}

export default DefFieldSettings
